/*
    Template Name: Ezone - Minimalist eCommerce HTML5 Template
    Description: This is html5 template
    Author: HasTech
    Version: 1.0

*/
/*================================================
[  Table of contents  ]
================================================
01. General
02. Page section margin padding
03. Section title 1
04. Section title 2
05. button
06. button small
07. share button
08. Text color
09. Input
10. Extra class
11. Tool tip
12. loading
13. Box Layout
14. Back to top
15. Basic margin padding

======================================
[ End table content ]
======================================*/

/*************************
    General
*************************/
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900|Roboto+Slab:300,400,700|Open+Sans:300,300i,400,400i,600,700,800');
body {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
	color:#686868;
    overflow-x: hidden;
    overflow-y: auto;
}
.img{
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
}
a,
.btn {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.btn {
    border-radius: 0px;
    font-size: 14px;
    padding: 0px 15px;
    height: 30px;
    line-height: 30px;
}
a:focus,
.btn:focus {
    text-decoration: none;
    outline: none;
}
a:focus, a:hover {
  color: #050035;
  text-decoration: none;
}
a,
button,
input {
    outline: medium none;
    color: #686868;
}
.uppercase {
    text-transform: uppercase
}
.capitalize {
    text-transform: capitalize
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    color: #383838;
    margin-top: 0px;
    font-style: normal;
    font-weight: 400;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit
}
h1 {
    font-size: 46px;
    font-weight: 500;
}
h2 {
    font-size: 37px;
}
h3 {
    font-size: 28px;
}
h4 {
    font-size: 22px;
}
h5 {
    font-size: 18px;
}
h6 {
    font-size: 16px;
}
ul {
    margin: 0px;
    padding: 0px;
}


p {
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    color: #3e3e3e;
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
}

hr{
    margin: 60px 0;
    padding: 0px;
    border-bottom: 1px solid #eceff8;
    border-top: 0px;
}
hr.style-2{
    border-bottom: 1px dashed #f10;
}
hr.mp-0 {
    margin: 0;
    border-bottom: 1px solid #eceff8;
}
hr.mtb-40 {
    margin: 40px 0;
    border-bottom: 1px solid #eceff8;
    border-top: 0px;
}
label {
    font-size: 15px;
    font-weight: 400;
    color: #626262;
}
*::-moz-selection {
    background: #4fc1f0;
    color: #fff;
    text-shadow: none;
}
::-moz-selection {
    background: #4fc1f0;
    color: #fff;
    text-shadow: none;
}
::selection {
    background: #4fc1f0;
    color: #fff;
    text-shadow: none;
}
.mark, mark {
    background: #4fc1f0 none repeat scroll 0 0;
    color: #ffffff;
}
span.tooltip-content {
  color: #00a9da;
  cursor: help;
  font-weight: 600;
}
.f-left {
    float: left
}
.f-right {
    float: right
}
.fix {
    overflow: hidden
}
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
/***************************
    transtion
****************************/
a.button::after,
a.button-small::after {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.share ul,
.share:hover ul {
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
}
a.button-border span,
a.button-border-white span,
input,
select,
textarea {
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
}

/*************************
    Section title 1
***********************/
.section-title{}
.section-title h3 {
  font-size: 36px;
  margin-bottom: 20px;
}
.section-title.text-white h3 {
  color: #ffffff;
}



/*************************
         button
*************************/
.button {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #9c9c9c;
  border-radius: 5px;
  box-shadow: none;
  color: #9c9c9c;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.4px;
  overflow: hidden;
  padding: 12px 30px;
  position: relative;
  text-transform: uppercase;
}
.button:hover{border:1px solid #fff;color:#fff}
.button.border-white{border-color:#fff;}
.button.theme-bg {
  border-color: #4a90e2;
}
.button.button-white {
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03) inset;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    overflow: hidden;
    padding: 0;
    position: relative;
    background-color: #fff;
    color: #4FC1F0;
    font-size: 15px;
}
.button.button-white i {
    background: rgba(0, 0, 0, 0.09);
    display: block;
    float: left;
    padding: 14px;
    font-size: 16px;
    color: #4FC1F0;
    font-weight: normal;
    width: 50px;
    text-align: center;
}
.button.button-white span {
    display: block;
    float: left;
    position: relative;
    z-index: 2;
    padding: 11px 20px;
    font-size: 15px;
    color: #4FC1F0;
    font-weight: normal;
}
.button.button-grey {
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03) inset;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    overflow: hidden;
    padding: 0;
    position: relative;
    background-color: #eceff7;
    color: #4FC1F0;
    font-size: 15px;
}
.button.button-grey i {
    background: rgba(0, 0, 0, 0.09);
    display: block;
    float: left;
    padding: 14px;
    font-size: 16px;
    color: #4FC1F0;
    font-weight: normal;
    width: 50px;
    text-align: center;
}
.button.button-grey span {
    display: block;
    float: left;
    position: relative;
    z-index: 2;
    padding: 11px 20px;
    font-size: 15px;
    color: #4FC1F0;
    font-weight: normal;
}
.button.button-black {
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03) inset;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    overflow: hidden;
    padding: 0;
    position: relative;
    background-color: #444444;
    color: #4FC1F0;
    font-size: 15px;
}
.button.button-black i {
    background: rgba(0, 0, 0, 0.3);
    display: block;
    float: left;
    padding: 14px;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    width: 50px;
    text-align: center;
}
.button.button-black span {
    display: block;
    float: left;
    position: relative;
    z-index: 2;
    padding: 11px 20px;
    font-size: 15px;
    color: #fff;
    font-weight: normal;
}
.button-border span {
    border: 0 none;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    padding: 11px 20px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    background-color: transparent;
    color: #4FC1F0;
    font-size: 15px;
    border: 1px solid #4FC1F0;
}
.button-border span:hover {
    box-shadow: -200px 0 0 #4FC1F0 inset;
    color: #fff;
    border-color: #4FC1F0;
}
.button-border-white span {
    border: 0 none;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    padding: 11px 20px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    background-color: transparent;
    color: #fff;
    font-size: 15px;
    border: 1px solid #fff;
}
.button-border-white span:hover {
    box-shadow: -200px 0 0 #fff inset;
    color: #4FC1F0;
    border-color: #fff;
}


/*************************
        button small
*************************/
.button-small {
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03) inset;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    overflow: hidden;
    padding: 0;
    position: relative;
    background-color: #4FC1F0;
    color: #fff;
    font-size: 15px;
}
.button-small i {
    background: rgba(0, 0, 0, 0.09);
    display: block;
    float: left;
    padding: 10px;
    font-size: 13px;
    color: #fff;
    font-weight: normal;
    width: 40px;
    text-align: center;
}
.button-small span {
    display: block;
    float: left;
    position: relative;
    z-index: 2;
    padding: 8px 10px;
    font-size: 13px;
    color: #fff;
    font-weight: normal;
}
.button-small:after {
    background: rgba(0, 0, 0, 0.09) none repeat scroll 0 0;
    content: "";
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    width: 0;
    z-index: 1;
}
a.button-small:hover::after { width: 100% }

/*************************
        buttons variation
*************************/
.button.large {margin-right: 0px; }
.button.large span { padding: 16px 80px; font-size: 18px;  }
.button.large i { padding: 20px; width: 60px; font-size: 18px; }
.button.medium span { padding: 14px 60px; font-size: 16px; }
.button.medium i { padding: 17px; width: 50px; font-size: 16px; }
.button.small span { padding: 12px 40px; }
.button.small i { padding: 14px; width: 45px; }
.button.small span { padding: 12px 40px; }
.button.small i { padding: 15px; width: 45px; }
.button.extra-small span { padding: 8px 20px; font-size: 13px; }
.button.extra-small i { padding: 11px; width: 38px; font-size: 13px; }

/*************************
       social-icon
*************************/
.social-icon ul { margin: 0; padding: 0; }
.social-icon ul li { display: inline-block; list-style: none; }
.social-icon ul li a { display: block; margin: 3px; width: 50px; height: 50px; line-height: 50px; font-size: 16px; color: #fff; background: #4FC1F0; text-align: center; border-radius: 50%; }
.social-icon ul li a:hover { background: #EB3B60; }

.social-icon.socile-icon-style-2 ul li a { color: #fff; background: #323232; border-radius: 0;}
.social-icon.socile-icon-style-2 ul li a:hover { background: #EB3B60;  }

.social-icon.socile-icon-style-3 ul li a { color: #fff; background: transparent; border:1px solid #fff; }
.social-icon.socile-icon-style-3 ul li a:hover { background: #EB3B60; border-color:#EB3B60; }


/*************************
        Text color
*************************/
.text-blue { color: #4FC1F0; }
.text-white { color: #fff ;}
.text-black { color: #363636; }
.text-theme { color: #f10; }
.text-theme {
  color: #4a90e2;
}

.text-white h1, .text-white p, .text-white h2{ color: #fff; }
/*************************
        Input
*************************/
input {
    background: #eceff8;
    border: 2px solid #eceff8;
    height: 45px;
    box-shadow: none;
    padding-left: 10px;
    font-size: 14px;
    color: #626262;
    width: 100%;
}
select {
    width: 100%;
    background: #eceff8;
    border: 2px solid #eceff8;
    height: 45px;
    padding-left: 10px;
    box-shadow: none;
    font-size: 14px;
    color: #626262;
}
option {
    background: #fff;
    border: 0px solid #626262;
    padding-left: 10px;
    font-size: 14px;
}
input:focus {
    background: transparent;
    border: 2px solid #4FC1F0;
}
textarea {
    resize: vertical;
    background: #eceff8;
    border: 2px solid #eceff8;
    padding: 10px;
    width: 100%;
    font-size: 14px;
}
textarea:focus {
    background: transparent;
    border: 2px solid #4FC1F0;
    outline: none;
}
::-moz-placeholder {
    color: #444;
}
.input-group.divcenter.input-group .form-control { padding-left: 0px }
/*************************
        back-to-top
*************************/
#back-to-top .top {
    z-index: 999;
    position: fixed;
    margin: 0px;
    color: #fff;
    transition: all .5s ease-in-out;
    position: fixed;
    bottom: 105px;
    right: 15px;
    border-radius: 3px;
    z-index: 999;
    background: transparent;
    font-size: 14px;
    background: #4FC1F0;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 38px;
}
#back-to-top .top:hover {
    color: #fff;
    height: 50px;
}
#back-to-top .top { background: #007da1; }
/*************************
  Basic margin padding
*************************/
.m-0 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}
.p-0 {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}
/*************************
         Margin top
*************************/
.mt-0 { margin-top: 0 }
.mt-10 { margin-top: 10px }
.mt-12 { margin-top: 12px }
.mt-15 { margin-top: 15px }
.mt-20 { margin-top: 20px }
.mt-30 { margin-top: 30px }
.mt-35 { margin-top: 35px }
.mt-40 { margin-top: 40px }
.mt-50 { margin-top: 50px }
.mt-60 { margin-top: 60px }
.mt-70 { margin-top: 70px }
.mt-80 { margin-top: 80px }
.mt-90 { margin-top: 90px }
.mt-100 { margin-top: 100px }
.mt-110 { margin-top: 110px }
.mt-120 { margin-top: 120px }
.mt-130 { margin-top: 130px }
.mt-140 { margin-top: 140px }
.mt-150 { margin-top: 150px }
.mt-160 { margin-top: 160px }
.mt-170 { margin-top: 170px }
.mt-180 { margin-top: 180px }
.mt-190 { margin-top: 190px }
/*************************
      Margin right
*************************/
.mr-0 { margin-right: 0px }
.mr-4 { margin-right: 4px }
.mr-10 { margin-right: 10px }
.mr-12 { margin-right: 12px }
.mr-15 { margin-right: 15px }
.mr-20 { margin-right: 20px }
.mr-30 { margin-right: 30px }
.mr-35 { margin-right: 35px }
.mr-40 { margin-right: 40px }
.mr-50 { margin-right: 50px }
.mr-60 { margin-right: 60px }
.mr-70 { margin-right: 70px }
.mr-80 { margin-right: 80px }
.mr-90 { margin-right: 90px }
.mr-100 { margin-right: 100px }
.mr-110 { margin-right: 110px }
.mr-120 { margin-right: 120px }
.mr-130 { margin-right: 130px }
.mr-140 { margin-right: 140px }
.mr-150 { margin-right: 150px }
.mr-185 { margin-right: 185px }
/*************************
      Margin bottom
*************************/
.mb-0 { margin-bottom: 0 }
.mb-4 { margin-bottom: 4px }
.mb-6 { margin-bottom: 6px }
.mb-10 { margin-bottom: 10px }
.mb-12 { margin-bottom: 12px }
.mb-15 { margin-bottom: 15px }
.mb-20 { margin-bottom: 20px }
.mb-24 { margin-bottom: 24px }
.mb-30 { margin-bottom: 30px }
.mb-35 { margin-bottom: 35px }
.mb-40 { margin-bottom: 40px }
.mb-45 { margin-bottom: 45px }
.mb-50 { margin-bottom: 50px }
.mb-55 { margin-bottom: 55px }
.mb-60 { margin-bottom: 60px }
.mb-65 { margin-bottom: 65px }
.mb-70 { margin-bottom: 70px }
.mb-80 { margin-bottom: 80px }
.mb-85 { margin-bottom: 85px }
.mb-90 { margin-bottom: 90px }
.mb-95 { margin-bottom: 95px }
.mb-100 { margin-bottom: 100px }
.mb-110 { margin-bottom: 110px }
.mb-115 { margin-bottom: 115px }
.mb-120 { margin-bottom: 120px }
.mb-130 { margin-bottom: 130px }
.mb-140 { margin-bottom: 140px }
.mb-145 { margin-bottom: 145px }
.mb-150 { margin-bottom: 150px }
/*************************
        Margin left
*************************/
.ml-0 { margin-left: 0 }
.ml-10 { margin-left: 10px }
.ml-12 { margin-left: 12px }
.ml-15 { margin-left: 15px }
.ml-20 { margin-left: 20px }
.ml-30 { margin-left: 30px }
.ml-40 { margin-left: 40px }
.ml-50 { margin-left: 50px }
.ml-60 { margin-left: 60px }
// .ml-70 { margin-left: 70px }
.ml-80 { margin-left: 80px }
.ml-90 { margin-left: 90px }
.ml-100 { margin-left: 100px }
.ml-110 { margin-left: 110px }
.ml-120 { margin-left: 120px }
.ml-130 { margin-left: 130px }
.ml-140 { margin-left: 140px }
.ml-150 { margin-left: 150px }
.ml-185 { margin-left: 185px }
/*************************
        Padding top
*************************/
.pt-0 { padding-top: 0 }
.pt-10 { padding-top: 10px }
.pt-15 { padding-top: 15px }
.pt-20 { padding-top: 20px }
.pt-25 { padding-top: 25px }
.pt-30 { padding-top: 30px }
.pt-35 { padding-top: 35px }
.pt-40 { padding-top: 40px }
.pt-45 { padding-top: 45px }
.pt-50 { padding-top: 50px }
.pt-55 { padding-top: 55px }
.pt-60 { padding-top: 60px }
.pt-65 { padding-top: 65px }
.pt-70 { padding-top: 70px }
.pt-75 { padding-top: 75px }
.pt-80 { padding-top: 80px }
.pt-85 { padding-top: 85px }
.pt-95 { padding-top: 95px }
.pt-90 { padding-top: 90px }
.pt-100 { padding-top: 100px }
.pt-custom{padding-bottom: 250px;
    padding-top: 80px;}
.pt-95 { padding-top: 95px }
.pt-105 { padding-top: 105px }
.pt-110 { padding-top: 110px }
.pt-115 { padding-top: 115px }
.pt-120 { padding-top: 120px }
.pt-125 { padding-top: 125px }
.pt-130 { padding-top: 130px }
.pt-135 { padding-top: 135px }
.pt-140 { padding-top: 140px }
.pt-145 { padding-top: 145px }
.pt-150 { padding-top: 150px }
.pt-155 { padding-top: 155px }
.pt-175 { padding-top: 175px }
.pt-160 { padding-top: 160px }
.pt-165 { padding-top: 165px }
.pt-170 { padding-top: 170px }
.pt-180 { padding-top: 180px }
.pt-195 { padding-top: 195px }
.pt-185 { padding-top: 185px }
.pt-205 { padding-top: 205px }
.pt-200 { padding-top: 200px }
.pt-365 { padding-top: 365px }
.pt-370 { padding-top: 370px }
.pt-380 { padding-top: 380px }
/*************************
        Padding right
*************************/
.pr-0 { padding-right: 0 }
.pr-10 { padding-right: 10px }
.pr-15 { padding-right: 15px }
.pr-20 { padding-right: 20px }
.pr-30 { padding-right: 30px }
.pr-40 { padding-right: 40px }
.pr-50 { padding-right: 50px }
.pr-60 { padding-right: 60px }
.pr-70 { padding-right: 70px }
.pr-80 { padding-right: 80px }
.pr-90 { padding-right: 90px }
.pr-100 { padding-right: 100px }
.pr-110 { padding-right: 110px }
.pr-120 { padding-right: 120px }
.pr-130 { padding-right: 130px }
.pr-140 { padding-right: 140px }
.pr-145 { padding-right: 145px }
.pr-155 { padding-right: 155px }
.pr-185 { padding-right: 185px }
.pr-200 { padding-right: 200px }
.pr-205 { padding-right: 205px }
/*************************
        Padding bottom
*************************/
.pb-0 { padding-bottom: 0 }
.pb-10 { padding-bottom: 10px }
.pb-15 { padding-bottom: 15px }
.pb-20 { padding-bottom: 20px }
.pb-25 { padding-bottom: 25px }
.pb-30 { padding-bottom: 30px }
.pb-35 { padding-bottom: 35px }
.pb-40 { padding-bottom: 40px }
.pb-45 { padding-bottom: 45px }
.pb-50 { padding-bottom: 50px }
.pb-55 { padding-bottom: 55px }
.pb-60 { padding-bottom: 60px }
.pb-65 { padding-bottom: 65px }
.pb-70 { padding-bottom: 70px }
.pb-75 { padding-bottom: 75px }
.pb-80 { padding-bottom: 80px }
.pb-85 { padding-bottom: 85px }
.pb-90 { padding-bottom: 90px }
.pb-95 { padding-bottom: 95px }
.pb-100 { padding-bottom: 100px }
.pb-105 { padding-bottom: 105px }
.pb-110 { padding-bottom: 110px }
.pb-115 { padding-bottom: 115px }
.pb-120 { padding-bottom: 120px }
.pb-130 { padding-bottom: 130px }
.pb-150 { padding-bottom: 140px }
.pb-120 { padding-bottom: 120px }
.pb-120 { padding-bottom: 120px }
.pb-125 { padding-bottom: 125px }
.pb-130 { padding-bottom: 130px }
.pb-135 { padding-bottom: 135px }
.pb-140 { padding-bottom: 140px }
.pb-150 { padding-bottom: 150px }
.pb-145 { padding-bottom: 145px }
.pb-155 { padding-bottom: 155px }
.pb-160 { padding-bottom: 160px }
.pb-170 { padding-bottom: 170px }
.pb-175 { padding-bottom: 175px }
.pb-180 { padding-bottom: 180px }
.pb-160 { padding-bottom: 160px }
.pb-165 { padding-bottom: 165px }
.pb-173 { padding-bottom: 173px }
.pb-180 { padding-bottom: 180px }
.pb-190 { padding-bottom: 190px }
.pb-195 { padding-bottom: 195px }
.pb-280 { padding-bottom: 280px }
.pb-210 { padding-bottom: 210px }
.pb-220 { padding-bottom: 220px }
.pb-285 { padding-bottom: 285px }
.pb-290 { padding-bottom: 290px }
/*************************
        Padding left
*************************/
.pl-0 { padding-left: 0 }
.pl-10 { padding-left: 10px }
.pl-15 { padding-left: 15px }
.pl-20 { padding-left: 20px }
.pl-30 { padding-left: 30px }
.pl-40 { padding-left: 40px }
.pl-50 { padding-left: 50px }
.pl-55 { padding-left: 55px }
.pl-60 { padding-left: 60px }
.pl-70 { padding-left: 70px }
.pl-80 { padding-left: 80px }
.pl-90 { padding-left: 90px }
.pl-100 { padding-left: 100px }
.pl-110 { padding-left: 110px }
.pl-120 { padding-left: 120px }
.pl-130 { padding-left: 130px }
.pl-140 { padding-left: 140px }
.pl-150 { padding-left: 150px }
.pl-155 { padding-left: 155px }
.pl-185 { padding-left: 185px }
.pl-190 { padding-left: 190px }
.pl-195 { padding-left: 195px }
.pl-200 { padding-left: 200px }


/***************************
    Page section padding
****************************/
.ptb-0 { padding: 0 }
.ptb-10 { padding: 10px 0 }
.ptb-15 { padding: 15px 0 }
.ptb-20 { padding: 20px 0 }
.ptb-30 { padding: 30px 0 }
.ptb-35 { padding: 35px 0 }
.ptb-32 { padding: 32px 0 }
.ptb-40 { padding: 40px 0 }
.ptb-50 { padding: 50px 0 }
.ptb-60 { padding: 60px 0 }
.ptb-70 { padding: 70px 0 }
.ptb-80 { padding: 80px 0 }
.ptb-90 { padding: 90px 0 }
.ptb-95 { padding: 95px 0 }
.ptb-100 { padding: 100px 0 }
.ptb-105 { padding: 105px 0 }
.ptb-115 { padding: 115px 0 }
.ptb-110 { padding: 110px 0 }
.ptb-120 { padding: 120px 0 }
.ptb-130 { padding: 130px 0 }
.ptb-135 { padding: 135px 0 }
.ptb-140 { padding: 140px 0 }
.ptb-150 { padding: 150px 0 }
.ptb-145 { padding: 145px 0 }
.ptb-160 { padding: 160px 0 }
.ptb-170 { padding: 170px 0 }
.ptb-177 { padding: 177px 0 }
.ptb-180 { padding: 180px 0 }
.ptb-190 { padding: 190px 0 }
.ptb-200 { padding: 200px 0 }
.ptb-210 { padding: 210px 0 }
.ptb-220 { padding: 220px 0 }
.ptb-290 { padding: 290px 0 }
.ptb-310 { padding: 310px 0 }

/***************************
    Page section margin
****************************/
.mtb-0 { margin: 0 }
.mtb-10 { margin: 10px 0 }
.mtb-15 { margin: 15px 0 }
.mtb-20 { margin: 20px 0 }
.mtb-30 { margin: 30px 0 }
.mtb-40 { margin: 40px 0 }
.mtb-50 { margin: 50px 0 }
.mtb-60 { margin: 60px 0 }
.mtb-70 { margin: 70px 0 }
.mtb-80 { margin: 80px 0 }
.mtb-90 { margin: 90px 0 }
.mtb-100 { margin: 100px 0 }
.mtb-110 { margin: 110px 0 }
.mtb-120 { margin: 120px 0 }
.mtb-130 { margin: 130px 0 }
.mtb-140 { margin: 140px 0 }
.mtb-150 { margin: 150px 0; }
.mtb-290 { margin: 290px 0; }

/*************************
        Extra class
*************************/
.pricing .container .row [class*="col-"] { }



/*Custom cloumn*/

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10 {
    float: left;
}
.col-1{
    width: 10%;
}
.col-2{
    width: 20%;
}
.col-3{
    width: 30%;
}
.col-4{
    width: 40%;
}
.col-5{
    width: 50%;
}
.col-6{
    width: 60%;
}
.col-7{
    width: 70%;
}
.col-8{
    width: 80%;
}
.col-9{
    width: 90%;
}
.col-10{
    width: 100%;
}
 /*************************************
    Background variation set
 **************************************/
/*colored background*/
 .white-bg { background: #fff; }
 .black-bg { background: #282828 }
 .black-bg-2 { background: #181818 }
 .black-bg-3 { background: #0a0a0a }
 .black-bg-4 { background: #0d0e15 }
 .black-bg-5 { background: #353535 }
 .black-bg-6 { background: #222222 }
 .gray-bg { background: #fafafa; }
 .gray-bg-2 { background: #f1f1f1; }
 .gray-bg-3 { background: #f3f3f3; }
 .gray-bg-4 { background: #f8f8f8; }
 .gray-bg-5 { background: #f7f7f7; }
 .gray-bg-6 { background: #eeeeee; }
 .gray-bg-7 { background: #f9f9f9; }
 .gray-bg-8 { background: #f6f6f6; }
 .theme-bg { background: #2a68fc;}
 .theme-bg-2 { background: #f44344;}
 .blue-bg { background: #4FC1F0; }
 .default-bg { background: #50C1F0; }
 .transparent-bg { background: transparent; }

/*Opacity background*/
/* black overlay */
[data-overlay] {
  position: relative;
}
[data-overlay]::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
[data-overlay="3"]::before {
  opacity: 0.3;
}
[data-overlay="4"]::before {
  opacity: 0.4;
}
[data-overlay="5"]::before {
  opacity: 0.5;
}
[data-overlay="56"]::before {
  opacity: 0.56;
}
[data-overlay="6"]::before {
  opacity: 0.6;
}
[data-overlay="7"]::before {
  opacity: 0.7;
}
[data-overlay="8"]::before {
  opacity: 0.8;
}
[data-overlay="9"]::before {
  opacity: 0.9;
}
/* white overlay */
[white-overlay] {
  position: relative;
}
[white-overlay]::before {
  background: #fff none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
[white-overlay="3"]::before {
  opacity: 0.3;
}
[white-overlay="4"]::before {
  opacity: 0.4;
}
[white-overlay="5"]::before {
  opacity: 0.3;
}
[white-overlay="6"]::before {
  opacity: 0.6;
}
[white-overlay="7"]::before {
  opacity: 0.8;
}
[white-overlay="9"]::before {
  opacity: 0.9;
}
.z-index{position:relative;z-index:9}

/*image background*/
 .bg-1, .bg-2, .bg-3, .bg-4, .bg-5, .bg-6, .bg-7, .bg-8, .bg-9, .bg-10{
    background-attachment: fixed; background-clip: initial; background-color: rgba(0, 0, 0, 0); background-origin: initial; background-position: center center; background-repeat: no-repeat; background-size: cover; position: relative; z-index: 0;
 }
 .bg-1 {
  background: url(../../images/bg/1.jpg);}
 .bg-2 { background: url(../../images/bg/2.jpg);}
 .bg-3 { background: url(../../images/bg/3.jpg);}
 .bg-4 { background: url(../../images/bg/4.jpg);}
 .bg-5 { background: url(../../images/bg/5.jpg);}
 .bg-6 { background: url(../../images/bg/6.jpg);}
 .bg-7 { background: url(../../images/bg/7.jpg);}
 .bg-8 { background: url(../../images/bg/8.jpg);}
 .bg-9 { background: url(../../images/bg/9.jpg);}
 .bg-10 { background: url(../../images/bg/10.jpg);}

.bg-img {
  background-position: center center;
  background-size: cover;
}
  /*pattern background*/
.pattern-bg { background:url(../../images/pattern/pattern-bg.png) repeat; background-position: top right; background-color: rgba(236, 239, 247, 1); }








/*separator*/
.separator{
    position: relative;
    display: inline-block;
    clear: both;
    background: rgba(0, 0, 0, 0.07) none repeat scroll 0 0;
    bottom: 0;
    height: 1px;
    width: 33%;
}
.separator::before {
  background: #4fc1f0 none repeat scroll 0 0;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  transition: all 0.4s ease 0s;
  width: 100px;
}

.section-title:hover .separator::before {
  width: 75%;
}

.separator i {
  background: #ECEFF7 none repeat scroll 0 0;
  color: #4fc1f0;
  display: block;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: -20px;
  width: 40px;
}
.separator span {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #4fc1f0;
  border-radius: 100%;
  display: block;
  height: 10px;
  position: absolute;
  top: -5px;
  width: 10px;
}
.separator i, .separator span {
  left: 0;
  transform: none;
}
.text-center .separator::before, .text-center .separator i, .text-center .separator span  {
  left: 50%;
  transform: translateX(-50%);
}

.text-right .separator::before, .text-right .separator i, .text-right .separator span  {
  left: auto;
  transform: none;
  right: 0;
}

/*tooltip*/
.socile-icon-tooltip [data-tooltip], .socile-icon-tooltip .tooltip {
    cursor: pointer;
    position: relative;
}
.socile-icon-tooltip [data-tooltip]::before, .socile-icon-tooltip [data-tooltip]::after, .socile-icon-tooltip .tooltip::before, .socile-icon-tooltip .tooltip::after {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translate3d(0px, 0px, 0px);
    transition: opacity 0.2s ease-in-out 0s, visibility 0.2s ease-in-out 0s, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24) 0s;
    visibility: hidden;
}
.socile-icon-tooltip [data-tooltip]:hover::before, .socile-icon-tooltip [data-tooltip]:hover::after, .socile-icon-tooltip [data-tooltip]:focus::before, .socile-icon-tooltip [data-tooltip]:focus::after, .socile-icon-tooltip .tooltip:hover::before, .socile-icon-tooltip .tooltip:hover::after, .socile-icon-tooltip .tooltip:focus::before, .socile-icon-tooltip .tooltip:focus::after {
    opacity: 1;
    visibility: visible;
}
.socile-icon-tooltip .tooltip::before, .socile-icon-tooltip [data-tooltip]::before {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 6px solid rgba(0, 0, 0, 0);
    content: "";
    z-index: 1001;
}
.socile-icon-tooltip .tooltip::after, .socile-icon-tooltip [data-tooltip]::after {
    background-color: #00a9da;
    border-radius: 3px;
    color: #ffffff;
    content: attr(data-tooltip);
    font-size: 14px;
    line-height: 1.2;
    padding: 8px;
    text-align: center;
    width: 100px;
    z-index: 1000;
}
.socile-icon-tooltip [data-tooltip]::before, .socile-icon-tooltip [data-tooltip]::after, .socile-icon-tooltip .tooltip::before, .socile-icon-tooltip .tooltip::after, .socile-icon-tooltip .tooltip-top::before, .socile-icon-tooltip .tooltip-top::after {
    bottom: 100%;
    left: 50%;
}
.socile-icon-tooltip [data-tooltip]::before, .socile-icon-tooltip .tooltip::before, .socile-icon-tooltip .tooltip-top::before {
    border-top-color: #00a9da;
    margin-bottom: -12px;
    margin-left: -6px;
}
.socile-icon-tooltip [data-tooltip]::after, .socile-icon-tooltip .tooltip::after, .socile-icon-tooltip .tooltip-top::after {
    margin-left: -50px;
}
.socile-icon-tooltip [data-tooltip]:hover::before, .socile-icon-tooltip [data-tooltip]:hover::after, .socile-icon-tooltip [data-tooltip]:focus::before, .socile-icon-tooltip [data-tooltip]:focus::after, .socile-icon-tooltip .tooltip:hover::before, .socile-icon-tooltip .tooltip:hover::after, .socile-icon-tooltip .tooltip:focus::before, .socile-icon-tooltip .tooltip:focus::after, .socile-icon-tooltip .tooltip-top:hover::before, .socile-icon-tooltip .tooltip-top:hover::after, .socile-icon-tooltip .tooltip-top:focus::before, .socile-icon-tooltip .tooltip-top:focus::after {
    transform: translateY(-25px);
}
:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

:focus {
    outline: -mos-focus-ring-color auto 0px;
    outline: none;
}


button:focus {
  outline: none;
}

.relative{
    position: relative;
}


.border-bottom-1{
    border-bottom: 1px solid #d5d5d5;
}


.border-bottom-2{
    border-bottom: 1px solid #ededed;
}

.border-bottom-3{
    border-bottom: 1px solid #5b5b63;
}


.border-top-1 {
  border-top: 1px solid #dcdcdc;
}

.border-top-2 {
  border-top: 1px solid #dadada;
}

.border-top-3 {
  border-top: 1px solid #e5e5e5;
}


.overly-style{
    position: relative;
}


.overly-style:before{
    position: relative;
}



.custom .row {
  margin-left: -5px;
  margin-right: -5px;
}
.custom .row .col-md-7 , .custom .row .col-md-5 {
  padding-left: 5px;
  padding-right: 5px;
}


.height-100vh {
    height: 100vh;
}






