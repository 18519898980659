/*-----------------------------------------------------------------------------------

    Template Name: Ezone - eCommerce HTML5 Template
    Description: This is html5 template
    Author: HasTech
    Version: 1.0

-----------------------------------------------------------------------------------

    [Table of contents]

    1. Template default css (css/bundle.css)
    2. index page
        2.1 Header sidebar
        2.2 Menu style
        2.3 Currency style
        2.4 Cart style
        2.5 Slider style
        2.6 Banner style
        2.7 Product style
        2.8 Banner style 2
        2.9 Brand logo style
        2.10 Banner 3 style
        2.11 Instafeed style
        2.12 Footer style
    3. Book page
        3.1 Header style of book
        3.2 Menu style of book
        3.3 Slider style book
        3.4 Banner style book
        3.5 product style book
        3.6 Testimonial style book
        3.7 Discount style book
        3.8 Blog style book
        3.9 Subscribe style book
        3.10 Footer style of book

    4. Electronics page
        4.1 Header style of electronics
        4.2 Menu style of electronics
        4.3 Slider style of electronics
        4.4 Banner style of electronics
        4.5 Product style of electronics
        4.6 Brand logo style of electronics
        4.7 Newsletter style of electronics
        4.8 Footer style of electronics
    5. Electronic style 2
        5.1 Header style of electronic 2
        5.2 Slider style of electronic 2
        5.3 Banner style of electronic 2
        5.4 Product style of electronic 2
    6. Handicraft page
        6.1 Header style of handicraft
        6.2 Slider style of handicraft
        6.3 Banner style of handicraft
        6.4 Product style of handicraft
        6.5 Banner style 2 of handicraft
        6.6 Testimonial style of handicraft
        6.7 Newsletter style of handicraft
        6.8 Footer style of handicraft
    7. Furniture page
        7.1 Header style of furniture
        7.2 Product style of furniture
        7.3 Services style of furniture
        7.4 Footer style of furniture
    8. Smart watch page
        8.1 Header style of smart watch
        8.2 Slider style of smart watch
        8.3 About style of smart watch
        8.4 Overview style of smart watch
        8.5 Video style of smart watch
        8.6 Feature style of smart watch
        8.7 Feadback style of smart watch
        8.8 Subscribe style of smart watch
    9. Sports page
        9.1 Header style of sports
        9.2 Slider style of sports
        9.3 Discount style of sports
        9.4 Banner style of sports
    10. Food page
        10.1 Header style of food
        10.2 Slider style of food
        10.3 Product style of food
        10.4 Service style of food
        10.5 Foot menu style of food
        10.6 Banner style of food
        10.7 Testimonial style of food
        10.8 Subscribe style of food
        10.9 Footer style of food
    11. Fashion style 2
        11.1 Header style of fashion 2
        11.2 Slider style of fashion 2
    12. Breadcrumb style
    13. Login page
    14. Shopping cart
    15. Checkout page
    16. Shop page
    17. Product details
    18. Compare style
    19. Blog style
    20. Blog details
    21. Contact us
    22. About us
    23. Fruits page
        23.1 Heade style of fruits
        23.2 Slider style of fruits
        23.3 Banner style of fruits
        23.4 Product style of fruits
        23.5 Banner style 2 of fruits
        23.6 Services style of fruits
        23.7 Product coundoun style of fruits
        23.8 Product Best seller style of fruits
        23.9 Testimonial style of fruits
        23.10 Blog style of fruits
        23.11 Newsletter style of fruits
        23.12 Footer style of fruits
    24. Modal style

-----------------------------------------------------------------------------------*/


/* 2. index page */
/* 2.1 Header sidebar */
.header-left-sidebar {
    float: left;
    height: 100vh;
    padding: 70px 0 60px 0px;
    top: 0;
    width: 375px;
    z-index: 99;
}
.logo {
    padding-left: 100px;
}
.language {
    padding-left: 50px;
}

/* 2.2 Menu style */

.main-menu {
    padding: 124px 0 120px;
}
.main-menu ul li {
    display: block;
    padding-bottom: 16px;
    padding-left: 100px;
    padding-top: 17px;
    position: relative;
}
.main-menu ul li:hover {
    background-color: #efefef;
}
.main-menu ul li .single-dropdown li:hover {
    background-color: transparent;
}
.main-menu ul li::before {
    background-color: #efefef;
    bottom: 0;
    content: "";
    height: 1px;
    left: -50px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 225px;
}
.main-menu ul li:last-child::before {
    content: inherit;
}
.main-menu ul li:last-child {
    border-bottom: none;
    margin-bottom: 0px;
}
.main-menu ul li a {
    color: #999999;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
}
.main-menu ul li:hover a {
    color: #050035;
}
.main-menu ul li a span {
    background-color: #f44336;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    left: 2px;
    line-height: 1;
    padding: 4px 6px 1px;
    position: relative;
    top: -11px;
}
.main-menu .single-dropdown {
    left: 110%;
    top: 0;
}
.menu-hover.main-menu ul li:hover .single-dropdown {
    left: 100%;
    top: 0;
}
.main-menu ul li .single-dropdown li:before {
    content: none;
}
.mega-common4 {
    float: left;
    padding-left: 60px;
    padding-right: 10px;
    position: relative;
    width: 25%;
}
.mega-common4.mega-common2 {
    float: left;
    padding-left: 60px;
    padding-right: 10px;
    position: relative;
    width: 50%;
}
.mega-common4.discount-mega-common4 {
    padding-left: 0px;
}
.mega-menu-dropdown {
    background: #fafafa none repeat scroll 0 0;
    box-shadow: 0 0 15px 0.08px rgba(0, 0, 0, 0.15);
    left: 115%;
    opacity: 0;
    padding: 92px 0 164px;
    position: absolute;
    text-align: left;
    top: -220px;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 1170px;
    z-index: 999999999;
}
.mega-dropdown-width {
    width: 1170px;
}
.mega-dropdown-width-2 {
    width: 700px;
    padding: 70px 0;
}
.menu-hover.main-menu ul li:hover .mega-menu-dropdown {
    left: 100%;
    visibility: visible;
    opacity: 1;
}
.main-menu ul li .mega-dropdown-style ul li {
    margin-bottom: 0;
    padding: 0 0 25px 0;
    z-index: 99;
}
.main-menu ul li .mega-dropdown-style ul li:hover {
    background-color: transparent;
}
.main-menu ul li .mega-dropdown-style ul li:last-child {
    padding-bottom: 0px;
}
.main-menu ul li .mega-dropdown-style ul li a {
    color: #999999;
    font-size: 18px;
    text-transform: capitalize;
}
.main-menu ul li .mega-dropdown-style ul li a:hover {
    color: #050035;
    padding-left: 5px;
}
.main-menu ul li .mega-dropdown-style ul li::before {
    content: none;
}
.mega-banner-img-2 {
    bottom: 0;
    display: inline-block;
    position: absolute;
    right: 0;
}
.mega-subtitle {
    color: #666666;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 34px;
    padding-bottom: 6px;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.mega-subtitle::before {
    background-color: #e1e1e1;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0px;
    position: absolute;
    width: 140px;
}
.mega-discount {
    text-align: center;
}
.mega-discount > h5 {
    color: #051523;
    font-size: 14px;
    line-height: 10px;
    margin-bottom: 4px;
}
.mega-discount > h2 {
    color: #666666;
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}
.mega-discount {
    margin: 197px 37px 0 0;
    text-align: center;
}
.mega-banner-img-2 img {
    max-width: 566px;
}

/* 2.3 Currency style */

.currency {
    padding-left: 100px;
}
.currency ul li,
.currency-2 ul li {
    display: inline-block;
    margin-right: 18px;
    position: relative;
}
.currency ul li a,
.currency-2 ul li a {
    color: #999999;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}
.currency ul li a:hover,
.currency-2 ul li a:hover {
    color: #383838;
}
.currency ul li::after {
    background-color: #999;
    content: "";
    font-size: 8px;
    height: 15px;
    position: absolute;
    right: -11px;
    top: 4px;
    width: 2px;
}
.currency ul li:last-child::after,
.header-login ul li:last-child::after {
    content: none;
}
/* header right sidebar */
.header-search-cart-login .logo {
    display: none;
}
.header-right-sidebar {
    margin-left: 375px;
}
.header-search-cart-login {
    display: flex;
    justify-content: flex-end;
    margin-right: 127px;
    margin-top: 66px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
}
.header-search input {
    width: inherit;
}
.header-search input {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: transparent none repeat scroll 0 0;
    border-color: currentcolor currentcolor #acb3a9;
    border-image: none;
    border-style: none none solid;
    border-width: 0 0 1px;
    color: #999;
    font-size: 14px;
    height: 18px;
    padding: 0;
    width: 172px;
}
.header-search input::-moz-placeholder,
.subscribe-form-3 form input::-moz-placeholder {
    color: #999;
    opacity: 1;
}
.header-search input::-webkit-placeholder,
.subscribe-form-3 form input::-webkit-placeholder {
    color: #999;
    opacity: 1;
}
.header-search button {
    background-color: transparent;
    border: medium none;
    color: #050035;
    display: inline-block;
    font-size: 18px;
    line-height: 1;
    margin-bottom: -10px;
    padding: 0;
    cursor: pointer;
    transition: all .3s ease 0s;
}
.header-search button:hover {
    color: #666;
}

/* 2.4 Cart style */

.icon-cart i {
    color: #050035;
    font-size: 35px;
    transition: all .3s ease 0s;
}

.icon-cart i:hover {
    color: #666;
}
.icon-cart {
    display: block;
    position: relative;
}
.cart-dropdown {
    background: #fff none repeat scroll 0 0;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    opacity: 0;
    padding: 20px 20px 47px;
    position: absolute;
    right: 0;
    top: 140%;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 316px;
    z-index: 9999;
}
.single-product-cart {
    border-bottom: 1px solid #f6f6f6;
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.single-product-cart:last-child {
    border-bottom: 0px solid #f6f6f6;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.cart-delete {
    display: flex;
    flex-grow: 100;
    justify-content: flex-end;
}
.cart-title {
    margin: 19px 0 0 15px;
}
.cart-title h5,
.cart-title h6,
.cart-title span {
    color: #070b21;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 6px;
    text-transform: capitalize;
}
.cart-title h5 a,
.cart-title h6 a {
    color: #070b21;
}
.cart-delete > a {
    color: #070b21;
    font-size: 18px;
    margin-top: 18px;
}
.cart-delete > a:hover,
.cart-title h5 a:hover,
.cart-title h6 a:hover {
    color: #050035;
}
.cart-title > span {
    display: block;
    margin: 9px 0 0;
}
.cart-space {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
.cart-sub > h4 {
    color: #070b21;
    font-size: 18px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
}
.cart-price > h4 {
    color: #070b21;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
}
.cart-btn-wrapper {
    display: flex;
    justify-content: space-between;
}
.cart-btn {
    border: 1px solid #666666;
    color: #070b21;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1;
    padding: 12px 21px 10px;
    text-transform: uppercase;
}
.cart-btn:hover {
    border: 1px solid #000;
    color: #fff;
}
.header-cart:hover .cart-dropdown,
.header-cart-2:hover .cart-dropdown {
    opacity: 1;
    top: 100%;
    visibility: visible;
}
.header-login ul li {
    display: inline-block;
    margin-right: 18px;
    position: relative;
}
.header-login ul li:last-child {
    margin-right: 0px;
}
.header-login ul li::after {
    background-color: #050035;
    content: "";
    font-size: 8px;
    height: 12px;
    position: absolute;
    right: -12px;
    top: 4px;
    width: 1px;
}
.header-login ul li a {
    color: #050035;
    font-size: 14px;
    text-transform: uppercase;
}
.header-login ul li a:hover {
    color: #666;
}
.shop-count {
    background-color: #050035;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    position: absolute;
    right: -26px;
    text-align: center;
    top: 5px;
    width: 25px;
}
.header-cart {
    margin-left: 41px;
    position: relative;
}
.header-login {
    margin-left: 41px;
    margin-top: 7px;
}
.header-search {
    margin-top: 4px;
}

/* 2.5 Slider style */

.slider-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 103px;
    z-index: 999;
}
.slider-content-style-1 h1 {
    color: #050035;
    font-size: 100px;
    font-weight: bold;
    letter-spacing: 6px;
    margin-bottom: 4px;
    text-transform: uppercase;
}
.slider-content-style-1 p {
    color: #050035;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: .6px;
    margin: 0;
}
.owl-item .slider-animation * {
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.owl-item.active .slider-animated-1 h1 {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}
.owl-item.active .slider-animated-1 p {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.owl-item.active .slider-animated-1 .text-bg {
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}
.owl-item.active .slider-animated-2 h1 {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}
.owl-item.active .slider-animated-2 p {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
.position-slider-img {
    position: absolute;
    right: 175px;
    top: 160px;
    width: 669px;
    display: flex;
}
.slider-img-1 {
    margin-right: -285px;
    margin-top: 60px;
    width: 450px;
}
.slider-img-2 {
    width: 397px;
    z-index: 99;
}
.slider-img-4 {
    width: 600px;
    z-index: 99;
}
.slider-img-4.slider-mrg {
  margin-right: 70px;
}
.slider-img-3 {
    margin-left: -414px;
    margin-top: 150px;
    width: 450px;
}
.slider-img-1 img,
.slider-img-3 img {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.fruits-services-img img {}
.single-fruits-services:hover .fruits-services-img img {
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running tada;
    animation: 500ms ease-in-out 0s normal none 1 running tada;
}

/* 2.6 Banner style */

.single-banner {
    position: relative;
    overflow: hidden;
}
.single-banner img {
    width: 100%;
}
.banner-content-style1 {
    left: 0;
    padding: 30px 42px;
    position: absolute;
    top: 232px;
}
.banner-content-style2 {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    padding: 30px;
}
.banner-content.banner-content-style3 {
    bottom: 185px;
    left: 0;
    padding: 30px;
    position: absolute;
    right: 0;
    text-align: center;
}
.banner-content-style1 > h2 {
    color: #383838;
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 9px;
}
.banner-content-style1 > h2 span {
    color: #0275e6;
}
.banner-content-style1 > p {
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    width: 62%;
    margin: 0;
}
.banner-content-style2 > span {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}
.banner-content-style2 > h2 {
    color: #ffffff;
    font-size: 70px;
    font-weight: bold;
    margin: 7px 0 12px;
    text-transform: uppercase;
}
.banner-content-style2 > p {
    color: #ffffff;
    font-size: 24px;
    margin: 0;
    text-transform: uppercase;
}
.banner-content-style3 > h2 {
    color: #fff;
    font-size: 70px;
    font-weight: bold;
    line-height: 75px;
    margin: 0;
}
.single-banner a img {
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    width: 100%;
}
.single-banner:hover a img {
    transform: scale(1.1) rotate(-5deg);
}

/* 2.7 Product style */

.section-title > h2 {
    display: inline-block;
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    position: relative;
    text-transform: uppercase;
}
.section-title > h2::before {
    background-color: #9b9b9b;
    content: "";
    height: 1px;
    left: -100px;
    position: absolute;
    top: 19px;
    width: 70px;
}
.section-title > h2::after {
    background-color: #9b9b9b;
    content: "";
    height: 1px;
    right: -100px;
    position: absolute;
    top: 19px;
    width: 70px;
}
.product-wrapper,
.product-img {
    position: relative;
}
.product-action {
    bottom: 30px;
    display: inline-block;
    left: 0;
    opacity: 0;
    padding: 0 20px;
    position: absolute;
    right: 0;
    text-align: center;
    transition: all 0.3s ease 0s;
}
.product-action a {
    background-color: #383838;
    border-radius: 100%;
    color: #f7f7f7;
    display: inline-block;
    font-size: 24px;
    height: 45px;
    line-height: 52px;
    margin: 0 15px;
    width: 45px;
}
.product-action a:hover {
    background-color: #050035;
    color: #fff;
}
.product-img > span {
    background-color: #666;
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 40px;
    left: 20px;
    letter-spacing: 1px;
    line-height: 40px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 20px;
    width: 40px;
}
.product-content h4 {
    color: #383838;
    font-size: 18px;
    margin-bottom: 6px;
}
.product-content > span {
    color: #383838;
    font-size: 18px;
    font-weight: bold;
}
.product-content {
    padding-top: 23px;
}
.product-action > a.animate-left {
    transform: translateX(-20px);
    transition: all 0.4s ease 0s;
}
.product-wrapper:hover .product-action > a.animate-left {
    transform: translateX(0px);
}
.product-action > a.animate-right {
    transform: translateX(20px);
    transition: all 0.4s ease 0s;
}
.product-wrapper:hover .product-action > a.animate-right {
    transform: translateX(0px);
}
.product-action > a.animate-top {
    transform: translateY(20px);
    transition: all 0.4s ease 0s;
}
.product-wrapper:hover .product-action > a.animate-top {
    transform: translateY(0px);
}
.product-wrapper:hover .product-action {
    opacity: 1;
}

/* 2.8 Banner style 2 */

.banner-wrapper {
    position: relative;
    overflow: hidden;
}
.banner-wrapper-content {
    left: 0;
    padding: 20px 20px 20px 60px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.banner-wrapper-content > h3 {
    color: #004a8d;
    font-size: 36px;
    font-weight: bold;
    line-height: 23px;
    margin-bottom: 21px;
    text-transform: uppercase;
}
.banner-wrapper-content > h3 span {
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 14px;
}
.banner-wrapper-content > h2 {
    color: #004a8d;
    font-size: 36px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
}
.banner-wrapper-content > h2 span {
    font-weight: 300;
}
.banner-wrapper img {
    width: 100%;
    transition: all .3s ease 0s;
}
.banner-wrapper-content2 > h3 {
    color: #000000;
    font-family: 'Roboto Slab', serif;
    font-size: 30px;
    font-weight: bold;
    line-height: 29px;
    text-transform: uppercase;
}
.banner-wrapper-content2 {
    left: 0;
    padding: 113px 40px 30px;
    position: absolute;
    top: 0;
}
.banner-wrapper-content2 > a {
    color: #626262;
    font-weight: bold;
    text-transform: uppercase;
}
.banner-wrapper-content2 > a:hover {
    color: #050035;
}
.banner-wrapper-content3 {
    left: 0;
    padding: 50px 40px;
    position: absolute;
    top: 0;
}
.banner-wrapper-content3 > h3 {
    color: #004a8d;
    font-weight: bold;
    line-height: 30px;
    margin: 0;
    text-transform: uppercase;
}
.banner-wrapper-content3 > h3 span {
    font-weight: 300;
}
.mrgn-r-4 {
    margin-right: 4px;
}
.mrgn-b-4 {
    margin-bottom: 4px;
}
.banner-wrapper-content4 {
    left: 0;
    padding: 60px 35px;
    position: absolute;
    top: 0;
}
.banner-wrapper-content4 > h4 {
    color: #383838;
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 1px;
}
.banner-wrapper-content4 > h2 {
    color: #f51424;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
}
.banner-wrapper:hover a img {
    transform: scale(1.1);
}
/* all products */
.custom-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
}
.custom-col-style {
    padding-left: 20px;
    padding-right: 20px;
}
.custom-col-5 {
    flex: 0 0 20%;
    max-width: 20%;
}
.product-tab-list {
    display: flex;
    justify-content: center;
}
.product-tab-list a h4 {
    color: #999999;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
    transition: all .3s ease 0s;
    letter-spacing: .5px;
}
.product-tab-list a {
    color: #999999;
}
.product-tab-list a.active h4,
.product-tab-list a h4:hover {
    color: #050035;
}
.product-tab-list a {
    line-height: 1;
    margin: 0 28px;
}

/* 2.9 Brand logo style */

.brand-logo-active {
    display: flex;
    justify-content: space-between;
}
.single-brand {
    display: flex;
    justify-content: center;
}
.brand-logo-active.owl-carousel .owl-item img {
    width: auto;
}

/* 2.10 Banner 3 style  */

.banner-wrapper3-content > img,
.banner-wrapper2-content img {
    width: 100%;
}
.row.no-gutters.banner-mrg {
    margin-left: 23px;
}
.banner-wrapper3-content {
    bottom: 0;
    left: 0;
    padding: 40px;
    position: absolute;
    right: 0;
    text-align: center;
    opacity: 0;
    transition: all .3s ease 0s;
}
.banner-wrapper:hover .banner-wrapper3-content {
    opacity: 1;
}
.banner-wrapper3-content > a {
    border-bottom: 1px solid #383838;
    color: #383838;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1;
    text-transform: uppercase;
}
.banner-wrapper3-content > a:hover {
    color: #050035;
    border-bottom: 1px solid #050035;
}
.banner-wrapper.mrgn-negative {
    margin-right: -17px;
}
.mrgn-b-5 {
    margin-bottom: 5px
}
.mrgn-r-5 {
    margin-right: 5px;
}
.banner-wrapper2-content {
    bottom: 0;
    padding: 40px 50px 188px;
    position: absolute;
    right: 0;
    text-align: right;
}
.banner-wrapper2-content > h3 {
    color: #1f1e26;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 5.5px;
    margin: 0;
    text-transform: uppercase;
}
.banner-wrapper2-content > h2 {
    color: #1f1e26;
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    margin: 5px 4px;
    text-transform: uppercase;
}
.banner-wrapper2-content > span {
    color: #1f1e26;
    font-size: 15px;
    letter-spacing: 0.4px;
    line-height: 1;
    text-transform: uppercase;
}
.electronic-banner-wrapper {
    overflow: hidden;
}
.electronic-banner-wrapper img {
    transition: all .3s ease 0s;
}
.banner-wrapper:hover img , .electronic-banner-wrapper:hover img {
    transform: scale(1.1);
}
.banner-wrapper3-content.banner-text-color > a {
    border-bottom: 1px solid #fff;
    color: #fff;
}
.banner-wrapper3-content.banner-text-color > a:hover {
    border-bottom: 1px solid #ddd;
    color: #ddd;
}

/* 2.11 Instafeed style */

#Instafeed > li {
    display: inline-block;
    padding-right: 17px;
    width: 20%;
}
#Instafeed > li a img {
    width: 100%;
}
#Instafeed > li:last-child {
    padding-right: 0px;
}
.section-title-2 > h2 {
    color: #383838;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 18px;
    text-transform: uppercase;
}
.section-title-2.text-center > h4 {
    color: #666666;
    font-size: 18px;
    letter-spacing: 4px;
    margin: 0;
}
.section-title-2 > h4 span {
    color: #02bffc;
}

/* 2.12 Footer style */

.footer-widget {
    position: relative;
    z-index: 9;
}
.footer-widget-title {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 40px;
    text-transform: uppercase;
}
.footer-widget-content li {
    margin-bottom: 20px;
}
.footer-widget-content li:last-child {
    margin-bottom: 0px;
}
.footer-widget-content li a {
    color: #999999;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.footer-widget-content li a:hover {
    color: #fff;
}
.footer-newsletter > p {
    color: #999999;
    margin: 0;
}
.subscribe-form form {
    position: relative;
}
.subscribe-form form input {
    background: #1e1e1e none repeat scroll 0 0;
    border: 2px solid transparent;
    color: #999999;
    font-size: 12px;
    height: 45px;
    padding-left: 30px;
    padding-right: 55px;
    position: relative;
}
.mc-form .mc-news {
    display: none;
}
.subscribe-form form input::-moz-placeholder {
    color: #999999;
    opacity: 1;
}
.subscribe-form form input::-webkit-placeholder {
    color: #999999;
    opacity: 1;
}
.subscribe-form .mc-form .clear {
    background: rgba(0, 0, 0, 0) url("../../img/icon-img/1.png") no-repeat scroll right 0 center;
    bottom: 0;
    display: inline-block;
    position: absolute;
    right: 20px;
    z-index: 9999;
}
.subscribe-form .clear input {
    border: medium none;
    padding: 0;
    text-indent: -99999px;
    width: 37px;
    background-color: transparent;
}
.footer-contact > p {
    color: #999999;
    margin-bottom: 11px;
}
.footer-contact > p span i {
    font-size: 20px;
}
.footer-contact > p span {
    display: inline-block;
    width: 33px;
}
.footer-contact > p:last-child {
    margin-bottom: 0px;
}
.subscribe-form {
    margin: 28px 0 40px;
}
.copyright > p {
    color: #999;
    line-height: 1;
    margin: 0;
    letter-spacing: 1px;
}
.copyright > p a {
    color: #fff;
}
.copyright > p a:hover {
    text-decoration: underline;
}
/*----------- 3. Book page ---------*/
/* 3.1 Header style of book */
.header-top {
    display: flex;
    justify-content: space-between;
    padding: 19px 0 20px;
}
.book-login-register > ul {
    display: flex;
}
.header-info > span {
    color: #e8e8e8;
}
.header-info > span a {
    color: #e8e8e8;
}
.header-info > span a:hover {
    color: #fff;
}
.book-login-register ul li {
    margin-left: 30px;
}
.book-login-register ul li:first-child {
    margin-left: 0px;
}
.book-login-register a {
    color: #e8e8e8;
    text-transform: capitalize;
}
.book-login-register a:hover {
    color: #fff;
}
.book-login-register a i {
    margin-right: 5px;
    position: relative;
    top: 2px;
}

/* 3.2 Menu style of book */

.menu-style-2 nav > ul > li {
    display: inline-block;
    margin: 0 16px;
    position: relative;
}
.menu-style-2.book-menu nav > ul > li {
    margin: 0 30px;
}
.header-bottom-wrapper {
    display: flex;
    justify-content: space-between;
}
.header-cart-2 {
    position: relative;
}
.header-cart-2 .icon-cart {
    display: block;
    padding: 44px 0 42px;
    position: relative;
}
.menu-style-2 {
    align-items: center;
    display: flex;
}
.header-cart-2 .icon-cart i {
    color: #383838;
}
.menu-style-2 nav > ul > li > a {
    color: #383838;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    padding: 50px 0 51px;
    text-transform: capitalize;
}
.shop-count.book-count {
    top: 50%;
    transform: translateY(-50%);
}
.menu-style-2 ul li a:hover {
    color: #f44344;
}
.menu-style-2 ul li:hover a {
    color: #050035;
}

/* 3.3 Slider style book */

.single-slider-book1 {
    min-height: 760px;
}
.slider-content-book h1 {
    color: #383838;
    font-size: 60px;
    font-weight: 400;
    line-height: 53px;
    margin-bottom: 8px;
    text-transform: uppercase;
}
.slider-content-book h1 span {
    color: #f44344;
}
.slider-content-book h2 {
    font-size: 60px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}
.slider-content-book p {
    color: #383838;
    font-size: 16px;
    line-height: 26px;
    margin: 16px 0 46px;
    width: 35%;
}
.slider-content-book > a {
    background-color: #f44344;
    border-radius: 50px;
    color: #f5fcff;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    padding: 16px 40px 15px;
    text-transform: uppercase;
}
.slider-content-book > a:hover {
    box-shadow: 0 10px 35px -7px rgba(244, 67, 68, 0.9);
    transform: translateY(-3px);
}
.single-slider-book1 {
    -moz-box-align: center;
    align-items: center;
    display: flex;
    position: relative;
}
.owl-item.active .fadeinup-animated img {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.owl-item.active .fadeinup-animated img.slide-img-position {
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.owl-item.active .fadeinup-animated h1 {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.owl-item.active .fadeinup-animated h2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.owl-item.active .fadeinup-animated h4 {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.owl-item.active .fadeinup-animated p {
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.owl-item.active .fadeinup-animated a {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

/* 3.4 Banner style book */

.book-banner-wrapper > img {
    width: 100%;
}

.book-banner-wrapper {
    position: relative;
}
.banner-book-style1 {
    padding: 87px 60px 30px;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}
.banner-book-style1 > h3 {
    font-size: 30px;
    letter-spacing: 5px;
    line-height: 39px;
    margin: 0;
}
.banner-book-style1 > h2 {
    color: #383838;
    font-size: 30px;
    font-weight: bold;
    margin: 8px 0 22px;
}
a.banner-book-style-btn {
    background-color: #f44344;
    border-radius: 50px;
    color: #ffffff;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    padding: 15px 30px 14px;
    text-transform: uppercase;
}
a.banner-book-style-btn:hover {
    box-shadow: 0 10px 35px -7px rgba(244, 67, 68, 0.9);
    transform: translateY(-3px);
}
.banner-book-style2 {
    left: 0;
    padding: 60px 79px;
    position: absolute;
    right: 0;
    top: 0;
}
.banner-book-style2 > h2 {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 12px;
    line-height: 25px;
    margin: 0;
    text-transform: uppercase;
}
.banner-book-style2 > p {
    color: #383838;
    margin: 8px 0 22px;
}

/* 3.5 product style book */

.section-title-3 > h2 {
    display: inline-block;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
    position: relative;
    text-transform: capitalize;
}
.section-title-3 > h2::after {
    background-color: #666666;
    content: "";
    height: 2px;
    position: absolute;
    right: -69px;
    top: 19px;
    width: 40px;
}
.section-title-3 > h2::before {
    background-color: #666666;
    content: "";
    height: 2px;
    left: -69px;
    position: absolute;
    top: 19px;
    width: 40px;
}
.product-tab-list2 {
    display: flex;
    justify-content: center;
}
.product-tab-list2 a h4 {
    color: #999999;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
}
.product-tab-list2 a.active h4,
.product-tab-list2 a h4:hover {
    color: #383838;
}
.product-tab-list2 a.active {
    position: relative;
}
.product-tab-list2 a {
    line-height: 1;
    margin: 0 39px;
}
.product-tab-list2 a.active::before {
    background-color: #9b9b9b;
    content: "";
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 8px;
    width: 100%;
}
.product-img-2,
.new-top-img {
    position: relative;
}
.product-img-2::before,
.new-top-img::before {
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: 0 0 0;
    transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0s, opacity 0s ease 0.4s;
}
.product-wrapper:hover .product-img-2::before,
.new-top-wrapper:hover .new-top-img::before {
    transform: scaleX(1);
    opacity: 1;
    transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0s, opacity 0s ease 0s;
}
.product-img-2 img {
    width: 100%;
}
.product-action-2 {
    bottom: 0;
    display: inline-block;
    left: 0;
    opacity: 1;
    padding: 60px 26px;
    position: absolute;
    right: 0;
    text-align: center;
    transition: all 0.3s ease 0s;
    z-index: 999;
    transform: scaleX(0);
    opacity: 0;
    transform-origin: 0 0 0;
    transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1) 0s, opacity 0s ease 0.3s;
}
.product-action-2 a.add-style-2 {
    background-color: #fff;
    border-radius: 50px;
    color: #383838;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    margin: 0 8px;
    padding: 10px 20px 12px;
}
.product-action-2 a.wishlist-style-2 {
    background-color: #fff;
    border-radius: 100%;
    color: #383838;
    display: inline-block;
    font-size: 18px;
    height: 40px;
    line-height: 45px;
    margin: 0 8px;
    width: 40px;
    bottom: -2px;
    position: relative;
}
.product-action-2 a.add-style-2 i {
    font-size: 18px;
    margin-left: 15px;
    position: relative;
    top: 2px;
}
.product-action-2 a.add-style-2:hover,
.product-action-2 a.wishlist-style-2:hover {
    background-color: #f44344;
    color: #f1f1f1;
}
.product-wrapper:hover .product-action-2 {
    transform: scaleX(1);
    opacity: 1;
    transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0s, opacity 0s ease 0s;
}
.writer-wrapper > img {
    width: 100%;
}
.product-content-2 > h4 {
    color: #383838;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 13px;
}
.product-content-2 > span {
    color: #383838;
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.product-rating i {
    color: #999999;
    font-size: 16px;
}
.product-rating i.black {
    color: #000000;
    font-size: 16px;
    margin: 0 1px;
}
.product-content-2 {
    padding-top: 26px;
}
.book-list-active {
    padding-left: 80px;
}
.writer-content > h4 {
    color: #999999;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 6px;
    position: relative;
}
.writer-content > span {
    color: #383838;
    line-height: 22px;
}
.writer-content {
    margin: 30px 0 0 66px;
}
.book-list-hover {
    margin-top: 46px;
}
.writer-content > h4::before {
    background-color: #999999;
    content: "";
    height: 1px;
    left: -66px;
    position: absolute;
    top: 12px;
    width: 40px;
}
.product-content-3 > h4 {
    color: #383838;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.product-rating-2 i {
    color: #000000;
    margin: 0 2px;
}
.product-content-3 {
    padding-top: 26px;
}
.book-list-active.owl-carousel .owl-nav div {
    color: #999999;
    font-size: 30px;
    left: 31px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.6s ease 0s;
    visibility: hidden;
}
.book-list-active.owl-carousel .owl-nav div:hover {
    color: #f44344;
}
.book-list-active.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: -47px;
}
.book-list-hover:hover .book-list-active.owl-carousel .owl-nav div {
    opacity: 1;
    visibility: visible;
}

/* 3.6 Testimonial style book */

.single-testimonial i {
    color: #ffffff;
    font-size: 72px;
}
.single-testimonial > p {
    color: #ffffff;
    font-size: 24px;
    line-height: 38px;
    margin: 26px auto 31px;
    width: 73%;
}
.single-testimonial > h4 {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.single-testimonial > span {
    color: #ffffff;
    font-size: 14px;
}

/* 3.7 Discount style book */

.discount-area {
    display: block;
    overflow: hidden;
}
.discount-left {
    width: 43%;
    float: left;
}
.discount-right {
    float: left;
    width: 57%;
}
.new-top-wrapper {
    background-color: #fff;
    display: flex;
    padding: 15px;
}
.new-top-title {
    color: #383838;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 33px;
    text-transform: uppercase;
}
.new-top-content > h4 {
    color: #353535;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    margin-bottom: 2px;
    text-transform: uppercase;
}
.new-top-content > h4 a {
    color: #353535;
}
.new-top-content > h4 a:hover {
    color: #f44344;
}
.new-top-content > span {
    color: #353535;
    letter-spacing: 0.3px;
}
.product-price {
    display: flex;
    line-height: 1;
    margin: 15px 0 17px;
}
.product-price .old-price span {
    color: #828282;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
    margin-top: 4px;
    text-decoration: line-through;
}
.product-price .new-price span {
    color: #353535;
    font-size: 24px;
    font-weight: bold;
}
.product-rating-3 i {
    color: #0e0e0e;
    font-size: 18px;
    margin: 0 1px;
}
.new-top-content {
    padding: 25px 0 0 35px;
}
.discount-book-cart {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    left: 0;
    right: 0;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
}
.new-top-wrapper:hover .discount-book-cart {
    opacity: 1;
}
.discount-book-cart a {
    background-color: #fff;
    border-radius: 50px;
    color: #383838;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    height: 45px;
    line-height: 52px;
    width: 45px;
    text-align: center;
}
.discount-book-cart a:hover {
    background-color: #f44344;
    color: #fff;
}
.discount-book-cart a i {
    font-size: 20px;
    margin-left: 2px;
}
.discount-left > h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 23px;
}
.discount-left > a {
    background-color: #383838;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    padding: 14px 40px 13px;
    text-transform: uppercase;
}
.discount-left > a:hover {
    box-shadow: 0 10px 35px -7px rgba(0, 0, 0, 0.9);
    transform: translateY(-3px);
}

/* 3.8 Blog style book */

.blog-info > h4 {
    color: #383838;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 6px;
}
.blog-info > h4 a {
    color: #383838;
}
.blog-info > h4 a:hover {
    color: #f44344;
}
.blog-info > span {
    color: #383838;
}

.blog-hover {
    position: relative;
    overflow: hidden;
}
.blog-hover::after {
    background: #fff none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: -110%;
    opacity: 0.2;
    position: absolute;
    top: 0;
    transform: rotate(90deg);
    transition: all 3300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    width: 100%;
    z-index: 999;
}
.blog-wrapper:hover .blog-hover::after {
    left: 120%;
    transition: all 3300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

/* 3.9 Subscribe style book */

.subscribe-content > h3 {
    color: #f1f1f1;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 13px;
    text-transform: uppercase;
}
.subscribe-content > p {
    color: #a8a8a8;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
}
.subscribe-form-2 form input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #787878;
    border-radius: 50px;
    box-shadow: none;
    color: #666666;
    font-size: 14px;
    height: 48px;
    padding-left: 50px;
    padding-right: 180px;
    width: 100%;
}
.subscribe-form-2 form input::-moz-placeholder {
    color: #666666;
    opacity: 1;
}
.subscribe-form-2 form input::-webkit-placeholder {
    color: #666666;
    opacity: 1;
}
.subscribe-form-2 .clear input.button {
    border: medium none;
    color: #eeeeee;
    font-weight: bold;
    padding: 0 10px;
    width: inherit;
}
.subscribe-form-2 .clear input.button:hover {
    color: #f44344;
}
.subscribe-form-2 form {
    position: relative;
}
.subscribe-form-2 .mc-form .clear {
    bottom: 0;
    display: inline-block;
    position: absolute;
    right: 40px;
    z-index: 9999;
}
.subscribe-form-2 .mc-form .clear::before {
    background: #757575 none repeat scroll 0 0;
    content: "";
    height: 40px;
    left: -31px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
}
.subscribe-form-2 {
    margin-top: 27px;
}

/* 3.10 Footer style of book */

.footer-widget-title-2 {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 35px;
}
.footer-info-icon {
    width: 35px;
    margin-top: 6px;
}
.footer-info-icon i {
    font-size: 20px;
    color: #999999;
}
.footer-address {
    display: flex;
    margin-bottom: 19px;
}
.footer-address:last-child {
    margin-bottom: 0px;
}
.footer-info-content > p {
    color: #999999;
    line-height: 28px;
    margin: 0;
    font-size: 16px;
}
.footer-info-content > p a {
    color: #999999;
}
.footer-info-content > p a:hover {
    color: #f44344;
}
.footer-widget-content-2 ul li a {
    color: #999999;
    font-size: 16px;
}
.footer-widget-content-2 ul li a:hover {
    color: #f44344;
}
.footer-widget-content-2 li {
    margin-bottom: 16px;
}
.footer-widget-content-2 li:last-child {
    margin-bottom: 0px;
}
#footer-map {
    height: 220px;
    width: 100%;
}
#footer-map-2 {
    height: 220px;
}
.footer-map-width {
    width: 318px;
}
.payment-img {
    float: right;
}
.copyright-2 > p {
    color: #666666;
    margin: 2px 0 0;
}
.copyright-2 > p a {
    color: #f44344;
}
.copyright-2 > p a:hover {
    text-decoration: underline;
}
/*--------- 4. Electronics page ---------*/
/* 4.1 Header style of electronics */

.header-info-wrapper {
    display: flex;
    justify-content: space-between;
}
.header-contact-info {
  padding-top: 2px;
}
.header-contact-info li {
    color: #666666;
    display: inline-block;
    margin-right: 57px;
    position: relative;
}
.header-contact-info li::before {
    background-color: #e7e7e7;
    content: "";
    height: 25px;
    margin: 0 auto;
    position: absolute;
    right: -30px;
    top: 0;
    width: 1px;
}
.header-contact-info li:last-child::before {
    content: inherit;
}
.header-contact-info li a {
    color: #666666;
}
.header-contact-info.header-contact-info2 li a,
.header-contact-info.header-contact-info2 li {
    color: #8185a0;
    font-weight: bold;
}
.header-contact-info li i {
    font-size: 18px;
    position: relative;
    top: 4px;
    margin-right: 5px;
}
.electronics-login-register > ul > li {
  display: inline-block;
  margin-left: 66px;
  position: relative;
    padding: 0 0 10px;
}
.electronics-login-register ul li ul {
  background-color: #fff;
  box-shadow: 0 0 15px 0.08px rgba(0, 0, 0, 0.15);
  left: -30px;
  opacity: 0;
  padding: 20px;
  position: absolute;
  text-align: left;
  top: 110%;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 130px;
  z-index: 9999;
}
.electronics-login-register ul li:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.electronics-login-register ul li ul li {
  display: block;
  padding-bottom: 10px;
}
.electronics-login-register ul li ul li > a {
  display: block;
  text-transform: capitalize;
}
.header-contact-info li:last-child {
    margin-right: 0px;
}
.electronics-login-register ul li:last-child {
    margin-right: 0px;
}
.electronics-login-register ul li:first-child {
    margin-left: 0px;
}
.electronics-login-register ul li a {
    color: #666;
    position: relative;
}
.electronics-login-register ul li a i,
.electronics-login-register ul li a span {
    font-size: 18px;
    position: relative;
    top: 3px;
    margin-right: 10px;
    color: #666;
}
.electronics-login-register > ul > li > a::before {
    background-color: #e4e4e4;
    content: "";
    height: 10px;
    margin: 0 auto;
    position: absolute;
    right: -37px;
    top: 7px;
    width: 1px;
}
.electronics-login-register ul li a.border-none::before {
    content: none;
}
.header-top-wrapper-2 {
  padding: 13px 0 3px;
}
.header-contact-info li a:hover,
.electronics-login-register ul li a:hover {
    color: #050035;
}
.trace-cart-wrapper {
    display: flex;
}
.categories-search-wrapper {
    background-color: #bcf2ff;
    padding: 10px 30px 10px 15px;
    display: flex;
    border-radius: 5px;
}
.categories-search-wrapper.categories-search-wrapper2 {
    background-color: transparent;
    border: 2px solid #b4b5bc;
}
.select-wrapper select {
    -moz-appearance: none;
    -webkit-appearance: none;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: transparent;
    border-color: currentcolor #a7a7a7 currentcolor currentcolor;
    border-image: none;
    border-style: none solid none none;
    border-width: medium 1px medium medium;
    color: #666666;
    font-size: 12px;
    height: 30px;
    letter-spacing: 1px;
    padding: 0 0 0px 12px;
    text-transform: capitalize;
    width: 169px;
    background: rgba(0, 0, 0, 0) url("../../img/icon-img/4.png") no-repeat scroll right 40px center;
}

.select-wrapper select option {
    padding-left: 12px;
}

.categories-wrapper form input {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    box-shadow: none;
    color: #666666;
    font-size: 12px;
    height: 30px;
    letter-spacing: 1px;
    padding-left: 40px;
    width: 530px;
    padding-right: 100px;
}
.categories-wrapper form {
    position: relative;
}
.categories-wrapper form input::-moz-placeholder {
    color: #666666;
    opacity: 1;
}
.categories-wrapper form input::-webkit-placeholder {
    color: #666666;
    opacity: 1;
}
.categories-wrapper button {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: transparent;
    border-color: currentcolor currentcolor currentcolor #a7a7a7;
    border-image: none;
    border-style: none none none solid;
    border-width: medium medium medium 1px;
    color: #666666;
    cursor: pointer;
    font-size: 12px;
    height: 30px;
    letter-spacing: 1px;
    padding-left: 30px;
    padding-right: 0;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
}
.categories-wrapper button:hover {
    color: #000;
}
.same-style {
    display: flex;
    margin-left: 40px;
    margin-top: 6px;
}
.same-style:first-child {
    margin-left: 0px;
}
.same-style-icon > a i {
    color: #666666;
    font-size: 36px;
}
.same-style-text > a {
    color: #666666;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    margin-top: 2px;
}
.same-style-text > a:hover {
    color: #222;
}
.same-style-text {
    margin-left: 10px;
}
.categori-menu-slider-wrapper {
    display: block;
    padding-bottom: 100px;
    padding-top: 15px;
    width: 100%;
}
.categories-menu {
    display: block;
    float: left;
    width: 20%;
}
.menu-slider-wrapper {
    float: left;
    padding-left: 30px;
    width: 80%;
}
.category-menu-list {
    box-shadow: 0 0 15px 0.08px rgba(0, 0, 0, 0.15);
    padding: 40px 0px;
}
.category-heading > h3 {
    background-color: #2b2b2b;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 16px 60px 15px;
    position: relative;
    text-transform: uppercase;
}
.category-heading > h3 i {
    color: #ffffff;
    font-size: 30px;
    position: absolute;
    top: 9px;
    right: 50px;
}
.category-menu-list > ul > li {
  position: relative;
}
.category-menu-list li:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.category-menu-list > ul > li > a {
    color: #666666;
    font-weight: bold;
    font-size: 14px;
}
.category-menu-list > ul > li > a > img {
    margin-right: 10px;
}
.category-menu-list > ul > li > a > i {
    color: #383838;
    font-size: 30px;
    position: absolute;
    right: 9px;
    top: -5px;
}
.overflow {
    overflow: hidden;
}
.category-menu-list ul li:hover a {
    color: #050035;
}
.category-menu-dropdown {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 0 15px 0.08px rgba(0, 0, 0, 0.15);
    left: 110%;
    opacity: 0;
    padding: 35px 0 40px;
    position: absolute;
    text-align: left;
    top: 0;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 970px;
    z-index: -9;
}
.category-menu-list ul li:hover .category-menu-dropdown {
    left: 100%;
    opacity: 1;
    padding-right: 25px;
    visibility: visible;
    z-index: 99999;
}
.category-common {
    float: left;
    padding-right: 27px;
    width: 50%;
}
.category-common3 {
    float: left;
    padding-left: 100px;
    padding-right: 27px;
    width: 33.333%;
    position: relative;
}
.category-common4 {
    float: left;
    padding-left: 60px;
    padding-right: 25px;
    position: relative;
    width: 25%;
}
.category-common3::before {
    background: #c6c6c6 none repeat scroll 0 0;
    content: "";
    height: 240px;
    left: 0;
    position: absolute;
    top: 30px;
    width: 1px;
}
.category-common4::before {
    background: #c6c6c6 none repeat scroll 0 0;
    content: "";
    height: 240px;
    left: 0;
    position: absolute;
    top: 30px;
    width: 1px;
}
.category-common4:first-child::before,
.category-common3:first-child::before {
    display: none;
}
.category-menu-list ul li .category-common h4,
.category-menu-list ul li .category-common2 h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.category-menu-list ul li .category-common ul li,
.category-menu-list ul li .category-common2 ul li {
    border: medium none;
}
.category-menu-list ul li .category-common ul li a,
.category-menu-list ul li .category-common2 ul li a {
    padding: 3px 0;
    color: #333;
    background-color: transparent;
}
.category-menu-list ul li .category-common ul li a:hover,
.category-menu-list ul li .category-common2 ul li a:hover {
    background-color: transparent;
    color: #050035;
}
.category-menu-list ul li .mega-banner-img a {
    padding: 0;
    background-color: transparent;
}
.category-menu-list ul li .mega-banner-img a,
.category-menu-list ul li .category-menu-dropdown-right .menu-right-img a {
    background-color: transparent;
    display: inline-block;
    padding: 0;
}
.category-menu-dropdown-top {
    overflow: hidden;
}
.category-menu-dropdown-left {
    float: left;
    overflow: hidden;
    width: 65%;
}
.category-menu-dropdown-bottom {
    background-color: #f6f6f6;
    display: block;
    overflow: hidden;
    padding: 40px 10px;
}
.single-category-brand {
    float: left;
    padding: 0 34px;
    width: 25%;
}
.category-menu-list ul li .category-menu-dropdown-bottom .single-category-brand a {
    padding: 0;
    opacity: .4;
}
.category-menu-list ul li .category-menu-dropdown-bottom .single-category-brand a:hover {
    opacity: 1;
}
.category-menu-dropdown-right {
    overflow: hidden;
    width: 35%;
}
.categories-subtitle {
    color: #383838;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 27px;
    padding-bottom: 10px;
    position: relative;
    text-transform: uppercase;
}
.categories-subtitle::before {
    background-color: #c6c6c6;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 40px;
}
.category-dropdown-style ul li {
    padding-bottom: 9px;
}
.category-dropdown-style ul li:last-child {
    padding-bottom: 0px;
}
.category-menu-list .category-dropdown-style ul li a {
    background-color: transparent;
    color: #383838;
    font-size: 14px;
    text-transform: capitalize;
}
.category-dropdown-style ul li a:hover {
    color: #050035;
}
.category-dropdown-style ul {
    position: relative;
}
.category-dropdown-style ul.border-none:last-child::before {
    content: none;
}
.mega-banner-img {
    display: inline-block;
    padding-left: 60px;
    padding-right: 60px;
}
.mega-banner-img img {
    width: 100%;
}

/* 4.2 Menu style of electronics */

.menu-style-3 {
    background-color: #2b2b2b;
    padding: 0px 20px 0px;
}
.menu-style-3 nav > ul > li {
    display: inline-block;
    position: relative;
    margin: 0 45px;
}
.menu-style-3 nav > ul > li > a {
    color: #f3f3f3;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 48px;
    text-transform: uppercase;
}
.menu-style-3 ul > li > a > i {
    color: #efefef;
    font-size: 30px;
    position: relative;
    top: 8px;
}
.menu-style-3 ul > li > a > span {
    background-color: #fe0000;
    color: #ffffff;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    padding: 4px 4px 3px;
    position: absolute;
    right: 36px;
    text-transform: uppercase;
    top: -10px;
}
.menu-style-3 ul > li > a > span::after {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #fe0000;
    content: "";
    height: 0;
    left: 3px;
    position: absolute;
    top: 16px;
    width: 0;
}
.menu-hover {
    position: relative;
}
.single-dropdown {
    background-color: #ffffff;
    left: 0;
    padding: 20px;
    position: absolute;
    text-align: left;
    top: 110%;
    width: 210px;
    z-index: 9999;
    box-shadow: 0 0 15px 0.08px rgba(0, 0, 0, 0.15);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s;
}
.menu-hover ul li:hover .single-dropdown {
    opacity: 1;
    top: 100%;
    visibility: visible;
}
.menu-hover .single-dropdown li,
.shop-menu .category-dropdown-style ul li {
    display: block;
    margin: 0;
    padding: 0 0 12px;
    text-align: inherit;
}
.menu-style-3 .single-dropdown li:last-child,
.menu-style-2 .single-dropdown li:last-child,
.menu-style-4 .single-dropdown li:last-child {
    padding: 0 0 0px;
}
.menu-hover ul li .single-dropdown li a,
.shop-menu .category-dropdown-style ul li a {
    color: #666666;
    display: block;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 0;
    line-height: inherit;
}
.menu-hover .single-dropdown li a:hover,
.shop-menu .category-dropdown-style ul li a:hover {
    color: #85bd02;
    padding: 0 0 0 5px;
}
/* mega menu */
.category-menu-dropdown.shop-menu {
    left: 0px;
    opacity: 0;
    top: 110%;
    visibility: hidden;
    width: 500px;
}
.category-common2 {
    float: left;
    padding-left: 40px;
    padding-right: 25px;
    position: relative;
    width: 50%;
}
.menu-style-2 .mega-banner-img a {
    padding: 0px 0 0px;
}
.menu-hover ul li:hover .category-menu-dropdown.shop-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
    z-index: 99999;
}
.shop-menu .mega-banner-img {
    padding-left: 40px;
    padding-right: 40px;
}

/* 4.3 Slider style of electronics */

.slider-content-style-3 h2 {
    color: #1a1a1a;
    font-size: 60px;
    line-height: 70px;
    margin: 0;
}
.slider-content-style-3 h4 {
    color: #1a1a1a;
    font-size: 16px;
    letter-spacing: 2px;
    margin: 27px 0 25px;
}
.electro-slider-btn {
    background-color: #fff;
    border-radius: 5px;
    color: #1a1a1a;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    padding: 15px 40px 14px;
    text-transform: uppercase;
    z-index: 99;
}
.electro-slider-btn:hover {
    color: #fff;
}
.btn-hover.electro-slider-btn::before {
    border-radius: 4px;
}
.slider-content-style-3 {
    margin-left: 65px;
}
.custom-row-2 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12.5px;
    margin-right: -12.5px;
}
.custom-col-style-2 {
    padding-left: 12.5px;
    padding-right: 12.5px;
}
.electronic-banner-col-3 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
}
.electronic-banner-wrapper > img {
    width: 100%;
}
.electronic-banner-area {
    padding: 0 200px;
}

/* 4.4 Banner style of electronics */

.electronic-banner-wrapper {
    position: relative;
}
.electro-banner-position {
    left: 0;
    padding: 64px 50px;
    position: absolute;
    top: 0;
}
.electro-banner-position2 {
    padding: 63px 47px;
    position: absolute;
    right: 0;
    top: 0;
}
.electro-banner-position3 {
    left: 0;
    padding: 65px 60px;
    position: absolute;
    top: 0;
}
.electro-banner-style > h1 {
    color: #515151;
    font-size: 30px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}
.electro-banner-style > h2 {
    color: #515151;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin: 6px 0 3px;
    text-transform: uppercase;
}
.electro-banner-style > h4 {
    color: #525252;
    font-size: 14px;
    letter-spacing: 3px;
    margin-bottom: 10px;
}
.electro-banner-style > a {
    color: #515151;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}
.electro-banner-style > a:hover {
    color: #050035;
    padding-left: 5px;
}
.section-title-4 > h2 {
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 1;
}
.section-title-4 > h2::after {
    background-color: #2c2c2c;
    content: "";
    height: 2px;
    position: absolute;
    right: -81px;
    top: 19px;
    width: 60px;
}
.section-title-4 > h2::before {
    background-color: #2c2c2c;
    content: "";
    height: 2px;
    left: -81px;
    position: absolute;
    top: 19px;
    width: 60px;
}

/* 4.5 Product style of electronics */

.product-tab-list3 {
    display: flex;
    justify-content: center;
}
.product-tab-list3 a {
    line-height: 1;
    margin: 0 39px;
}
.product-tab-list3 a {
    line-height: 1;
    margin: 0 15px;
}
.product-tab-list3 a h4 {
    border: 1px solid #666666;
    border-radius: 50px;
    color: #383838;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1;
    margin: 0;
    padding: 12px 35px 11px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
}
.wrapper-padding .container-fluid {
    padding: 0 200px
}
.custom-col-4 {
    flex: 0 0 25%;
    max-width: 25%;
}
.product-border {
    border: 1px solid #eeeeee;
}
.product-img-3 img {
    width: 100%;
}
.product-rating-4 i {
    color: #999999;
    font-size: 18px;
    margin: 0 2px;
}
.product-rating-4 i.yellow {
    color: #ffc600;
}
.product-content-4 > h4 {
    color: #383838;
    font-size: 18px;
    font-weight: bold;
    margin: 12px 0 2px;
}
.product-content-4 > h4 a {
    color: #383838;
}
.product-content-4 > h4 a {
    color: #383838;
}
.product-content-4 > h4 a:hover {
    color: #050035;
}
.product-content-4 > span {
    color: #383838;
    display: block;
    font-size: 13px;
    margin-bottom: 14px;
}
.product-content-4 h5 {
    color: #383838;
    font-size: 24px;
    margin: 0;
}
.product-content-4 {
    padding: 29px 15px 25px;
}
.product-tab-list3 a.active h4,
.product-tab-list3 a h4:hover {
    color: #050035;
    border: 1px solid #050035;
}
.electro-fexible-banner > img {
    width: 100%;
}
.electro-fexible-banner {
  cursor: pointer;
  position: relative;
}
.electro-fexible-banner:hover .fexible-content > h3 , .electro-fexible-banner:hover .fexible-content > p  , .electro-fexible-banner:hover .fexible-content > a , .furits-banner-wrapper:hover .furits-banner-content > h4 , .furits-banner-wrapper:hover .furits-banner-content > p , .furits-banner-wrapper:hover .furits-banner-content > a , .single-banner:hover .banner-content > span , .single-banner:hover .banner-content > h2 , .single-banner:hover .banner-content > p  , .discount-wrapper:hover .discount-content > h2 , .discount-wrapper:hover .discount-content > a {
    animation: 700ms ease-in-out 0s normal none 1 running fadeInUp;
}


.furits-banner-wrapper ,
.discount-wrapper {
  cursor: pointer;
}
.fexible-content {
    padding: 75px 294px 77px;
}
.fexible-content > h3 {
    color: #383838;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
}
.fexible-content p {
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    margin: 4px 0 26px;
}
.btn-hover {
    position: relative;
}
.flexible-btn {
    border: 1px solid #000000;
    border-radius: 5px;
    color: #383838;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    padding: 14px 40px 12px;
    text-transform: uppercase;
    z-index: 9;
}
.flexible-btn:hover {
    border: 1px solid #000000;
    color: #fff;
}
.btn-hover::before {
    background: #000 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;
    z-index: -1;
}
.btn-hover:hover::before {
    transform: scaleX(1);
}
.product-wrapper > img {
    width: 100%;
}
.custom-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 6px;
    padding-right: 0px;
    width: 100%;
}
.coustom-row-3 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3px;
    margin-right: -3px;
}
.custom-col-style-3 {
    padding-left: 3px;
    padding-right: 3px;
}
.coustom-row-4 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -9px;
    margin-right: -9px;
}
.coustom-container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}
.coustom-row-5 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}
.custom-col-style-4 {
    padding-left: 9px;
    padding-right: 9px;
}
.custom-col-style-5 {
    padding-left: 10px;
    padding-right: 10px;
}
.custom-col-two-5 {
    flex: 0 0 20%;
    max-width: 20%;
}
.custom-col-three-5 {
    flex: 0 0 20%;
    max-width: 20%;
}
.custom-col-3 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
}
.product-content-5 {
    bottom: 0;
    left: 50%;
    padding: 67px 0;
    position: absolute;
    transform: translateX(-50%);
}
.product-content-5 > h4 {
    color: #383838;
    font-size: 24px;
    font-weight: bold;
    margin: 3px 0;
}
.product-content-5 > h4 a {
    color: #383838;
}
.product-content-5 > h4 a:hover {
    color: #050035;
}
.product-content-5 > span {
    color: #383838;
    display: block;
    font-size: 13px;
    margin-bottom: 14px;
}
.product-content-5 > h5 {
    color: #383838;
    font-size: 24px;
}
.product-content-6 {
    bottom: 0;
    left: 0;
    padding: 14px 29px;
    position: absolute;
}
.product-img-4 img {
    width: 100%;
}
.best-selling-right .product-content-4 > h4 {
    margin: 1px 0 5px;
}
.product-content-4.best-selling-position {
    padding: 29px 30px 21px;
}
.best-selling-product .col-lg-5 {
    flex: 0 0 45.31%;
    max-width: 45.31%;
}
.best-selling-product .col-lg-7 {
    flex: 0 0 54.69%;
    max-width: 54.69%;
}
.product-content-6 > h4 {
    color: #383838;
    font-size: 18px;
    font-weight: bold;
    margin: 3px 0 7px;
}
.product-content-6 > h4 a {
    color: #383838;
}
.product-content-6 > h4 a:hover {
    color: #050035;
}
.product-content-6 > h5 {
    color: #383838;
}
.androit-banner-img > img {
    width: 100%;
}
.androit-banner-img {
    position: relative;
}
.androit-banner-img:hover .phn-img img {
    animation: 700ms ease-in-out 0s normal none 1 running fadeInUp;
}
.androit-banner-content {
    padding: 103px 557px 93px 40px;
    text-align: center;
}
.androit-banner-content > h3 {
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 20px;
}
.product-action-right {
    opacity: 0;
    position: absolute;
    right: -40px;
    top: 0;
    transition: all 0.3s ease 0s;
    z-index: 999;
}
.product-action-right > a {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    color: #333;
    display: block;
    font-size: 25px;
    height: 46px;
    line-height: 50px;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 50px;
}
.product-action-right > a:hover {
    background-color: #333;
    border-bottom: 1px solid #333;
    color: #fff;
}
.product-wrapper {
    overflow: hidden;
}
.product-wrapper:hover .product-action-right {
    opacity: 1;
    right: 0;
}
.product-action-right > a:last-child {
    border-bottom: medium none;
}
.phn-img {
    bottom: -73px;
    position: absolute;
    right: 112px;
}
.androit-banner-content > a {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;

}
.androit-banner-content > a:hover {
    color: #ccc;
}
.banner-price.text-center {
    background-color: #5b5b63;
    border-radius: 0 100% 100%;
    display: inline-block;
    height: 138px;
    left: 0;
    position: absolute;
    top: 0;
    width: 150px;
}
.banner-price-new {
    color: #ffffff;
    display: block;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;
}
.banner-price-old {
    color: #ececec;
    font-size: 20px;
    font-weight: bold;
    text-decoration: line-through;
    line-height: 1;
}
.banner-price-position {
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.product-wrapper-border {
    border: 1px solid #ddd;
    display: flex;
    padding: 61px 20px 47px;
    background-color: #fff;
}
.product-img-5 {
  flex: 0 0 189px;
}
.product-content-7 > h4 {
    color: #383838;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    margin-bottom: 11px;
}
.product-content-7 > h5 {
    color: #383838;
    font-size: 24px;
    line-height: 1;
    margin: 14px 0 15px;
}
.product-action-electro a {
    background-color: transparent;
    border: 1px solid #d4d4d4;
    border-radius: 100%;
    color: #b1b1b1;
    display: inline-block;
    font-size: 18px;
    height: 33px;
    line-height: 35px;
    margin: 0 8px 0 0;
    text-align: center;
    width: 33px;
}
.product-action-electro a:hover {
    background-color: #383838;
    border: 1px solid #383838;
    color: #fff;
}
.product-content-7 {
    margin-left: 30px;
}

/* 4.6 Brand logo style of electronics */

.brand-logo-active2.owl-carousel .owl-item img {
    width: auto;
}
.brand-logo-area-2 {
    position: relative;
}
.brand-logo-area-2::before {
    background-color: #eeeeee;
    content: "";
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 1168px;
}
.brand-logo-area-2::after {
    background-color: #eeeeee;
    content: "";
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 1168px;
}
.section-title-5 > h2 {
    color: #383838;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 3px;
}
.section-title-5 > p {
    color: #383838;
    font-size: 18px;
    letter-spacing: 0.5px;
    margin: 0;
}
.section-title-5 > p span {
    color: #1f51d3;
}

/* 4.7 Newsletter style of electronics */

.newsletter-style-3 {
    margin: 8px 0 0 50px;
}
.subscribe-form-3 form {
    position: relative;
}
.subscribe-form-3 form input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #aaaaaa;
    border-radius: 5px;
    color: #999999;
    font-size: 12px;
    height: 48px;
    line-height: 48px;
    padding-left: 20px;
    padding-right: 55px;
    position: relative;
}
.subscribe-form-3 .mc-form .clear {
    background: rgb(56, 56, 56) url("../../img/icon-img/14.png") no-repeat scroll right 15px center;
    border-radius: 5px;
    bottom: 0;
    display: inline-block;
    padding: 0 6px;
    position: absolute;
    right: 0;
    z-index: 999;
    transition: all .3s ease 0s;
}
.subscribe-form-3 .mc-form .clear:hover {
    background: rgb(0, 0, 0) url("../../img/icon-img/14.png") no-repeat scroll right 15px center;
}
.subscribe-form-3 .clear input {
    border: medium none;
    padding: 0;
    text-indent: -99999px;
    width: 37px;
}

/* 4.8 Footer style of electronics */

.footer-widget-title-3 {
    color: #fafafa;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 35px;
    padding-bottom: 14px;
    position: relative;
}
.footer-widget-title-3::after {
    background-color: #414141;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 70px;
}
.footer-address-electro {
    display: flex;
    margin-bottom: 19px;
}
.footer-info-icon2 {
    width: 68px;
}
.footer-info-icon2 span {
    color: #aaaaaa;
    text-transform: uppercase;
    font-weight: bold;
}
.footer-info-content2 {
    margin-left: 5px;
}
.footer-info-content2 > p {
    color: #aaaaaa;
    line-height: 25px;
    margin: 0;
}
.footer-info-content2 > p a {
    color: #aaaaaa;
}

.footer-info-content2 > p a:hover {
    color: #fff;
}
.footer-widget-content-3 ul li {
    margin-bottom: 22px;
    position: relative;
}
.footer-widget-content-3 ul li:last-child {
    margin-bottom: 0px;
}
.footer-widget-content-3 ul li:last-child {
    margin-bottom: 0px;
}
.footer-widget-content-3 ul li::before {
    border: 1px solid #696969;
    border-radius: 50px;
    content: "";
    height: 7px;
    left: 0;
    position: absolute;
    top: 8px;
    width: 7px;
}
.footer-widget-content-3 ul li a {
    color: #aaaaaa;
    display: block;
    letter-spacing: 1px;
    padding-left: 24px;
}
.footer-services-content {
    margin-left: 30px;
}
.footer-widget-content-3 ul li a:hover {
    color: #fff;
}
.widget-right {
    float: right;
}
.footer-services-wrapper {
    display: flex;
}
.footer-services-icon i {
    color: #eeeeee;
    font-size: 36px;
}
.footer-services-content > h3 {
    color: #aaaaaa;
    font-size: 25px;
    margin-bottom: 9px;
}
.footer-services-content > p {
    color: #aaaaaa;
    margin: 0;
}
.footer-menu li {
    display: inline-block;
    line-height: 1;
    margin-right: 20px;
}
.footer-menu li a {
    color: #7b7b7b;
    font-size: 12px;
    letter-spacing: 1px;
}
.footer-menu li a:hover {
    color: #fff;
}
.footer-menu li:last-child {
    margin-right: 0px;
}
.mrg-5 {
    margin-top: 5px;
}
/*--------- 5. Electronic style 2 ----------*/
/* 5.1 Header style of electronic 2 */

.header-cart-3 {
    margin-top: 14px;
    position: relative;
}
.header-cart-3 > a i {
    color: #070b21;
    font-size: 20px;
    margin-right: 4px;
    position: relative;
    top: 3px;
}
.header-cart-3 > a {
    color: #070b21;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}
.header-cart-3 > a span {
    background-color: #8185a0;
    border-radius: 50px;
    color: #ffffff;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    margin-left: 3px;
    text-align: center;
    width: 20px;
}
.header-cart-3:hover .cart-dropdown {
    opacity: 1;
    top: 100%;
    visibility: visible;
}
.categori-menu-wrapper2 {
    background-color: #070b21;
}
.categori-style-2 {
    width: 20%;
    float: left;
}
.menu-style-4 {
    float: left;
    padding-left: 134px;
    width: 80%;
}
.category-heading-2 > h3 {
    color: #efefef;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 15px 10px 13px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}
.menu-style-4 nav > ul > li {
    display: inline-block;
    margin: 0 64px 0 0;
    position: relative;
}
.menu-style-4 nav > ul > li:last-child {
    margin: 0 0px 0 0;
}
.category-heading-2 {
    position: relative;
}
.category-heading-2 .category-menu-list {
  background-color: #fff;
  border-radius: 10px;
  left: 0;
  padding: 40px 0;
  position: absolute;
  top: 100%;
  width: 300px;
  z-index: 999;
}
.category-heading-2 .category-menu-list.category-list-none {
    display: none;
}
.category-menu-list > ul > li > a {
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 20px;
  padding-bottom: 20px;
}
.category-menu-list > ul > li:last-child > a {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.menu-style-4 nav > ul > li > a {
    color: #f3f3f3;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 48px;
    text-transform: uppercase;
}
.menu-style-4 ul > li > a > i {
    color: #efefef;
    font-size: 30px;
    position: relative;
    top: 8px;
}
.menu-style-4 ul > li > a > span {
    background-color: #fe0000;
    color: #ffffff;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    padding: 4px 4px 3px;
    position: absolute;
    right: 36px;
    text-transform: uppercase;
    top: -10px;
}
.menu-style-4 ul > li > a > span::after {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #fe0000;
    content: "";
    height: 0;
    left: 3px;
    position: absolute;
    top: 16px;
    width: 0;
}

.category-heading-2 .category-menu-list .category-dropdown-style ul li a {
    color: #383838;
}
.category-heading-2 .category-menu-list .category-dropdown-style ul li a:hover {
    color: #070b21;
}

/* 5.2 Slider style of electronic 2 */

.slider-height-4 {
    min-height: 700px;
}
.single-slider-4 {
    -moz-box-align: center;
    align-items: center;
    display: flex;
    position: relative;
}
.slider-content-4 {
    padding-left: 208px;
}
.slider-content-4 h2 {
    color: #ffffff;
    font-size: 60px;
    font-weight: bold;
    line-height: 70px;
    margin: 0;
}
.slider-content-4 h4 {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 2px;
    margin: 6px 0 30px;
}

/* 5.3 Banner style of electronic 2 */

.banner-wrapper-4 img {
    width: 100%;
}
.banner-wrapper-4 {
    position: relative;
}
.banner-content4-style1 {
    left: 0;
    padding: 40px 44px;
    position: absolute;
    top: 0;
}
.banner-wrapper-4 {
    overflow: hidden;
}
.banner-wrapper-4 img {
    transition: all .1s ease 0s;
}
.banner-wrapper-4:hover img {
    transform: scale(1.1);
}
.banner-content4-style1 > h4 {
    color: #ffffff;
    font-size: 24px;
    font-style: italic;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 28px;
    margin: 0;
}
.banner-content4-style2 {
    left: 0;
    padding: 25px 40px;
    position: absolute;
    top: 0;
}
.banner-content4-style2 > h2 {
    color: #ffffff;
    font-size: 50px;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 48px;
    margin: 0;
    text-transform: uppercase;
}
.banner-content4-style2 > h5 {
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 4px;
    text-transform: uppercase;
}
.banner-content4-style2 > h5.p-right {
    display: block;
    margin-left: 65px;
}
.banner-content4-style2 > h3 {
    color: #fff;
    display: block;
    font-size: 24px;
    font-weight: bold;
    margin-top: 25px;
    overflow: hidden;
    text-transform: uppercase;
}
.banner-content4-style3 {
    left: 0;
    padding: 17px 50px;
    position: absolute;
    top: 0;
}
.banner-content4-style3 h1 {
    color: #ffffff;
    font-size: 48px;
    font-weight: 100;
    line-height: 44px;
    text-transform: uppercase;
}
.banner-content4-style3 > h3 {
    color: #fff;
    display: block;
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    overflow: hidden;
    text-transform: uppercase;
}

/* 5.4 Product style of electronic 2 */

.product-content-electro2 {
    bottom: 0;
    left: 0;
    padding: 40px 20px;
    position: absolute;
    right: 0;
}
.product-content-electro2 > h3 {
    color: #1c1e23;
    font-size: 16px;
    margin: 0;
}
.product-content-electro2 > h3 a {
    color: #1c1e23;
}
.product-content-electro2 > h3 a:hover {
    color: #050035;
}
.product-content-electro2 > span {
    color: #b8bfd3;
    display: block;
    letter-spacing: 1px;
    margin: 12px 0 9px;
    text-transform: uppercase;
}
.product-content-electro2 > h5 {
    color: #343e5c;
    font-weight: bold;
    margin: 0;
}
.fexible-content.fexible-content-2 {
    padding: 70px 120px 74px;
}
.hanicraft-action {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
/*------------- 6. Handicraft page -----------*/
/* 6.1 Header style of handicraft */

.wrapper-padding-2 .container-fluid {
    padding: 0 100px;
}
.handicraft-search-cart {
    display: flex;
}
.header-cart-4 > a {
    display: block;
    padding: 55px 0;
    position: relative;
}
.header-cart-4 > a > i {
    color: #2a2b2c;
    font-size: 35px;
}
.header-cart-4 > a span {
    background-color: #2c2c2c;
    border-radius: 50px;
    color: #ffffff;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 25px;
    left: 18px;
    line-height: 25px;
    margin-left: 3px;
    position: absolute;
    text-align: center;
    top: 42px;
    width: 25px;
}
.header-cart-4 {
    margin-left: 50px;
}
.handicraft-search {
    position: relative;
}
.handicraft-search .search-toggle {
    background-color: transparent;
    border: medium none;
    color: #fff;
    line-height: 24px;
    margin: 62px 0 40px 13px;
    padding: 0;
    position: relative;
    z-index: 99999;
    cursor: pointer;
}
.handicraft-search .search-toggle i {
    color: #2a2b2c;
    display: block;
    font-size: 35px;
    line-height: 24px;
}
.handicraft-search .handicraft-content {
    background-color: transparent;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s ease 0s;
    visibility: hidden;
    width: 340px;
    z-index: -9999;
}
.handicraft-search .handicraft-content form {
    overflow: hidden;
    position: relative;
}
.handicraft-search .handicraft-content label {
    border-right: 1px solid #ddd;
    left: 0;
    padding: 0 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #2a2b2c;
    font-size: 18px;
    margin: 2px 0 0;
}
.handicraft-search .handicraft-content form input {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #333;
    line-height: 30px;
    padding-left: 20px;
    width: 100%;
}
.handicraft-search .handicraft-content.open {
    opacity: 1;
    right: 100%;
    visibility: visible;
    z-index: 9999;
}
.handicraft-search .search-toggle .s-close {
    opacity: 0;
}
.handicraft-search .search-toggle i.s-close {
    left: 0;
    position: absolute;
    top: 0;
}
.handicraft-search .search-toggle.open .s-close {
    opacity: 1;
}
.handicraft-search .search-toggle.open .s-open {
    opacity: 0;
}
.header-cart-4 {
    position: relative;
}
.header-cart-4:hover .cart-dropdown {
    opacity: 1;
    top: 100%;
    visibility: visible;
}
.menu-style-2.handicraft-menu nav > ul > li {
    margin: 0 25px;
}
// .menu-style-2.handicraft-menu nav > ul > li > a {
//     padding: 50px 0;
// }

/* 6.2 Slider style of handicraft */
.slider-height-5 {
    min-height: 800px;
}
.slider-content-5 h2 {
    color: #383838;
    font-size: 60px;
    line-height: 80px;
    text-transform: uppercase;
    margin: 0;
}
.slider-content-5 p {
    color: #999999;
    font-size: 18px;
    line-height: 28px;
    margin: 2px 0 34px;
    width: 53%;
}
.handicraft-slider-btn {
    border: 1px solid #999999;
    border-radius: 50px;
    color: #666666;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    padding: 17px 39px 15px;
    text-transform: uppercase;
    z-index: 99;
}
.btn-hover.handicraft-slider-btn::before {
    border-radius: 50px;
}
.handicraft-slider-btn:hover {
    border: 1px solid #000;
    color: #fff;
}
/* 6.3 Banner style of handicraft */
.offer-img {
    position: relative;
    text-align: center;
}
.offer-img::before {
    background-color: #f6f7fa;
    content: "";
    height: 500px;
    left: 154px;
    position: absolute;
    top: -61px;
    width: 500px;
    z-index: -1;
}
.offer-text {
    left: 0;
    position: absolute;
    top: 20px;
    writing-mode: vertical-rl;
}
.offer-text > h3 {
    color: #999999;
    font-size: 16px;
    letter-spacing: 2px;
    margin: 0;
    text-transform: uppercase;
}
.offer-details-wrapper > h2 {
    border-bottom: 2px solid #383838;
    color: #383838;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 28px;
    margin: 0;
}
.offer-details-wrapper > p {
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    margin: 35px 0 16px;
    width: 73%;
}
.offer-price-btn {
    display: flex;
}
.offer-old-price {
    color: #666666;
    font-size: 18px;
    margin-right: 12px;
    text-decoration: line-through;
}
.offer-new-price {
    color: #383838;
    font-size: 30px;
}
.offer-btn > a {
    color: #666666;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
    text-transform: uppercase;
}
.offer-btn > a:hover {
    color: #333;
}
.offer-price {
    margin-right: 48px;
}
.offer-details-wrapper {
    margin-top: 107px;
}

/* 6.4 Product style of handicraft */

.product-img-hanicraft {
    position: relative;
}
.hanicraft-action-position {
    left: 0;
    position: absolute;
    text-align: center;
    top: 60%;
    transform: translateY(-50%);
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    transition: all 0.6s ease 0s;
}
.product-wrapper:hover .hanicraft-action-position,
.trandy-product-wrapper:hover .hanicraft-action-position {
    opacity: 1;
    top: 50%;
    visibility: visible;
}
.hanicraft-action {
    background-color: #fff;
    border-radius: 50px;
    display: inline-block;
    left: 50%;
    line-height: 1;
    padding: 13px 26px 10px;
}
.hanicraft-action a {
    color: #383838;
    font-size: 24px;
    margin: 0 13px;
    position: relative;
}
.hanicraft-action a:hover {
    color: #000;
}
.product-img-hanicraft img {
    width: 100%;
}
.hanicraft-action a::before {
    background-color: #eee;
    content: "";
    height: 50px;
    margin: 0 auto;
    position: absolute;
    right: -10px;
    top: -8px;
    width: 1px;
}
.hanicraft-action a:last-child::before {
    content: none;
}
.product-content-hanicraft > h5 {
    color: #666666;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}
.product-content-hanicraft > h4 {
    color: #383838;
    font-size: 18px;
    margin: 6px 0 5px;
}
.product-content-hanicraft > h4 a {
    color: #383838;
}
.product-content-hanicraft > h4 a:hover {
    color: #000;
}
.product-content-hanicraft > span {
    color: #666666;
}
.product-img-hanicraft {
    margin-bottom: 29px;
}
.product-img-hanicraft span {
    background-color: #383838;
    color: #fefefe;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    padding: 6px 10px 5px;
    position: absolute;
    text-transform: uppercase;
    top: 20px;
}
.product-img-hanicraft .new {
    left: 20px;
}
.product-img-hanicraft .sell {
    right: 20px;
}
/* 6.5 Banner style 2 of handicraft */
.handicraft-banner-wrapper {
    margin: 0 30px 0 70px;
    position: relative;
}
.handicraft-banner-position {
    padding: 20px 50px;
    position: absolute;
    right: 0;
    text-align: right;
    top: 50%;
    transform: translateY(-50%);
}
.handicraft-banner-position2 {
    padding: 20px 50px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.handicraft-banner-wrapper > img {
    width: 100%;
}
.handicraft-banner-content > h3 {
    color: #383838;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 34px;
    margin-bottom: 20px;
}
.banner-btn {
    border: 1px solid #999999;
    border-radius: 50px;
    color: #666666;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    padding: 13px 20px 11px;
    text-transform: uppercase;
}
.banner-btn:hover {
    color: #fff;
    border: 1px solid #333;
}
.btn-hover.banner-btn::before {
    border-radius: 50px;
}

/* 6.6 Testimonial style of handicraft */

.owl-carousel .owl-item .single-testimonial-2 img {
    display: block;
    margin: 0 auto;
    width: auto;
}
.single-testimonial-2 > p {
    color: #666666;
    font-size: 20px;
    font-style: italic;
    letter-spacing: 0.5px;
    line-height: 36px;
    margin: 35px auto 52px;
    width: 80%;
}
.single-testimonial-2 > h4 {
    color: #666666;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    margin: 16px 0 7px;
}
.single-testimonial-2 > span {
    color: #666666;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

/* 6.7 Newsletter style of handicraft */

.newsletter-content > h2 {
    color: #666666;
    font-size: 30px;
    margin-bottom: 5px;
}
.newsletter-content > p {
    color: #383838;
    margin: 0;
    width: 85%;
}
.subscribe-form-4 form input {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    border-radius: 50px;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
    color: #999999;
    font-size: 12px;
    height: 56px;
    line-height: 56px;
    padding: 0 65px 0 40px;
    width: 500px;
    position: relative;
}
.subscribe-form-4 {
  margin-top: 16px;
}
.subscribe-form-4 form {
    position: relative;
}
.subscribe-form-4 .mc-form .clear {
    background: rgba(0, 0, 0, 0) url("../../img/icon-img/24.png") no-repeat scroll right 0 center;
    bottom: 0;
    display: inline-block;
    position: absolute;
    right: 22px;
    z-index: 9999;
}
.subscribe-form-4 .clear input {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: transparent;
    border-color: currentcolor currentcolor currentcolor #dddddd;
    border-image: none;
    border-radius: 0;
    border-style: none none none solid;
    border-width: medium medium medium 1px;
    box-shadow: none;
    padding: 0 0 0 22px;
    text-indent: -99999px;
    width: 37px;
}

/* 6.8 Footer style of handicraft */

.footer-about > p {
    color: #666666;
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 33px 0 22px;
    padding-right: 40px;
}
.footer-about li {
    display: inline-block;
    margin-right: 15px;
    transition: all .3s ease 0s;
}
.footer-about li:last-child {
    margin-right: 0px;
}
.footer-about li a {
    color: #666666;
    font-size: 16px;
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background-color: #f1f1f1;
    line-height: 35px;
    text-align: center;
}
.footer-about li a:hover {
    color: #f1f1f1;
    background-color: #666;
}
.footer-widget-title-4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 30px;
    padding-bottom: 12px;
    position: relative;
    text-transform: uppercase;
}
.footer-widget-title-4::before {
    background-color: #000000;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px;
}
.footer-widget-content-4 li {
    margin-bottom: 7px;
    position: relative;
}
.footer-widget-content-4 li:last-child {
    margin-bottom: 0px;
}
.footer-widget-content-4 li a {
    color: #666666;
    display: block;
    font-size: 16px;
    transition: all 0.3s ease 0s;
}
.footer-widget-content-4 li::before {
    background: url("../../img/icon-img/25.png") no-repeat scroll left 0px center;
    bottom: 0;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    transition-duration: 0.3s;
    width: 100%;
    z-index: -9;
    opacity: 0;
}
.footer-widget-content-4 li:hover::before {
    opacity: 1;
}
.footer-widget-content-4 li:hover a {
    padding-left: 20px;
    color: #333;
}
.footer-banner {
    position: relative;
}
.footer-banner-content {
    left: 0;
    padding: 20px 35px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.footer-banner-content > h3 {
    color: #383838;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 28px;
    margin-bottom: 8px;
    text-transform: uppercase;
}
.footer-banner-content > a {
    border-bottom: 2px solid #989898;
    color: #383838;
    display: inline-block;
    font-size: 14px;
    line-height: 1;
}
.footer-banner-content > a:hover {
    border-bottom: 2px solid #333;
    color: #333;
}
.footer-banner > img {
    width: 100%;
}
.copyright-handicraft > p {
    color: #333333;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 18px;
    margin: 0;
}

/*--------- 7. Furniture page ---------*/
/* 7.1 Header style of furniture */

.icon-cart-furniture i {
    color: #00245b;
    font-size: 35px;
}
a.icon-cart-furniture {
    display: block;
    padding: 32px 0 32px 10px;
    position: relative;
}
a.icon-cart-furniture span {
    border-radius: 50px;
    color: #ffffff;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 25px;
    line-height: 25px;
    margin-left: 3px;
    position: absolute;
    right: -26px;
    text-align: center;
    top: 38px;
    width: 25px;
}
a.icon-cart-furniture span.green {
    background-color: #333;
}
.furniture-logo > a {
    display: block;
}
.menu-style-2.furniture-menu nav > ul > li {
    margin: 0 30px;
}
.menu-style-2.furniture-menu nav > ul > li > a {
    padding: 38px 0;
}
.furniture-bottom-wrapper {
    display: flex;
    justify-content: space-between;
}
.furniture-login ul li {
    color: #999999;
    display: inline-block;
    font-weight: 400;
    margin-right: 15px;
    font-size: 14px;
    position: relative;
}

.furniture-login ul li a {
    text-transform: uppercase;
    font-weight: bold;
    color: #999999;
}
.furniture-login ul li::before {
    background-color: #999999;
    content: "";
    height: 14px;
    margin: 0 auto;
    position: absolute;
    right: -10px;
    top: 3px;
    width: 1px;
}
.furniture-login ul li:last-child::before {
    content: none;
}
.furniture-search form input {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: transparent none repeat scroll 0 0;
    border-color: currentcolor #e5e5e5;
    border-image: none;
    border-style: none solid;
    border-width: medium 1px;
    box-shadow: none;
    color: #999999;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    padding-left: 27px;
    padding-right: 60px;
    width: 630px;
}
.subscribe-form-4 form input::-moz-placeholder,
.furniture-search form input::-moz-placeholder {
    color: #999;
    opacity: 1;
}
.subscribe-form-4 form input::-webkit-placeholder,
.furniture-search form input::-webkit-placeholder {
    color: #999;
    opacity: 1;
}
.furniture-search form {
    position: relative;
}
.furniture-search form button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: #999999;
    font-size: 20px;
    padding: 0;
    position: absolute;
    right: 30px;
    top: 13px;
    cursor: pointer;
}
.furniture-search form button:hover {
    color: #050035;
}
.furniture-wishlist li {
    display: inline-block;
    margin-left: 39px;
}
.furniture-wishlist li a i {
    color: #00245b;
    font-size: 18px;
    margin-right: 10px;
    position: relative;
    top: 3px;
}
.furniture-wishlist li a {
    color: #383838;
    letter-spacing: .5px;
}
.furniture-wishlist li:first-child {
    margin-left: 0px;
}
.furniture-login,
.furniture-wishlist {
    align-items: center;
    display: flex;
}
.furniture-login ul li a:hover,
.furniture-wishlist li a:hover {
    color: #00245b;
}
.slider-height-6 {
    min-height: 790px;
}
.furniture-content h2 {
    font-size: 72px;
    font-weight: bold;
    line-height: 90px;
    margin: 0;
}
.furniture-content p {
    color: #383838;
    font-size: 16px;
    line-height: 26px;
    margin: 10px 0 28px;
    width: 78%;
}
.furniture-slider-btn {
    border: 1px solid #333;
    border-radius: 50px;
    color: #383838;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    padding: 13px 30px 12px;
    text-transform: uppercase;
}
.furniture-slider-btn:hover {
    border: 1px solid #000;
    color: #fff;
}
.furniture-slider-btn::before {
    border-radius: 50px;
}
/* 7.2 Product style of furniture */

.wrapper-padding-3 .container-fluid {
    padding: 0 275px;
}
.section-title-6 > h2 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-bottom: 29px;
}
.section-title-6 > p {
    color: #666666;
    letter-spacing: 0.5px;
    margin: 0 auto;
    width: 46%;
}
.funiture-product-content {
    margin-top: 26px;
}
.funiture-product-content > h4 {
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
}
.funiture-product-content > h4 a {
    color: #383838;
}
.funiture-product-content > h4 a:hover {
    color: #050035;
}
.funiture-product-content > span {
    color: #383838;
    letter-spacing: 0.5px;
}
.popular-product-active.owl-carousel .owl-nav div,
.popular-product-active-2.owl-carousel .owl-nav div {
    left: -75px;
    position: absolute;
    top: 39%;
}
.popular-product-active.owl-carousel .owl-nav div.owl-next,
.popular-product-active-2.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: -76px;
}
.discount-img > img {
    width: 100%;
}
.discount-img {
    position: relative;
}
.discount-img::before {
    background-color: #eafaff;
    content: "";
    height: 500px;
    left: 0px;
    position: absolute;
    top: -70px;
    width: 500px;
    z-index: -1;
}
.blog-img a img {
    max-width: 100%;
}
.discount-details-wrapper > h5 {
    border-bottom: 2px solid #9b9b9b;
    display: inline-block;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding-bottom: 1px;
    text-transform: uppercase;
}
.discount-details-wrapper > p {
    color: #999999;
    letter-spacing: 0.5px;
    margin: 17px 0 21px;
    width: 65%;
}
.discount-details-wrapper > h2 {
    color: #383838;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 46px;
    margin: 0;
}
.discount-details-wrapper > p.discount-peragraph {
    color: #666666;
    margin: 32px 0 41px;
    width: 83%;
}
.discount-btn {
    border: 1px solid #666666;
    border-radius: 50px;
    color: #666666;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    padding: 12px 30px;
    text-transform: uppercase;
}
.discount-btn:hover {
    border: 1px solid #000;
    color: #fff;
}
.btn-hover.discount-btn::before {
    border-radius: 50px;
}
.discount-details-wrapper {
    padding-left: 30px;
    padding-top: 97px;
}
.discount-wrapper {
    position: relative;
}
.discount-wrapper > img {
    width: 100%;
}
.discount-content {
    padding: 20px 250px;
    position: absolute;
    right: 0;
    text-align: right;
    top: 50%;
    transform: translateY(-50%);
}
.discount-content > h2 {
    color: #998e7e;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 58px;
    margin-bottom: 17px;
}
.discount-content > a {
    border-bottom: 2px solid #cccccc;
    color: #999999;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 17px;
    text-transform: uppercase;
}
.discount-content > a:hover {
    border-bottom: 2px solid #666;
    color: #666;
}
.product-img a img {
    width: 100%;
}
.product-rating-5 {
    margin-top: 10px;
}
.product-rating-5 i {
    color: #b7b7b7;
    font-size: 18px;
    margin: 3px;
}
.product-rating-5 i.black {
    color: #111111;
}
.section-title-7 > h2 {
    color: #383838;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.section-title-7 > p {
    color: #666666;
    letter-spacing: 0.5px;
    margin: 0 auto 48px;
    width: 33%;
}
.view-all-product {
    position: relative;
}
.view-all-product > a {
    border: 1px solid #383838;
    border-radius: 50px;
    color: #383838;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    padding: 14px 30px 12px;
    text-transform: uppercase;
}
.view-all-product > a:hover {
    color: #fff;
    background-color: #383838;
}
.view-all-product > a::before {
    background-color: #f1f1f1;
    content: "";
    height: 1px;
    left: 360px;
    position: absolute;
    top: 19px;
    width: 438px;
}
.view-all-product > a::after {
    background-color: #f1f1f1;
    content: "";
    height: 1px;
    right: 360px;
    position: absolute;
    top: 19px;
    width: 438px;
}
.wrapper-padding-4 .container-fluid {
    padding: 0 305px;
}

/* 7.3 Services style of furniture */

.services-wrapper {
    display: flex;
    justify-content: space-between;
}
.single-services {
    display: flex;
}
.services-content > h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 14px;
}
.services-content > p {
    color: #666666;
    margin: 0;
    width: 77%;
}
.services-content {
    padding: 10px 0 0 26px;
}
.wrapper-padding-5 .container-fluid {
    padding: 0 305px 0 150px;
}

/* 7.4 Footer style of furniture */

.widget-wrapper {
    display: flex;
    justify-content: space-between;
}
.footer-about-2 > p {
    color: #666666;
    margin: 40px 0 0;
}
.footer-widget h3.footer-widget-title-5 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 28px;
}
.footer-address-furniture {
    display: flex;
}
.sidebar-search form > button:hover {
    background-color: #050035;
    color: #fff;
}
.sidebar-categories li a:hover,
.product-size li a:hover {
    color: #050035;
}
.footer-info-icon3 {
    width: 80px;
}
.footer-info-icon3 > span {
    color: #666666;
    font-size: 16px;
    font-weight: bold;
}
.footer-info-content3 > p {
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
}
.footer-info-content3 > p a {
    color: #666;
}
.footer-info-content3 > p a:hover {
    color: #333;
}
.footer-address-furniture {
    margin-bottom: 16px;
}
.footer-address-furniture:last-child {
    margin-bottom: 0px;
}
.footer-newsletter-2 > p {
    color: #383838;
    margin: 0;
}
.subscribe-form-5 form input {
    background: #f6f6f6 none repeat scroll 0 0;
    border: medium none;
    border-radius: 10px;
    color: #383838;
    font-size: 12px;
    letter-spacing: 0.5px;
    padding-left: 30px;
    width: 320px;
    padding-right: 55px;
}
.subscribe-form-5 form {
    position: relative;
}
.subscribe-form-5 .mc-form .clear {
    background: rgba(0, 0, 0, 0) url("../../img/icon-img/24.png") no-repeat scroll right 0 center;
    bottom: 0;
    display: inline-block;
    position: absolute;
    right: 20px;
    z-index: 9999;
}
.subscribe-form-5 .clear input {
    background-color: transparent;
    border: medium none;
    padding: 0;
    text-indent: -99999px;
    width: 37px;
}
.subscribe-form-5 {
    margin-top: 19px;
}
.copyright-furniture > p {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.5px;
    margin: 0;
}
.copyright-furniture > p a {
    color: #333;
}
.copyright-furniture > p a:hover {
    text-decoration: underline;
}
/*-------- 8. Smart watch page ----------*/
/* 8.1 Header style of smart watch */
.header-sticky {
    left: 0;
    padding: 70px 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.5s ease 0s;
    z-index: 99999;
}
.header-sticky.stick {
    background: #1834a6;
    padding: 15px 0;
    position: fixed;
    top: 0;
}
.single-overview > h4 {
  font-size: 24px;
  letter-spacing: 0.2px;
  position: relative;
}
.single-overview > h4::before {
  background: #383838 none repeat scroll 0 0;
  bottom: -3px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.3s ease-in-out 0s;
  width: 0;
}
.single-overview:hover *::before {
  width: 50px;
}
.buy-now-btn {
    margin-left: 20px;
    margin-top: 7px;
}
.menu-style-toggle nav {
    float: left;
    transition: all 0.3s ease 0s;
}
.menu-style-toggle nav {
    margin-right: -30px;
    opacity: 0;
    visibility: hidden;
}
.menu-style-toggle nav.menu-open {
    margin-right: 0;
    opacity: 1;
    visibility: visible;
}
.logo-menu-wrapper {
    display: flex;
    justify-content: space-between;
}
.hamburger-menu {
    align-items: center;
    display: flex;
}
.hamburger-menu ul > li {
    display: inline-block;
    margin-left: 35px;
}
.hamburger-menu ul > li a {
    color: #fff;
    text-transform: uppercase;
}
.hamburger-wrapper {
    display: flex;
}
.hamburger-inner {
    border-radius: 0px;
    height: 2px;
    width: 25px;
    float: right;
}
.hamburger-inner::after {
    border-radius: 0px;
    height: 2px;
    width: 30px;
    right: 0;
}
.hamburger-inner::before {
    border-radius: 0px;
    height: 2px;
    width: 20px;
    right: 0;
}
.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    width: 30px;
}
.buy-now-btn > a {
    background-color: #fff;
    border-radius: 50px;
    color: #1834a6;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1;
    padding: 14px 30px 12px;
    text-transform: uppercase;
}
.table,
.table-cell {
    display: table;
    height: 100%;
}
.table-cell {
    display: table-cell;
    vertical-align: middle;
}

/* 8.2 Slider style of smart watch */

.slider-text {
    margin-top: 103px;
}
.wacth-img {
    float: right;
    padding: 0 0px 0 125px;
    width: 100%;
}
.wacth-img img {
    max-width: 410px;
}
.slider-text > h2 {
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    line-height: 70px;
    margin-bottom: 32px;
    text-transform: uppercase;
}
.explore-btn {
    background-color: #f4f4f4;
    border-radius: 50px;
    color: #1834a6;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1;
    padding: 9px 30px 15px;
    text-transform: uppercase;
}
.explore-btn i {
    font-size: 20px;
    margin-left: 10px;
    position: relative;
    top: 3px;
}
.explore-btn:hover,
.buy-now-btn > a:hover {
    box-shadow: 0 10px 35px -7px rgba(255, 255, 255, 0.5);
}

/* 8.3 About style of smart watch */

.watch-about-content > h2 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.8px;
    margin: 28px 0 0;
}
.watch-about-content > p {
    color: #666666;
    letter-spacing: 0.2px;
    margin: 25px auto 46px;
    width: 55%;
}
.watch-about-content > a {
    background-color: #1834a6;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    padding: 14px 40px 12px;
    text-transform: uppercase;
}
.watch-about-content > a:hover {
    box-shadow: 0 10px 35px -7px rgba(24, 54, 166, 0.9);
    transform: translateY(-3px);
}
.section-title-8 > h2 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.2px;
    margin-bottom: 19px;
}
.section-title-8 > p {
    color: #666666;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 26px;
}
.section-title-8.peragraph-width > p {
    width: 79%;
}

/* 8.4 Overview style of smart watch */

.single-power-feathers > h4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2px;
    margin: 20px 0 14px;
}
.single-overview > h4 {
    font-size: 24px;
    letter-spacing: .2px;
    position: relative;
}
.single-overview > h4::before {
    background: #383838 none repeat scroll 0 0;
    bottom: -3px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in-out 0s;
    width: 0;
}
.single-overview:hover::before {
    width: 50px;
}
.single-overview > p,
.single-power-feathers > p {
    color: #666666;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 26px;
    margin: 0;
}
.single-overview {
    margin-bottom: 35px;
}
.section-title-8.peragraph-width-2 > p {
    width: 46%;
}
.single-power-feathers > h4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2px;
}
.single-power-feathers:hover img {
    animation: 500ms ease-in-out 0s normal none 1 running tada;
}

.power-feathers-area {
    background: rgba(0, 0, 0, 0) url("../../img/banner/5.png") no-repeat scroll 95.5% 46% / 25% auto;
}
.smart-watch-product-wrapper {
    position: relative;
}
.smart-watch-content {
    border-top: 1px solid #eaebec;
    bottom: 0;
    left: 0;
    padding: 23px 40px 18px;
    position: absolute;
    width: 100%;
}
.smart-watch-content > h4 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 7px;
    text-transform: uppercase;
}
.smart-watch-content > h4 a {
    color: #383838;
}
.smart-watch-content > a {
    color: #999999;
    font-weight: bold;
}
.smart-watch-content > a:hover,
.smart-watch-content > h4 a:hover {
    color: #1834a6;
}
.smart-watch-product-wrapper a img {
    width: 100%;
}
.smart-watch-product-wrapper {
    margin: 30px 0 40px;
    transition: all .3s ease 0s;
}
.smart-watch-product-wrapper:hover {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
}
.section-title-8.peragraph-width-3 > p {
    margin: 0 auto;
    width: 56%;
}

/* 8.5 Video style of smart watch */

.watch-video-content > h2 {
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 55px;
    text-transform: uppercase;
}
.watch-video-content > p {
    color: #383838;
    font-size: 16px;
    margin: 22px 0 50px;
    width: 77%;
}
.video-btn > a {
    background-color: #1834a6;
    border-radius: 50px;
    color: #fffefe;
    display: inline-block;
    font-size: 20px;
    height: 40px;
    line-height: 45px;
    position: relative;
    text-align: center;
    width: 40px;
    transition: all .3s ease 0;
    z-index: 99;
}
.video-btn > a::before {
    animation: 1.5s linear 0s normal none infinite running buttonRing;
    border: 2px solid #383838;
    border-radius: 50em;
    content: "";
    display: inline-block;
    height: 50px;
    left: -5px;
    opacity: 0.6;
    position: absolute;
    top: -5px;
    transform: scale3d(1, 1, 1);
    transition: all 0.3s ease 0s;
    width: 50px;
    z-index: 9;
}
@keyframes buttonRing {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        -khtml-transform: scale3d(1, 1, 1);
        -moz-transform: scale3d(1, 1, 1);
        -ms-transform: scale3d(1, 1, 1);
        -o-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
        opacity: 0.6
    }
    85% {
        opacity: 0
    }
    100% {
        -webkit-transform: scale3d(1.42, 1.42, 1.42);
        -khtml-transform: scale3d(1.42, 1.42, 1.42);
        -moz-transform: scale3d(1.42, 1.42, 1.42);
        -ms-transform: scale3d(1.42, 1.42, 1.42);
        -o-transform: scale3d(1.42, 1.42, 1.42);
        transform: scale3d(1.42, 1.42, 1.42);
        opacity: 0
    }
}

/* 8.6 Feature style of smart watch */

.feature-list-icon,
.feature-list-icon2 {
    background-color: #1834a6;
    border-radius: 50px;
    display: inline-block;
    height: 100px;
    line-height: 100px;
    text-align: center;
    width: 100px;
    transition: all .3s ease 0s;
}
.single-features-list,
.sfl-lsft {
    display: block;
    margin-bottom: 245px;
    margin-top: -50px;
    overflow: hidden;
}
.feature-list .single-features-list:first-child,
.feature-list .single-features-list:last-child {
    margin-right: -220px;
    padding-left: 430px;
    position: relative;
    z-index: 99;
}
.single-features-list {
    padding-left: 267px;
}
.feature-list-icon {
    display: inline-block;
    float: right;
}
.feature-list-icon2 {
    display: inline-block;
    float: left;
}
.feature-list .sfl-lsft:first-child,
.feature-list .sfl-lsft:last-child {
    margin-left: -220px;
    position: relative;
    z-index: 99;
    padding-right: 430px;
}
.single-features-list2 {
    padding-right: 280px;
}
.feature-img {
    position: relative;
}
.feature-img > img {
    max-width: 512px;
}
.feature-img::before {
    border: 3px dashed #e7eaf6;
    border-radius: 100%;
    content: "";
    display: inline-block;
    height: 500px;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 52px;
    transform: translateX(-50%);
    width: 500px;
}
.feature-list-text > h3,
.feature-list-text2 > h3 {
    color: #1834a6;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 8px;
    text-transform: uppercase;
}
.feature-list-text > h3 span,
.feature-list-text2 > h3 span {
    font-weight: bold;
}
.feature-list-text > p {
    color: #383838;
    float: right;
    margin: 0;
}
.feature-list-text {
    display: block;
    margin-right: 122px;
    margin-top: -4px;
    overflow: hidden;
}
.feature-list-text2 {
    display: block;
    margin-left: 122px;
    overflow: hidden;
    margin-top: -4px;
}
.single-features-list.middle-features-list {
    margin-bottom: 255px;
    margin-right: -48px;
}
.single-features-list2.middle-features-list2 {
    margin-bottom: 255px;
    margin-left: -48px;
}
.single-features-list:hover .feature-list-icon,
.single-features-list2:hover .feature-list-icon2 {
    background-color: #333;
}
.single-features-list.mrn-nn,
.single-features-list2.mrn-nn {
    margin-bottom: 0;
}

/* 8.7 Feadback style of smart watch */

.single-feadback-wrapper {
    background-color: #f7f8f9;
    border-radius: 5px;
    padding: 30px 30px 26px;
}
.feadback-img-title {
    display: flex;
}
.feadback-title > h4 {
    color: #383838;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2px;
}
.feadback-title > span {
    color: #1834a6;
    font-size: 12px;
}
.feadback-title {
    margin: 15px 0 0 24px;
}
.feadback-img-title {
    margin-bottom: 24px;
}
.single-feadback-wrapper > p {
    color: #666666;
    font-size: 12px;
    line-height: 22px;
    margin: 0;
}

/* 8.8 Subscribe style of smart watch */

.subscribe-form-6 form input {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: transparent none repeat scroll 0 0;
    border-color: currentcolor currentcolor #4a5ca4;
    border-image: none;
    border-radius: 0;
    border-style: none none solid;
    border-width: medium medium 2px;
    color: #5f6b9f;
    font-size: 12px;
    font-weight: bold;
    height: 35px;
    line-height: 35px !important;
    margin-left: 75px;
    padding: 0;
    width: 385px;
    margin-top: 5px;
}
.subscribe-form-6 .mc-form {
    background: #1834a6 none repeat scroll 0 0;
    border-radius: 50px;
    padding: 22px 0 34px;
    position: relative;
}
.subscribe-form-6 form {
    position: relative;
}
.subscribe-form-6 .mc-form .clear {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
}
.subscribe-form-6 .clear input.button {
    background-color: #f1f3f9;
    border: medium none;
    border-radius: 50px;
    color: #1834a6;
    font-size: 16px;
    font-weight: bold;
    height: inherit;
    letter-spacing: 0.5px;
    margin: 0;
    padding: 14px 40px 12px;
    text-transform: uppercase;
    width: inherit;
    transition: all .3s ease 0s;
}
.subscribe-form-6 .clear input.button:hover {
    background-color: #383838;
    color: #fff;
}
.subscribe-form-6 form input::-moz-placeholder {
    color: #ddd;
    opacity: 1;
}
.subscribe-form-6 form input::-webkit-input-placeholder {
    color: #ddd;
    opacity: 1;
}
.newsletter-style-6 {
    margin: 0 70px;
}
.subscribe-form-6 .mc-form::before {
  color: #969fc1;
  content: "";
  display: block;
  font-family: "themify";
  font-size: 24px;
  left: 31px;
  position: absolute;
  text-align: center;
  top: 30px;
  z-index: 99;
}

/*---------- 9. Sports page -----------*/
/* 9.1 Header style of sports */
.menu-style-2.sports-menu nav > ul > li {
    margin: 0 30px;
}
.menu-style-2.sports-menu nav > ul > li > a {
    padding: 38px 0;
    color: #e7e7e7;
}
.menu-style-2.sports-menu nav > ul > li > a:hover {
    color: #ccc;
}
.icon-cart-furniture.white-cart i {
    color: #e7e7e7;
}
a.icon-cart-furniture.white-cart span {
    color: #333;
    background-color: #e7e7e7;
}
.logo-2.sports-logo {
    padding: 35px 0 20px;
}

/* 9.2 Slider style of sports */

.sports-slider-content-middele {
    opacity: 1;
    position: absolute;
    left: 37.3%;
    transform: translateY(110%);
    transition: all .7s ease-in-out 0s;
    width: 455px;
}
.slider-active .owl-item.active .sports-slider-content-middele {
    opacity: 1;
    transform: translateY(10%);
}
.slider-height-7 {
    min-height: 800px;
}
.sports-slider-slider {
    position: relative;
}
.sports-slider-content-left {
    position: absolute;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-113.5%);
    transition: all .7s ease-in-out 0s;
}
.slider-active .owl-item.active .sports-slider-content-left {
    opacity: 1;
    transform: translateY(-50%) translateX(113.5%);
}
.sports-slider-content-right {
    opacity: 0;
    position: absolute;
    top: 44%;
    transform: translateX(500%) translateY(-50%);
    transition: all 0.7s ease-in-out 0s;
    z-index: 9999;
}
.slider-active .owl-item.active .sports-slider-content-right {
    opacity: 1;
    transform: translateX(249%);
}
.sports-slider-content-middele.sports-second-img {
    left: 34.3%;
    width: 475px;
}
.slider-active.owl-carousel .owl-item .sports-slider-content-left > img {
    width: 422px;
}
.slider-active.owl-carousel .owl-item .sports-slider-content-right > img {
    width: 387px;
}
.slider-active .owl-item.active .sports-slider-content-middele.sports-second-img {
    transform: translateY(22%);
}

/* 9.3 Discount style of sports */

.discount-wrapper-2 {
    position: relative;
}
.discount-details-wrapper-2 {
    padding: 20px 200px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.discount-wrapper-2 > img {
    width: 100%;
}
.discount-details-wrapper-2 > h2 {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
}
.discount-details-wrapper-2 > p {
    color: #383838;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 26px;
    margin: 5px 0 19px;
    text-transform: uppercase;
}
.discount-btn-2 {
    background-color: #d9cfb0;
    border-radius: 5px;
    color: #383838;
    display: inline-block;
    font-weight: bold;
    padding: 12px 30px 11px;
    text-transform: uppercase;
    z-index: 1;
    overflow: hidden;
}
.discount-btn-2:hover {
    color: #fff;
}
.btn-hover.discount-btn-2::before {
    border-radius: 5px;
}
.section-title-9 > h2 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.4px;
    margin-bottom: 21px;
    text-transform: uppercase;
}
.section-title-9 > p {
    color: #666666;
    letter-spacing: 0.5px;
    margin: 0 auto;
    width: 54%;
}

/* 9.4 Banner style of sports */

.catch-wrapper {
    position: relative;
}
.catch-content {
    bottom: 0;
    left: 0;
    padding: 20px 185px 52px;
    position: absolute;
    width: 100%;
}
.catch-content > h5,
.catch-text-right > h5 {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 6px;
    text-transform: uppercase;
    margin: 0;
}
.catch-content > h5 span {
    font-weight: bold;
}
.catch-text-right > h5 {
    float: right;
}
.catch-text-right {
    display: block;
    overflow: hidden;
}
.catch-content > h2 {
    color: #ffffff;
    font-size: 120px;
    font-weight: bold;
    letter-spacing: 70px;
    line-height: 1;
    margin: 23px 0 12px;
}
.catch-wrapper > img {
    width: 100%;
}
.catch-btn {
    margin-top: 45px;
    text-align: center;
}
.catch-btn .discount-btn-2 {
    background-color: #fff;
}
.catch-btn .discount-btn-2:hover {
    color: #fff;
}
.trandy-product-content > h4 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
}
.trandy-rating-5 i {
    color: #fac62a;
    font-size: 18px;
    margin: 0 1px;
}
.trandy-product-content > span {
    color: #666666;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
}
.trandy-rating-5 {
    margin: 11px 0 10px;
}
.trandy-product-content {
    margin-top: 24px;
}
/*--------- 10. Food page -----------*/
/* 10.1 Header style of food */
a.icon-cart-furniture.food-cart {
    padding: 54px 0 52px 10px;
}
a.icon-cart-furniture.food-cart span {
    top: 60px;
}

/* 10.2 Slider style of food */

.owl-carousel .owl-item .food-slider img {
    display: inline-block;
    width: inherit;
}
.food-slider {
    -moz-box-align: center;
    align-items: center;
    display: flex;
    position: relative;
}
.food-slider-content > p {
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 30px;
    margin: 26px auto 44px;
    width: 50%;
}
.food-slider-btn {
    background-color: #ecab10;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 14px 40px 12px;
    text-transform: uppercase;
}
.food-slider-btn:hover {
    box-shadow: 0 10px 35px -7px rgba(236, 171, 16, 0.9);
    color: #fff;
}
.wrapper-padding-6 .container-fluid {
    padding: 0 236px 0 202px;
}

/* 10.3 Product style of food */

.popular-product-wrapper {
    display: flex;
    justify-content: space-between;
}
.section-title-10 > h2 {
    color: #666666;
    font-family: "Roboto Slab", serif;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.3px;
    margin-bottom: 21px;
}
.section-title-10 > p {
    color: #666666;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.3px;
    margin: 0 auto;
    width: 50%;
    font-weight: 400;
}
.single-popular-product > h3 {
    color: #1a1a1a;
    font-size: 36px;
    font-weight: bold;
    font-family: "Roboto Slab", serif;
    letter-spacing: .5px;
}
.single-popular-product > h3 a {
    color: #1a1a1a;
}
.single-popular-product > h3 a:hover {
    color: #ecab10;
}
.single-popular-product > p {
    color: #1a1a1a;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 0.3px;
    margin: 22px 0 18px;
    padding: 0 79px;
}
.pizza-old-price {
    color: #666666;
    font-family: "Roboto Slab", serif;
    font-size: 24px;
    font-weight: 400;
    margin: 0 7px;
    text-decoration: line-through;
}
.pizza-new-price {
    color: #1a1a1a;
    font-weight: bold;
    font-family: "Roboto Slab", serif;
    font-size: 30px;
    margin: 0 7px;
}
.single-popular-product a > img {
    margin-bottom: 29px;
}
.single-popular-product {
    position: relative;
}
.food-border-1::before {
    background-color: #ede9eb;
    content: "";
    height: 560px;
    margin: 0 auto;
    position: absolute;
    right: -15px;
    top: 3px;
    width: 1px;
}
.food-border-2::after {
    background-color: #ede9eb;
    content: "";
    height: 560px;
    margin: 0 auto;
    position: absolute;
    right: 1px;
    top: 3px;
    width: 1px;
}

/* 10.4 Service style of food */

.single-food-services img , .single-food-services p , .single-food-services h4 {
  z-index: 99;
    position: relative;
}
.single-food-services:hover img {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}
.single-food-services > h4 {
    color: #ffffff;
    font-family: "Roboto Slab", serif;
    font-size: 24px;
    font-weight: bold;
    margin: 38px 0 20px;
}
.single-food-services > p {
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin: 0;
}
.food-services-padding1 {
    padding-right: 90px;
    position: relative;
}
.food-services-padding2 {
    padding: 0 30px;
}
.food-services-padding3 {
    padding: 0 15px 0 72px;
}
.food-services-padding1::before {
    background: rgba(0, 0, 0, 0) url("../../img/bg/1.png") no-repeat scroll left 0 center;
    content: "";
    height: 100%;
    opacity: 1;
    position: absolute;
    right: -226px;
    top: -103px;
    transition-duration: 0.3s;
    width: 100%;
    z-index: 9;
}
.food-services-padding2::after {
    background: rgba(0, 0, 0, 0) url("../../img/bg/2.png") no-repeat scroll left 0 center;
    content: "";
    height: 100%;
    opacity: 1;
    position: absolute;
    right: -277px;
    top: -44px;
    transition-duration: 0.3s;
    width: 100%;
    z-index: 9;
}

/* 10.5 Foot menu style of food */


.single-menu-product {
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    padding: 15px;
}
.menu-product-content > h4 {
    color: #353535;
    font-family: "Roboto Slab", serif;
    /*font-weight: bold;*/
    letter-spacing: 0.3px;
    margin-bottom: 12px;
    padding-bottom: 16px;
}
.menu-product-content {
    margin-left: 35px;
}
.menu-product-price-rating {
    display: flex;
    margin-bottom: 5px;
}
.menu-product-old {
    color: #828282;
    display: inline-block;
    font-family: "Roboto Slab", serif;
    font-size: 18px;
    font-weight: bold;
    margin-right: 12px;
    text-decoration: line-through;
}
.menu-product-rating {
    line-height: 1;
    margin-left: 29px;
    margin-top: 9px;
}

.menu-product-new {
    color: #353535;
    font-size: 24px;
    font-family: "Roboto Slab", serif;
    font-weight: bold;
}
.menu-product-rating i {
    color: #0e0e0e;
    font-size: 18px;
}

.menu-product-content > p {
    color: #353535;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin: 0;
}
.food-menu-list > a h5 {

    color: #228B22;
    display: inline-block;
    font-family: "Roboto Slab", serif;
    font-weight: bold;
    margin: 0 10px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    border: 1px dashed;
    padding: 10px;
}

.food-menu-list > a.active h5,
.food-menu-list > a h5:hover {
    color: #999999;
}
.menu-btn {
    background-color: #ecab10;
    border-radius: 50px;
    color: #ffffff;
    display: inline-block;
    font-family: "Roboto Slab", serif;
    font-size: 18px;
    font-weight: normal;
    padding: 8px 40px 12px;
    z-index: 1;
}
.menu-btn:hover {
    color: #ffffff;
}
.btn-hover.menu-btn::before {
    border-radius: 50px;
}

/* 10.6 Banner style of food */

.food-banner-wrapper {
    position: relative;
}
.food-banner-content {
    left: 0;
    padding: 0 30px 0 230px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.food-banner-content > h3 {
    color: #e94439;
    font-size: 36px;
    font-weight: bold;
    line-height: 46px;
    font-family: "Roboto Slab", serif;
    margin: 0;
}
.food-banner-content-2 > h3 {
    color: #ff792a;
    font-size: 36px;
    font-weight: bold;
    line-height: 46px;
    font-family: "Roboto Slab", serif;
    margin: 0;
}
.food-banner-content > p {
    color: #202020;
    font-family: "Open Sans", sans-serif;
    margin: 7px 0 26px;
}
.food-banner-content > a {
    border: 1px solid #f1493e;
    border-radius: 50px;
    color: #f1493e;
    display: inline-block;
    font-family: "Roboto Slab", serif;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 30px 12px;
}
.food-banner-content-2 {
    padding: 10px 30px 10px 294px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.food-banner-content-2 > a {
    border: 1px solid #ff792a;
    border-radius: 50px;
    color: #ff792a;
    display: inline-block;
    font-family: "Roboto Slab", serif;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 30px 12px;
}
.food-banner-content-2 > a:hover,
.food-banner-content > a:hover {
    border: 1px solid #333;
    color: #333;
}
.food-banner-content-2 > p {
    color: #202020;
    font-family: "Open Sans", sans-serif;
    margin: 5px 0 7px;
}
.food-banner-rating i {
    color: #444545;
    margin: 0 1px;
}
.food-banner-rating {
    margin-bottom: 18px;
}
.food-banner-wrapper > img {
    width: 100%;
}

/* 10.7 Testimonial style of food */

.single-testimonial-3 {
    position: relative;
    z-index: 99;
}
.single-testimonial-3 > p {
    color: #cacaca;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-style: italic;
    line-height: 30px;
    margin: 33px auto 31px;
    width: 64%;
}
.single-testimonial-3 > h4 {
    color: #ffffff;
    font-family: "Roboto Slab", serif;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 6px;
}
.single-testimonial-3 > span {
    color: #aaaaaa;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    letter-spacing: .5px;
}
.testimonials-active.owl-carousel .owl-item img {
    display: inline-block;
    text-align: center;
    width: inherit;
}
.pagination-style .owl-dot {
    background: #f1f1f1 none repeat scroll 0 0;
    border-radius: 50px;
    display: inline-block;
    height: 10px;
    margin: 0 5px;
    width: 10px;
    transition: all .3s ease 0s;
}
.pagination-style .owl-dot.active {
    height: 10px;
    width: 30px;
}
.pagination-style .owl-dots {
    margin-top: 26px;
    text-align: center;
}
.container > h3 {
    color: #ffffff;
    font-family: "Roboto Slab", serif;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 61px;
    position: relative;
    text-align: center;
    z-index: 99;
}
.wrapper-padding-7 .container-fluid {
    padding: 0 255px
}
.special-food-content > h4,
.special-food-content > span {
    color: #666666;
    font-family: "Roboto Slab", serif;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}
.special-food-content > h4 a {
    color: #666666;
}
.special-food-content a img {
    display: block;
    width: 100%;
}
.special-food-content > h4 a:hover {
    color: #ecab10;
}
.special-food-content > p {
    color: #353535;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0.3px;
    margin: 14px 0 12px;
}
.special-food-content {
    padding: 24px 10px 0;
}
.brand-logo-active3.owl-carousel .owl-item img {
    width: inherit;
}
.section-title-11 > h2 {
    color: #cccccc;
    font-family: "Roboto Slab", serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 12px;
}
.section-title-11 > p {
    color: #999999;
    line-height: 20px;
    margin: 0;
    width: 97%;
}

/* 10.8 Subscribe style of food */

.subscribe-form-7 input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #5b5b63;
    border-radius: 10px;
    color: #616161;
    font-size: 14px;
    height: 48px;
    padding: 0 30px;
    width: 398px;
    display: inline-block;
    float: left;
}
.subscribe-form-7 .clear input.button {
    color: #a9a9a9;
    float: left;
    font-weight: 600;
    margin-left: 20px;
    padding: 0 40px;
    text-transform: uppercase;
    width: inherit;
}
.subscribe-form-7 .clear input.button:hover {
    color: #fff;
}
.subscribe-form-7 input::-moz-placeholder {
    color: #a9a9a9;
    opacity: 1;
}
.subscribe-form-7 input::-webkit-placeholder {
    color: #a9a9a9;
    opacity: 1;
}
.newsletter-style-7 {
    padding: 0 0 0 38px;
}

/* 10.9 Footer style of food */

.food-about > p {
    color: #fcfcfc;
    letter-spacing: 1px;
    margin: 20px 0 0;
    padding-bottom: 18px;
    position: relative;
}
.food-about-info > p {
    color: #dadada;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin-bottom: 23px;
}
.food-about-info {
    margin-top: 29px;
}
.food-address {
    display: flex;
    margin-bottom: 8px;
}
.food-info-icon {
    margin-right: 10px;
    margin-top: 6px;
    width: 20px;
}
.food-info-icon i {
    color: #dcdcdc;
    font-size: 18px;
}
.food-info-content > p {
    color: #f6f6f6;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    margin: 0;
}
.food-info-content > p a {
    line-height: 26px;
    color: #f6f6f6;
    font-family: "Open Sans", sans-serif;
}
.food-widget-content ul li {
    margin-bottom: 15px;
}
.food-widget-content ul li:last-child {
    margin-bottom: 0px;
}
.food-widget-content ul li a {
    color: #dfdfdf;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}
.food-info-content > p a:hover {
    color: #ddd;
}
.food-widget-content ul li a:hover {
    color: #aaa;
}
.food-widget-content ul li a img {
    margin-right: 16px;
}
.footer-widget-title-6 {
    color: #f6f6f6;
    font-family: "Roboto Slab", serif;
    font-size: 24px;
    margin-bottom: 30px;
    padding-bottom: 28px;
    position: relative;
}
.footer-widget-title-6:before,
.food-about > p:before {
    width: 50px;
    height: 2px;
    content: "";
    left: 0;
    bottom: 0;
    position: absolute;
    background-color: #5c5c63;
}
.food-widget .food-widget-content a > img {
    width: 100%;
}
.container > p {
    color: #b3b3b3;
    letter-spacing: 1px;
    margin: 0;
}
.container > p a {
    color: #b3b3b3;
}
.container > p a:hover {
    text-decoration: underline;
}
/*---------- 11. Fashion style 2 ------------*/
/* 11.1 Header style of fashion 2 */

.fashion-2-header .header-login ul li a {
    color: #999999;
}
.fashion-2-header .header-login ul li a:hover {
    color: #333;
}
.fashion-2-header .header-login ul li::after {
    background-color: #999999;
}
.fashion-2-header .icon-cart i {
    color: #383838;
}
.fashion-2-header .shop-count {
    background-color: #02bffc;
}
.header-sticky-2 {
    left: 0;
    padding: 60px 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.5s ease 0s;
    z-index: 999;
}
.header-sticky-2.stick {
    background: #03bffc none repeat scroll 0 0;
    padding: 15px 0;
    position: fixed;
    top: 0;
}
.fashion-2-header .header-search-cart-login {
    margin-right: 0;
    margin-top: 0;
    position: inherit;
}
.slider-content.slider-content-style-fashion {
    left: 377px;
}
.wrapper-padding-2.fashion-2-header .container-fluid {
    padding: 0 126px 0 100px;
}
.currency-2 {
    bottom: 47px;
    position: absolute;
    right: 103px;
    z-index: 99;
}
.currency-2 ul li::after {
    background-color: #999;
    content: "";
    font-size: 8px;
    height: 15px;
    position: absolute;
    right: -11px;
    top: 4px;
    width: 2px;
}
.currency-2 ul li:last-child::after {
    content: none;
}
.fashion-banner-wrapper > img {
    width: 100%;
}
.fashion-banner-wrapper {
    position: relative;
}
.fashion-banner-content {
    padding: 20px 80px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.fashion-banner-content > h2 {
    font-size: 70px;
    font-weight: bold;
    line-height: 85px;
    margin-bottom: 18px;
    text-transform: uppercase;
}
.fashion-2-btn {
    border: 1px solid #383838;
    color: #383838;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    padding: 13px 40px 12px;
    text-transform: uppercase;
}
.fashion-2-btn:hover {
    border: 1px solid #000;
    color: #fff;
}
.single-testimonial-4 > p {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 35px;
    margin: 30px auto;
    width: 63%;
}
.single-testimonial-4 > h4 {
    color: #666666;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
}
.clickable-mainmenu-btn {
    background-color: #02bffc;
    left: 90px;
    padding: 15px 10px;
    position: absolute;
    top: 460px;
    z-index: 999;
}

.btn-hover-black::before {
    background: #333 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;
    z-index: -1;
}
.btn-hover-black:hover::before {
    transform: scaleX(1);
}
.quickview-btn-wishlist .btn-hover:hover {
    color: white;
}
.mobile-menu-area.mobile-menu-none-block {
    display: none;
}
.clickable-mainmenu {
    background: rgba(0, 0, 0, 0.92) none repeat scroll 0 0;
    color: #353535;
    min-height: 100vh;
    padding: 235px 46px 39px;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(110%);
    transition: transform 0.5s ease-in-out 0s;
    width: 100%;
    z-index: 9999;
}
.clickable-mainmenu.inside {
    transform: translateX(0px);
    z-index: 9999;
}
.clickable-mainmenu-icon button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    cursor: pointer;
    font-size: 50px;
    padding: 0;
    position: absolute;
    right: 375px;
    top: 25px;
    transition: all 0.3s ease 0s;
}
.slinky-menu.slinky-theme-default {
    display: inline-block;
    width: 290px;
}
#menu li a {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 0 0 12px;
    text-transform: capitalize;
}
.slinky-theme-default {
    background: transparent none repeat scroll 0 0;
}
.slinky-theme-default a:hover:not(.back) {
    background: transparent none repeat scroll 0 0;
}


/* 11.2 Slider style of fashion 2 */

.text-bg {
    margin-bottom: 30px;
    position: relative;
}
.text-bg::before {
    background: rgba(0, 0, 0, 0) url("../../img/icon-img/44.png") no-repeat scroll left 0 center;
    content: "";
    height: 75px;
    left: 8px;
    opacity: 1;
    position: absolute;
    top: 5px;
    transition-duration: 0.3s;
    width: 555px;
    z-index: 9;
}
.slider-content-style-fashion .text-bg > img {
    position: relative;
    z-index: 99;
}
.slider-content-style-fashion > p {
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 3px;
}

/*------- 12. Breadcrumb style -------------*/
.breadcrumb-content {
    margin-top: 50px;
}
#cert-bread{
        margin-top: 50px;
    margin-right: 916px;
    background: hsla(0, 0%, 0%, 0.4);
    padding-left: 6px;
    padding-bottom: 5px;
}
.breadcrumb-content h2 {
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 16px;
    text-transform: capitalize;
}
.breadcrumb-content li {
    color: #fff;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
}
.breadcrumb-content li a {
    color: #fff;
    position: relative;
    padding-right: 18px;
}
.breadcrumb-content li a:hover {
    color: #ccc;
}
.breadcrumb-content li a::after {
    background-color: #fff;
    content: "";
    font-size: 8px;
    height: 1px;
    position: absolute;
    right: 0;
    top: 10px;
    transform: rotate(-63deg);
    width: 13px;
}
/*--------- 13. Login page ---------*/
.login-form-container {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #ddd;
    padding: 60px 40px;
    text-align: left;
}
.login-text {
    margin-bottom: 30px;
    text-align: center;
}
.login-text h2 {
    color: #444;
    font-size: 30px;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.login-text span {
    font-size: 15px;
}
.login-form-container input {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: none;
    color: #999;
    font-size: 14px;
    height: 40px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}
.login-form-container input::-moz-placeholder {
    color: #999;
    opacity: 1;
}
.login-toggle-btn {
    padding-top: 10px;
}
.login-form-container input[type="checkbox"] {
    height: 15px;
    margin: 0;
    position: relative;
    top: 1px;
    width: 17px;
}
.login-form-container label {
    color: #777;
    font-size: 15px;
    font-weight: 400;
}
.login-toggle-btn > a {
    color: #777;
    float: right;
    transition: all 0.3s ease 0s;
}
.login-toggle-btn > a:hover {
    color: #000;
}
.button-box .default-btn {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #ddd;
    color: #777;
    font-size: 14px;
    line-height: 1;
    margin-top: 25px;
    padding: 12px 36px 10px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    border-radius: 50px;
}
.button-box .default-btn:hover {
    background-color: #050035;
    border: 1px solid #050035;
    color: #fff;
}
/*-------- 14. Shopping cart ----------*/
h1.cart-heading {
    color: #252525;
    font-size: 25px;
    margin-bottom: 25px;
    text-transform: uppercase;
}
.cart-title-area {
    padding-top: 30px;
}
.car-header-title h2 {
    font-size: 20px;
    margin: 0;
    text-transform: uppercase;
}
.table-content table {
    background: #fff none repeat scroll 0 0;
    border-color: #e5e5e5;
    border-radius: 0;
    border-style: solid;
    border-width: 1px 0 0 1px;
    margin: 0 0 50px;
    text-align: center;
    width: 100%;
    border: none;
}
.wishlist .table-content table {
    margin: 0 0 0px;
}
.table-content.wish table {
    margin: 0 0 0px;
}
.table-content table th {
    border-top: medium none;
    color: #555;
    font-size: 16px;
    font-weight: normal;
    padding: 15px 10px 12px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
}
.table-content table td {
    border-top: medium none;
    padding: 40px 10px;
    vertical-align: middle;
    font-size: 13px;
}
.table-content table td input {
    background: #e5e5e5 none repeat scroll 0 0;
    border: medium none;
    border-radius: 3px;
    color: #6f6f6f;
    font-size: 15px;
    font-weight: normal;
    height: 40px;
    padding: 0 5px 0 10px;
    width: 60px;
}
.table-content table td.product-subtotal {
    color: #555;
    font-size: 15px;
    width: 120px;
}
.table-content table td.product-name a {
    color: #6f6f6f;
    font-size: 15px;
    margin-left: 10px;
}
.table-content table td.product-name a:hover {
    color: #050035;
}
.table-content table td.product-name {
    width: 270px;
}
.table-content table td.product-thumbnail {
    width: 130px;
}
.table-content table td.product-remove i {
    color: #919191;
    display: inline-block;
    font-size: 35px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
}
.table-content table .product-price .amount {
    color: #555;
    font-size: 15px;
}
.table-content table td.product-remove i:hover {
    color: #050035;
}
.table-content table td.product-quantity {
    width: 180px;
}
.table-content table td.product-remove {
    width: 150px;
}
.table-content table td.product-price-cart {
    width: 130px;
}
thead {
    background-color: #f6f6f6;
}
.coupon input {
    background-color: transparent;
    border: 1px solid #ddd;
    color: #555;
    font-size: 13px;
    height: 42px;
    width: 120px;
}
.coupon2 input {
    width: inherit;
}
.coupon-all input.button {
    background-color: #333;
    border: 0 none;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    height: 42px;
    letter-spacing: 1px;
    line-height: 42px;
    padding: 0 25px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out 0s;
    width: inherit;
}
.coupon-all input.button:hover {
    background-color: #050035;
    border: medium none;
    color: #fff;
}
.coupon-all {
    margin-top: 30px;
}
.coupon {
    float: left;
}
.coupon2 {
    float: right;
}
.cart-page-total span {
    float: right;
}
.cart-page-total > ul {
    border: 1px solid #ddd;
}
.cart-page-total li {
    border-bottom: 1px solid #ddd;
    color: #555;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 30px;
}
.cart-page-total li:last-child {
    border-bottom: none;
}
.cart-page-total {
    padding-top: 50px;
}
.cart-page-total > a {
    background-color: #333;
    border: 1px solid #333;
    color: #fff;
    display: inline-block;
    margin-top: 30px;
    padding: 9px 20px;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
}
.cart-page-total > a:hover {
    background-color: #050035;
    color: #fff;
    border: 1px solid #050035;
}
.cart-page-total > h2 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: capitalize;
}
/*-------- 15. Checkout page ----------*/
.coupon-accordion h3 {
    background-color: #f7f6f7;
    border-top: 3px solid #050035;
    color: #515151;
    font-size: 14px;
    font-weight: 400;
    list-style: outside none none !important;
    margin: 0 0 2em !important;
    padding: 1em 2em 1em 3.5em !important;
    position: relative;
    width: auto;
}
.coupon-accordion h3::before {
    color: #333;
    content: "";
    display: inline-block;
    font-family: "Pe-icon-7-stroke";
    font-size: 30px;
    left: 10px;
    position: absolute;
    top: 3px;
}
.coupon-accordion span {
    cursor: pointer;
    color: #6f6f6f;
}
.coupon-accordion span:hover,
p.lost-password a:hover {
    color: #050035;
}
.coupon-content {
    border: 1px solid #e5e5e5;
    display: none;
    margin-bottom: 20px;
    padding: 20px;
}
.coupon-info p.coupon-text {
    margin-bottom: 15px;
}
.coupon-info p {
    margin-bottom: 0;
}
.coupon-info p.form-row-first label,
.coupon-info p.form-row-last label {
    display: block;
}
.coupon-info p.form-row-first label span.required,
.coupon-info p.form-row-last label span.required {
    color: red;
    font-weight: 700;
}
.coupon-info p.form-row-first input,
.coupon-info p.form-row-last input {
    border: 1px solid #e5e5e5;
    height: 36px;
    margin: 0 0 14px;
    max-width: 100%;
    padding: 0 0 0 10px;
    width: 370px;
    background-color: transparent;
}
.coupon-info p.form-row input[type="submit"]:hover,
p.checkout-coupon input[type="submit"]:hover {
    background: #050035 none repeat scroll 0 0;
}
.coupon-info p.form-row input[type="checkbox"] {
    height: inherit;
    position: relative;
    top: 2px;
    width: inherit;
}
.form-row > label {
    margin-top: 7px;
}
p.lost-password {
    margin-top: 15px;
}
p.lost-password a {
    color: #6f6f6f;
}
p.checkout-coupon input[type="text"] {
    background-color: transparent;
    border: 1px solid #ddd;
    height: 36px;
    padding-left: 10px;
    width: 170px;
}
p.checkout-coupon input[type="submit"] {
    background: #333 none repeat scroll 0 0;
    border: medium none;
    border-radius: 0;
    color: #fff;
    height: 36px;
    margin-left: 6px;
    padding: 5px 10px;
    transition: all 0.3s ease 0s;
    width: inherit;
}
.coupon-checkout-content {
    margin-bottom: 30px;
    display: none;
}
.checkbox-form h3 {
    border-bottom: 1px solid #e5e5e5;
    font-size: 25px;
    margin: 0 0 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    width: 100%;
}
.country-select {
    margin-bottom: 30px;
    position: relative;
}
.country-select label,
.checkout-form-list label {
    color: #333;
    margin: 0 0 5px;
    display: block;
}
.country-select label span.required,
.checkout-form-list label span.required {
    color: red;
}
.country-select select {
    -moz-appearance: none;
    border: 1px solid #ddd;
    height: 32px;
    padding-left: 10px;
    width: 100%;
    background-color: transparent;
}
.country-select::before {
    content: "";
    display: inline-block;
    font-family: "Pe-icon-7-stroke";
    font-size: 26px;
    position: absolute;
    right: 12px;
    top: 23px;
}
.checkout-form-list {
    margin-bottom: 30px;
}
.checkout-form-list label {
    color: #333;
}
.checkout-form-list input[type=text],
.checkout-form-list input[type=password],
.checkout-form-list input[type=email] {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    height: 42px;
    width: 100%;
    padding: 0 0 0 10px;
}
.checkout-form-list input[type="checkbox"] {
    display: inline-block;
    height: inherit;
    margin-right: 10px;
    position: relative;
    top: 2px;
    width: inherit;
}
.ship-different-title input {
    height: inherit;
    line-height: normal;
    margin: 4px 0 0;
    position: relative;
    top: 1px;
    width: 30px;
}
.create-acc label {
    color: #333;
    display: inline-block;
}
.create-account {
    display: none;
}
.ship-different-title h3 label {
    display: inline-block;
    margin-right: 20px;
    font-size: 25px;
    color: #363636;
}
.order-notes textarea {
    background-color: transparent;
    border: 1px solid #ddd;
    height: 90px;
    padding: 15px;
    width: 100%;
}
#ship-box-info {
    display: none;
}
.your-order {
    background: #f2f2f2 none repeat scroll 0 0;
    padding: 30px 40px 45px;
}
.your-order h3 {
    border-bottom: 1px solid #d8d8d8;
    font-size: 25px;
    margin: 0 0 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    width: 100%;
}
.your-order-table table {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    width: 100%;
}
.your-order-table table th,
.your-order-table table td {
    border-bottom: 1px solid #d8d8d8;
    border-right: medium none;
    font-size: 14px;
    padding: 15px 0;
    text-align: center;
}
.your-order-table table th {
    border-top: medium none;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    width: 250px;
}
.your-order-table table .shipping ul li input {
    position: relative;
    top: 2px;
}
.your-order-table table .shipping th {
    vertical-align: top;
}
.your-order-table table .order-total th {
    border-bottom: medium none;
    font-size: 18px;
}
.your-order-table table .order-total td {
    border-bottom: medium none;
}
.your-order-table table tr.cart_item:hover {
    background: #F9F9F9;
}
.your-order-table table tr.order-total td span {
    color: #464646;
    font-size: 20px;
}
.payment-method {
    margin-top: 40px;
}
.payment-accordion h3 {
    border-bottom: 0 none;
    margin-bottom: 10px;
    padding-bottom: 0;
}
.payment-accordion h3 a {
    color: #6f6f6f;
    font-size: 14px;
    padding-left: 25px;
    position: relative;
    text-transform: capitalize;
    text-decoration: none;
}
.payment-content p {
    font-size: 13px;
}
.payment-accordion img {
    height: 60px;
    margin-left: 15px;
}
.order-button-payment input {
    background: #464646 none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    height: 50px;
    margin: 20px 0 0;
    padding: 0;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    width: 100%;
    border: 1px solid transparent;
    cursor: pointer;
}
.order-button-payment input:hover {
    background: #050035;
    border: 1px solid #050035;
    color: #fff;
}
.coupon-info p.form-row input[type="submit"] {
    background: #252525 none repeat scroll 0 0;
    border: medium none;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    margin-right: 15px;
    padding: 0 30px;
    text-shadow: none;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    white-space: nowrap;
    width: inherit;
}
.mrg-nn {
    margin-bottom: 0;
}
h5.panel-title {
    color: #444;
    font-weight: 600;
}
.panel-body > p {
    color: #666;
}
.panel.panel-default {
    margin-bottom: 20px;
}
/*------- 16. Shop page ---------*/
.shop-page-padding .container-fluid {
    padding: 0 100px;
}
.sidebar-widget {
    display: block;
}
.sidebar-title {
    color: #383838;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
    text-transform: capitalize;
}
.product-tags ul li {
    display: inline-block;
    margin: 0 10px 0 0;
    text-transform: capitalize;
}
.product-tags ul li a {
    color: #888;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.4px;
}
.product-tags ul li a:hover {
    color: #050035;
}
.sidebar-search form {
    position: relative;
}
.sidebar-search form input {
    background: #f1f1f1 none repeat scroll 0 0;
    border: medium none;
    box-shadow: none;
    color: #8e8d8d;
    font-size: 13px;
    padding-left: 15px;
    padding-right: 55px;
}
.sidebar-search form > button {
    background-color: #dedede;
    border: medium none;
    color: #050035;
    cursor: pointer;
    font-size: 18px;
    height: 45px;
    line-height: 50px;
    padding: 0 16px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s ease 0s;
}
.price_filter .ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
    background: #ebebeb none repeat scroll 0 0;
    border: medium none;
    border-radius: 5px;
    color: #222222;
    height: 3px;
    margin-bottom: 21px;
    margin-left: auto;
}
.price_filter .ui-slider-range {
    background: #b7b7b7 none repeat scroll 0 0;
    border-radius: 0;
    height: 100%;
    top: 0;
}
.price_filter .ui-slider-handle.ui-state-default.ui-corner-all {
    background: #b7b7b7 none repeat scroll 0 0;
    border: medium none;
    border-radius: 0%;
    height: 15px;
    width: 4px;
}
.ui-slider-horizontal .ui-slider-handle {
    margin-left: 0;
    top: -6px;
}
.price_slider_amount {
    display: block;
    overflow: hidden;
}
.price_slider_amount {
    display: block;
    overflow: hidden;
}
.label-input {
    display: inline-block;
    float: left;
}
.label-input label {
    color: #575757;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    text-transform: capitalize;
}
.label-input input {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    box-shadow: none;
    color: #575757;
    font-size: 14px;
    font-weight: 600;
    height: inherit;
    padding-left: 5px;
    width: 99px;
}
.price_slider_amount > button {
    background-color: transparent;
    border: medium none;
    color: #575757;
    display: inline-block;
    float: right;
    font-weight: 600;
    line-height: 1;
    padding: 4px 0 0;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
}
.sidebar-categories li {
    margin-bottom: 16px;
}
.sidebar-categories li a {
    color: #888888;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
}
.sidebar-categories li a span {
    float: right;
}
.product-color > ul li {
    border-radius: 50px;
    cursor: pointer;
    display: block;
    float: left;
    height: 22px;
    margin-right: 20px;
    text-indent: -9999px;
    transition: all 0.4s ease 0s;
    width: 22px;
}
.product-color > ul li.red {
    background: #050035 none repeat scroll 0 0;
}
.product-color > ul li.pink {
    background: #ff01f0 none repeat scroll 0 0;
}
.product-color > ul li.blue {
    background: #3649ff none repeat scroll 0 0;
}
.product-color > ul li.sky {
    background: #00c0ff none repeat scroll 0 0;
}
.product-color > ul li.green {
    background: #00ffae none repeat scroll 0 0;
}
.product-color > ul li.purple {
    background: #8a00ff none repeat scroll 0 0;
}
.sidebar-widget.sidebar-overflow {
    overflow: hidden;
}
.product-size li {
    display: inline-block;
    margin-right: 16px;
}
.product-size li a {
    color: #888888;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
}
.single-top-rated {
    display: flex;
}
.top-rated-text {
    margin-left: 20px;
}
.top-rated-text > h4 {
    color: #383838;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}
.top-rated-rating {
    line-height: 1;
    margin: 9px 0 10px;
}
.top-rated-rating li {
    display: inline-block;
    margin-right: 2px;
}
.top-rated-text > span {
    color: #8b8b8b;
    font-weight: 600;
}
.shop-bar {
    display: flex;
    justify-content: space-between;
}
.shop-found-selector {
    display: flex;
}
.shop-found > p {
    color: #6c6c6c;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.shop-found > p span {
    color: #050035;
}
.shop-selector {
    margin-left: 63px;
    margin-top: -3px;
}
.shop-selector label {
    color: #6c6c6c;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}
.shop-selector select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: rgba(0, 0, 0, 0) url("../../img/icon-img/4.png") no-repeat scroll right 0 center;
    border: medium none;
    box-shadow: none;
    color: #090909;
    font-size: 18px;
    height: inherit;
    line-height: 1;
    padding-left: 0;
    width: 113px;
}
.shop-tab a {
    color: #6c6c6c;
    font-size: 23px;
    line-height: 1;
    margin-left: 10px;
    z-index: 99;
}
.shop-tab a:first-child {
    margin-left: 0;
}
.shop-tab a.active,
.shop-tab a:hover {
    color: #050035;
}
.pagination-style ul li {
    display: inline-block;
    margin: 0 3px;
}
.pagination-style ul li a {
    background-color: #f6f6f6;
    color: #3f3f3f;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    height: 40px;
    line-height: 41px;
    width: 40px;
}
.pagination-style ul li.active a,
.pagination-style ul li a:hover {
    background-color: #3f3f3f;
    color: #fff;
}
.single-product-list {
    display: flex;
}
.product-content-list {
    margin-left: 30px;
}
.product-list-info > h4 {
    color: #191919;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
.product-list-info > span {
    color: #666;
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin: 11px 0 4px;
}
.product-list-info > p {
    color: #818181;
    font-size: 16px;
    line-height: 30px;
    margin: 0;
}
.product-list-cart-wishlist {
    display: flex;
    margin-top: 42px;
}
.product-list-cart {
    margin-right: 20px;
}
.list-btn-style {
    border: 1px solid #dcdcdc;
    border-radius: 50px;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 1;
    padding: 15px 23px;
    text-transform: uppercase;
}
.list-btn-style.btn-hover::before,
.list-btn-wishlist.btn-hover::before {
    border-radius: 50px;
}
.list-btn-wishlist {
    border: 1px solid #dcdcdc;
    color: #727272;
    display: inline-block;
    font-size: 22px;
    line-height: 1;
    padding: 10px 18px 8px;
    border-radius: 50px;
}
.list-btn-style:hover,
.list-btn-wishlist:hover {
    border: 1px solid transparent;
    color: #fff;
}
.product-action-list-style {
    top: 60%;
    transform: translateY(-50%);
    display: inline-block;
    left: 0;
    opacity: 0;
    padding: 0 20px;
    position: absolute;
    right: 0;
    text-align: center;
    transition: all 0.3s ease 0s;
}
.product-action-list-style a {
    background-color: #383838;
    border-radius: 100%;
    color: #f7f7f7;
    display: inline-block;
    font-size: 24px;
    height: 45px;
    line-height: 52px;
    margin: 0 15px;
    width: 45px;
}
.product-action-list-style a:hover {
    background-color: #050035;
    color: #fff;
}
.product-wrapper:hover .product-action-list-style {
    opacity: 1;
    top: 50%;
}
.list-img-width {
    flex: 0 0 312px;
    position: relative;
}
.breadcrumb-padding .container-fluid {
    padding: 0 100px;
}
.product-box-style .product-action a {
    margin: 0 7px;
}
/*--------- 17. Product details ------------*/
.details-9 {
  overflow: hidden;
}
.product-details-content > h3,
.inner-wrapper-sticky > h3 {
    color: #383838;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 13px;
}
.details-price > span {
    color: #050035;
    font-size: 24px;
    font-weight: 600;
}
.product-details-4 .easyzoom a img {
    width: 100%;
}
.product-details-5.pr-70 > img {
    width: 100%;
}
.product-details-content > p {
    color: #545454;
    margin-bottom: 33px;
}
.select-option-part {
    margin-bottom: 35px;
}
.select-option-part label {
    color: #383838;
    font-size: 14px;
    margin-bottom: 14px;
}
.select-option-part select {
    -moz-appearance: none;
    background: rgba(0, 0, 0, 0) url("../../img/icon-img/4.png") no-repeat scroll right 20px center;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    color: #828282;
    font-size: 14px;
    height: 43px;
    padding-left: 20px;
    position: relative;
    width: 100%;
}
.product-details-content .rating-number {
    margin-bottom: 5px;
}
.product-details-cati-tag li.categories-title,
.product-share li.categories-title {
    color: #383838;
    font-size: 15px;
    text-transform: uppercase;
}
.product-details-cati-tag ul li {
    display: inline-block;
    margin: 0 8px 0 0;
    text-transform: capitalize;
}
.product-share li {
    display: inline-block;
    margin-right: 14px;
}
.product-details-cati-tag li a {
    text-transform: capitalize;
    color: #666;
    display: inline-block;
    letter-spacing: 0.4px;
}
.product-share > ul li {
    display: inline-block;
    margin-right: 14px;
}
.product-share > ul li a {
    border: 1px solid #3f3f3f;
    border-radius: 50px;
    color: #585858;
    display: inline-block;
    height: 30px;
    text-align: center;
    width: 30px;
    line-height: 30px;
}
.product-details-cati-tag li a:hover {
    color: #050035;
}
.product-share > ul li a:hover {
    background-color: #050035;
    border: 1px solid #050035;
    color: #fff;
}
.details-price {
    margin-bottom: 25px;
}
.product-details-large .easyzoom > a img , .details-9 .easyzoom > a img {
    width: 100%;
}
.product-details-small > a.active {
    position: relative;
}
.product-details-small > a.active::before {
    background: rgba(0, 0, 0, 0.15) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 400ms ease-in 0s;
    z-index: 10;
}
.product-details-small a > img {
    width: 100%;
}
.product-details-small a:last-child {
    margin-right: 0;
}
.description-review-title {
    justify-content: center;
    margin-bottom: 44px;
}
.description-review-title a.active {
    color: #333333;
}
.description-review-title a {
    color: #666;
    font-size: 30px;
    font-weight: 600;
    margin: 0 20px;
}
.description-review-text .tab-pane p {
    color: #545454;
    font-size: 15px;
    margin: 0 auto;
    width: 61%;
}
#pro-review a {
    background-color: #282828;
    border: 0 none;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 10px 15px;
    text-shadow: none;
    text-transform: uppercase;
}
.product-details-tab.product-details-tab2 {
    display: flex;
}
.product-details-2 {
    flex: 0 0 125px;
}
.details-title {
    color: #383838;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 19px;
}
.product-color-2 {
    margin-bottom: 37px;
    overflow: hidden;
}
.product-color-style2 > ul li {
    border-radius: 50px;
    cursor: pointer;
    display: block;
    float: left;
    height: 19px;
    margin-right: 10px;
    position: relative;
    transition: all 0.4s ease 0s;
    width: 19px;
}
.product-color-style2 > ul li.blue2 {
    background: #425dff none repeat scroll 0 0;
}
.product-color-style2 > ul li.orange {
    background: #ff5d42 none repeat scroll 0 0;
    position: relative;
}
.product-color-style2 > ul li.pink {
    background: #fd42ff none repeat scroll 0 0;
}
.product-color-style2 > ul li.yellow {
    background: #ffd542 none repeat scroll 0 0;
}
.product-size-style2 li {
    display: inline-block;
    margin-right: 12px;
}
.product-size-style2 li a {
    color: #828282;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
}
.product-size-2 {
    margin-bottom: 30px;
}
.product-size-style2 li a:hover {
    color: #050035;
}
.scroll-single-product > img {
    width: 100%;
}
.scroll-single-product {
    position: relative;
}
.scroll-single-product > a {
    background-color: #fff;
    border-radius: 50px;
    bottom: 30px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    color: #050035 !important;
    font-size: 22px;
    height: 45px;
    line-height: 50px;
    position: absolute;
    right: 30px;
    text-align: center;
    transform: scale(0);
    width: 45px;
}
.scroll-single-product > a:hover {
    background-color: #050035;
    color: #fff !important;
}
.scroll-single-product:hover a {
    transform: scale(1);
}
.product-details5-social li {
    display: inline-block;
    margin-right: 27px;
}
.product-details5-social li a {
    color: #383838;
    font-size: 18px;
}
.product-details5-social li a:hover {
    color: #050035;
    font-size: 18px;
}
.sidebar-details7 .quickview-btn-cart > a {
    padding: 17px 16px;
}
.single-details-gallery {
    flex: 0 0 290px;
    float: left;
}
.bundle-area > h3 {
    color: #383838;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}
.bundle-img {
    display: flex;
    margin: 34px 0 35px;
}
.single-bundle-img {
    cursor: pointer;
    margin-right: 11px;
    position: relative;
}
.single-bundle-img::before {
    background: rgba(0, 0, 0, 0.15) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 400ms ease-in 0s;
}
.single-bundle-img a img {
    width: 100%;
}
.single-bundle-img:hover::before {
    opacity: 1;
}
.bundle-price ul li::before {
    color: #727272;
    content: "";
    font-family: "themify";
    font-size: 14px;
    left: 0;
    position: absolute;
    text-indent: inherit;
    top: 0;
}
.bundle-price ul li {
    color: #383838;
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
}
.bundle-result {
    margin-top: 32px;
}
.bundle-result > h4 span {
    color: #747373;
    font-weight: 400;
}
.bundle-result > h4 span .bundle-cross {
    position: relative;
}
.bundle-result > h4 span .bundle-cross {
    position: relative;
}
.bundle-cart {
    margin: 37px 0 38px;
}
a.bundle-btn {
    background-color: #050035;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 1;
    padding: 17px 25px;
    position: relative;
    text-transform: uppercase;
    z-index: 9;
}
.details-9-content .rating-number,
.details-9-content .quickview-plus-minus {
    justify-content: center;
}
.details-9-content .product-details-content > p {
    margin: 0 auto;
    width: 54%;
}
.details-9-content .product-color-style2 > ul li {
    display: inline-block;
    float: inherit;
}
/*----------- 18. Compare style --------------*/
.modal-compare-width {
    max-width: 900px;
}
.table-content.compare-style th a span {
    color: #050035;
    display: inline-block;
    font-weight: 600;
    line-height: 0.81em;
    margin: 0;
    text-align: center;
}
.table-content.compare-style th p {
    color: #383838;
    font-size: 16px;
    margin: 0;
    text-transform: capitalize;
}
.table-content.compare-style th a {
    text-transform: capitalize;
    font-size: 14px;
}
.table-content.compare-style th a span {
    color: #050035;
    display: inline-block;
    font-size: 1em;
    font-weight: 600;
    line-height: 0.81em;
}
.table-content.compare-style th img {
    display: block;
    margin: 20px 0;
}
.table-content.table-responsive.compare-style tbody tr,
.table-content.table-responsive.compare-style thead {
    border-bottom: 1px solid #f1f1f1;
}
.table-content.table-responsive.compare-style tbody tr:last-child {
    border-bottom: 0px solid #f1f1f1;
}
.table-content.compare-style thead {
    background-color: transparent;
}
.table-content.compare-style table {
    text-align: inherit;
}
.table-content.compare-style table th {
    padding: 14px 0;
    text-align: inherit;
}
.table-content table td.compare-title h4 {
    border-top: medium none;
    color: #333333;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    min-width: 205px;
    overflow: hidden;
    text-align: left;
    text-transform: uppercase;
    vertical-align: middle;
}
.table-content table td.compare-common,
.table-content table td.compare-title {
    font-size: 14px;
    padding: 14px 0;
}
.table-content.compare-style table td p {
    margin: 0;
}
.table-content.compare-style th a.compare-btn {
    color: #383838;
    font-size: 14px;
    text-transform: capitalize;
}
.table-content.compare-style th a.compare-btn:hover {
    color: #050035;
}
.table-content.compare-style th span {
    display: block;
    margin: 10px 0;
}
.table-content.compare-style table {
    margin: 0 0 0px;
}
.modal .close {
    color: #fff;
    float: right;
    font-size: 50px;
    font-weight: bold;
    line-height: 1;
    margin-right: 50px;
    opacity: 1;
    text-shadow: 0 1px 0 #fff;
}
/*--------- 19. Blog style -----------*/
.blog-meta ul li {
    color: #666;
    display: inline-block;
    font-size: 13px;
    margin-right: 28px;
    position: relative;
}
.blog-meta ul li::after {
    background-color: #b6b6b6;
    content: "";
    font-size: 8px;
    height: 13px;
    position: absolute;
    right: -17px;
    top: 3px;
    width: 2px;
}
.blog-meta ul li:last-child::after {
    content: none;
}
.blog-meta ul li:last-child {
    margin-right: 0px;
}
.blog-meta ul li a {
    color: #666;
    font-size: 13px;
}
.blog-meta ul li a:hover {
    color: #383838;
}
.blog-info-wrapper > h4 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin: 15px 0 10px;
}
.blog-info-wrapper > p {
    color: #666;
    letter-spacing: 0.4px;
    line-height: 26px;
    margin: 0 0 25px;
    text-align: justify;
}
.blog-btn {
    border: 1px solid #d7d7d7;
    color: #383838;
    display: inline-block;
    font-weight: 500;
    line-height: 1;
    padding: 13px 20px 11px;
    text-transform: capitalize;
}
.blog-btn:hover {
    border: 1px solid transparent;
    color: #fff;
}
.sidebar-widget.mb-50 > img {
    width: 100%;
}
.sidebar-img-content {
    background: #f6f6f6 none repeat scroll 0 0;
    padding: 20px;
}
.sidebar-img-content > p {
    color: #646464;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 21px;
}
.sidebar-img-content h4 {
    color: #2f2f2f;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}
.sidebar-img-content > span {
    color: #2f2f2f;
    font-size: 12px;
    font-weight: 400;
}
.sidebar-img-social {
    margin-top: 10px;
}
.sidebar-img-social ul li {
    display: inline-block;
    margin-right: 20px;
}
.sidebar-img-social ul li > a {
    font-size: 16px;
}
.blog-tags-style li {
    display: inline-block;
    margin: 0 5px 10px 0;
}
.blog-tags-style li a {
    border: 1px solid #cbcbcb;
    color: #383838;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 13px 19px;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
}
.blog-tags-style li a:hover {
    background-color: #000;
    color: #fff;
}

.leave-form input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #e2e2e2;
    color: #666666;
    font-size: 14px;
    height: 40px;
    padding: 0 20px;
    width: 100%;
    letter-spacing: .4px;
}
.text-leave textarea {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #e2e2e2;
    color: #666666;
    font-size: 14px;
    height: 160px;
    margin-bottom: 30px;
    padding: 20px;
    letter-spacing: .4px;
}
.leave-form input::-moz-placeholder,
.text-leave textarea::-moz-placeholder {
    color: #666666;
    opacity: 1;
}
.leave-form input::-webkit-placeholder,
.text-leave textarea::-webkit-placeholder {
    color: #666666;
    opacity: 1;
}
.leave-btn button {
    background-color: #000;
    border: medium none;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    line-height: 1;
    padding: 6px 30px 13px;
    text-transform: uppercase;
}
.leave-btn button:hover {
    background-color: #666;
}
.leave-btn button i {
    font-size: 20px;
    line-height: 1;
    margin-right: 3px;
    position: relative;
    top: 4px;
}
.blog-details-title2 {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 29px;
    padding-bottom: 13px;
}
.replay-img {
    display: inline-block;
    float: left;
}
.replay-info {
    display: flex;
    justify-content: space-between;
}
.replay-info-wrapper {
    margin-left: 110px;
}
.replay-name-date > h5 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.replay-name-date > span {
    color: #949494;
    font-size: 13px;
}
.replay-name-date {
    margin-bottom: 3px;
}
.replay-info-wrapper > p {
    color: #333333;
    letter-spacing: 0.4px;
    margin: 0;
}
.single-blog-replay {
    display: block;
    margin-bottom: 43px;
    overflow: hidden;
}
.single-blog-replay:last-child {
    margin-bottom: 0px;
}
.blog-replay-wrapper {
    margin-top: 50px;
}
.single-blog-replay.middle-blog-repley {
    margin-left: 110px;
}
.replay-btn > a {
    background-color: #ebebeb;
    border-radius: 5px;
    color: #333;
    display: inline-block;
    line-height: 1;
    padding: 10px 20px 11px;
}
.replay-btn > a:hover {
    background-color: #000;
    color: #fff;
}

.heading-bottom-space{
    margin-bottom: 50px;
}
/*------------ 21. Contact us -----------*/
#hastech2 {
    height: 402px;
    width: 100%;
}
.contact-input-style input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #ececec;
    color: #666666;
    font-size: 14px;
    height: 35px;
    padding: 0 20px;
    width: 100%;
    line-height: 35px;
}
.contact-textarea-style textarea {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #ececec;
    color: #666666;
    font-size: 14px;
    padding: 0 20px;
    width: 100%;
    height: 170px;
    line-height: 35px;
}
.contact-input-style label,
.contact-textarea-style label {
    color: #383838;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}
.contact-title > h4 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.contact-btn {
    background-color: #85bd02;
    border: 1px solid transparent;
    color: #fff;
    display: inline-block;
    padding: 11px 25px 13px;
    z-index: 1;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
}
.contact-btn:hover {
    background-color: #648f00;
}
.single-contact-info {
    display: flex;
    margin-bottom: 15px;
}
.contact-info-icon {
    width: 35px;
}
.contact-info-icon i {
    color: #383838;
    font-size: 22px;
}
.contact-info-text > p {
    color: #383838;
    margin: 0;
}
.contact-info-text > p span {
    font-weight: 600;
}
/*------------- 22. About us -------*/
.story-img {
    position: relative;
}
.story-img > img {
    width: 100%;
}
.about-logo {
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.about-logo h3 {
    background-color: #050035;
    border-radius: 100%;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    margin: 0;
    position: relative;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    width: 185px;
    height: 185px;
    line-height: 185px;
}
.about-logo h3::before {
    border: 1px solid #fff;
    border-radius: 100%;
    content: "";
    height: 152px;
    left: 17px;
    line-height: 144px;
    position: absolute;
    top: 17px;
    width: 152px;
}
.story-details-top > h2 {
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 20px;
    text-transform: uppercase;
}
.story-details-top > h2 span {
    color: #050035;
}
.story-details-top > p,
.story-details-bottom > p,
.goal-wrapper.mb-30 > p {
    color: #666;
    letter-spacing: 0.4px;
    line-height: 26px;
    margin: 0;
}
.story-details-top {
    margin-bottom: 48px;
}
.story-details-bottom > h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.story-details-bottom {
    margin-bottom: 48px;
}
.story-details-bottom:last-child {
    margin-bottom: 0px;
}
.goal-wrapper.mb-30 > h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 14px;
    text-transform: uppercase;

}
.goal-wrapper > p {
    padding-right: 30px;
}
.about-section > h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 23px;
    text-transform: uppercase;
}
.about-section > p,
.choose-wrapper > p {
    color: #666;
    letter-spacing: 0.4px;
    line-height: 26px;
    margin: 0;
}
.about-section {
    width: 62%;
}
.choose-wrapper > h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}
.choose-wrapper {
    margin-bottom: 31px;
}
.choose-wrapper:last-child {
    margin-bottom: 0px;
}
.about-section {
    margin-bottom: 41px;
    width: 62%;
}
.choose-banner-wrapper {
    margin-left: 70px;
    position: relative;
}
.choose-banner-text {
    left: 45px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.choose-banner-text > h4 {
    color: #202020;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
}
.choose-banner-text > h3 {
    color: #202020;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 37px;
}
.choose-banner-text > h3 span {
    font-size: 52px;
    font-weight: bold;
}
.choose-banner-text > a {
    color: #303030;
    display: inline-block;
    font-weight: 600;
    text-decoration: underline;
}
.choose-banner-wrapper > img {
    width: 100%;
}
.all-causes {
    margin-top: 30px;
}
.team-area > img {
    width: 100%;
}


/* notification */
.notification-img {
    background-image: url("../../img/bg/4.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.notification-img-2 {
    background-image: url("../../img/bg/5.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.notification-img-3 {
    background-image: url("../../img/bg/6.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.notification-section-padding .container-fluid {
    padding: 0 200px;
}
.notification-section-padding-2 .container-fluid {
    padding: 0px 104px;
}
.notification-wrapper {
    display: flex;
    justify-content: space-between;
}
.notification-btn-close {
    display: flex;
}
.notification-btn > a {
    background-color: #ebf9ff;
    border-radius: 50px;
    color: #666666;
    display: inline-block;
    float: left;
    font-size: 12px !important;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 27px !important;
    margin: 4px 0 0;
    padding: 6px 40px 4px !important;
    text-transform: uppercase;
}
.notification-btn.notification-btn-2 a {
    background-color: #fff;
}
.notification-icon > button {
    background-color: transparent;
    border: medium none;
    margin: 9px 0 5px 53px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
}
.notification-icon.notification-icon-2 > button {
    margin: 9px 0 5px 169px;
}
.notification-icon > button i {
    background-color: #fff;
    border-radius: 50px;
    display: inline-block;
    font-size: 24px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    width: 25px;
    color: 333;
}
.notification-icon.notification-icon-2 > button i {
    background-color: #3b4764;
    color: #fff;
}
.notification-icon > button:hover i {
    background-color: #666;
    color: #fff;
}
.notification-pera-graph > p {
    color: #666666;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 9px 0 0;
}
.notification-pera-graph.notification-pera-graph-2 p {
    color: #8185a0;
}
.notification-btn > a:hover {
    background-color: #333;
    color: #fff;
}
.notification-pera-graph-3 p {
    color: #666666;
    font-family: "Roboto Slab", serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 2px 0 0 580px;
}
.notification-btn-3 a {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    color: #666;
    display: inline-block;
    font-family: "Roboto Slab", serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 4px 19px 7px;
    text-transform: capitalize;
}
.notification-btn-3 a:hover {
    background-color: #333;
    color: #fff;
}
.notification-icon-3 button {
    background-color: transparent;
    border: medium none;
    border-radius: 50px;
    color: #666666;
    display: block;
    float: left;
    margin: 1px 0 0 405px;
    text-align: center;
}
.notification-icon-3 i {
    background-color: #fff;
    border-radius: 50px;
    display: inline-block;
    font-size: 24px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    width: 25px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.notification-icon-3 button:hover i {
    background-color: #666;
    color: #fff;
}
.mfp-wrap {
    z-index: 99999999;
}
.mfp-bg {
    z-index: 9999999;
}
#scrollUp {
    background: #85bd02 none repeat scroll 0 0;
    bottom: 85px;
    color: #ffffff;
    cursor: pointer;
    display: none;
    font-size: 20px;
    height: 40px;
    line-height: 44px;
    position: fixed;
    right: 12px;
    text-align: center;
    width: 40px;
    z-index: 9999;
}
#scrollUp:hover {
    background: #648f00 none repeat scroll 0 0;
    color: #ffffff;
}

/*----- 23. Fruits page -----*/
/* 23.1 Heade style of fruits */

.header-sticky-3 {
    left: 0;
    padding: 15px 0 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.5s ease 0s;
    z-index: 99;
}
.furits-header .menu-style-2 nav > ul > li > a {
    // color: #fff;
    color: #666666;
    font-weight: 500;

}
.furits-login-cart {
    display: flex;
}
.furits-login li {
    display: inline-block;
    margin-left: 18px;
    position: relative;
}
.furits-login li:first-child {
    margin-left: 0px;
}
.furits-login li a {
    color: #969696;
    text-transform: uppercase;
}
.furits-login {
    padding: 62px 0 10px;
}
.furits-login li::after {
    background-color: #969696;
    content: "";
    font-size: 8px;
    height: 12px;
    position: absolute;
    right: -12px;
    top: 4px;
    width: 1px;
}
.furits-login li:last-child::after {
    content: none;
}
.header-cart-4.furits-cart > a span {
    background-color: #68a001;
}
.header-cart-4.furits-cart > a > i {
    color: #00245b;
}
.furits-login li a:hover {
    color: #68a001;
}

/* 23.2 Slider style of fruits */

.slider-area {
    position: relative;
}
.slider-social {
    bottom: 150px;
    display: flex;
    left: 0;
    padding: 59px 100px 175px;
    position: absolute;
    z-index: 99;
}
.slider-social li {
    display: inline-block;
    margin-right: 14px;
}
.slider-social li:last-child {
    display: inline-block;
    margin-right: 14px;
}
.slider-social li a {
    color: #838383;
    font-size: 15px;
}
.slider-social li a:hover {
    color: #85bd02;
}
.slider-social > span {
    color: #838383;
    font-weight: bold;
    margin-right: 11px;
    text-transform: uppercase;
}
.owl-carousel .owl-item .furits-content img {
    display: inline-block;
    width: inherit;
}
.furits-slider-btn {
    background-color: #85bd02;
    border-radius: 50px;
    color: #fffffe;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    padding: 17px 40px 16px;
    text-transform: uppercase;
    z-index: 99;
}
.furits-slider-btn:hover {
    color: #fff;
}
.furits-content p {
    color: #578a01;
    font-size: 24px;
    font-weight: 500;
    margin: 30px 0 52px;
}
.furits-slider {
    margin-bottom: 125px;
    padding: 273px 0 0px;
}
.furits-content {
    padding-bottom: 546px;
    position: relative;
    z-index: 999;
}
.furits-content img.slide-img-position {
    bottom: -125px;
    left: 9%;
    max-width: 100%;
    position: absolute;
    z-index: -1;
}
.furits-content img.slide-img-position.slide-img-position-2 {
  left: 17%;
}
.btn-hover.furits-slider-btn::before {
    border-radius: 50px;
}
.slider-active-2.owl-carousel .owl-nav div {
    bottom: 325px;
    color: #838383;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    right: 188px;
    transition: all 0.3s ease 0s;
}
.slider-active-2.owl-carousel .owl-nav div:hover {
    color: #85bd02;
}
.slider-active-2.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: 100px;
}
.slider-active-2.owl-carousel .owl-nav div::before {
    background-color: #838383;
    content: "";
    height: 20px;
    position: absolute;
    right: -13px;
    text-align: center;
    top: 5px;
    transform: rotate(15deg);
    width: 3px;
}
.slider-active-2.owl-carousel .owl-nav div:last-child::before {
    content: none;
}

/* 23.3 Banner style of fruits */

.furits-banner-wrapper > img {
    width: 100%;
}
.furits-banner-wrapper {
    position: relative;
}
.furits-banner-content {
    left: 0;
    padding: 35px 40px;
    position: absolute;
    top: 0;
}
.furits-banner-content > h4 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 800;
    font-family: 'Open Sans', sans-serif;
}
.furits-banner-content > p {
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 18px 0;
    width: 88%;
}
.furits-banner-btn {
    background-color: #fff;
    border-radius: 50px;
    color: #ffb102;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 14px 6px;
    text-transform: uppercase;
    z-index: 1;
}
.furits-banner-btn:hover {
    color: #fff;
}
.furits-banner-btn.btn-hover::before {
    border-radius: 50px;
}

/* 23.4 Product style of fruits */

.product-fruit-wrapper,
.product-fruit-img {
    position: relative;
    transition: all .3s ease 0s;
}
.product-fruit-img > img {
    border-radius: 10px 10px 0 0;
    width: 100%;
}
.product-furit-action {
    bottom: 0;
    display: flex;
    justify-content: space-between;
    left: 0;
    opacity: 0;
    right: 0;
    position: absolute;
    overflow: hidden;
    transition: all 0.4s ease 0s;
}
.product-furit-action a {
    background-color: #85bd02;
    border-radius: 0 50px 50px 0;
    color: #fff;
    font-size: 24px;
    height: 50px;
    line-height: 56px;
    text-align: center;
    width: 70px;
}
.product-furit-action a:hover {
    background-color: #333;
}
.product-furit-action a.furit-animate-left {
    border-radius: 0 50px 50px 0;
}
.product-furit-action a.furit-animate-right {
    border-radius: 50px 0px 0px 50px;
}
.product-fruit-content > h4 {
    color: #444444;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin-bottom: 6px;
}
.product-fruit-content > h4 a {
    color: #444444;
    text-transform: capitalize;
}
.product-fruit-content > h4 a:hover {
    color: #85bd02;
}
.product-fruit-content > span {
    color: #85bd02;
    font-size: 18px;
}
.product-fruit-content {
    padding: 25px 10px 34px;
    background-color: transparent;
    transition: all .3s ease 0s;
}
.product-fruit-wrapper:hover .product-fruit-img {
    border-radius: 10px 10px 0px 0px;
}
.product-fruit-wrapper:hover .product-fruit-content {
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
}
.product-furit-action a.furit-animate-left {
    transform: translateX(-20px);
    transition: all 0.4s ease 0s;
}
.product-furit-action a.furit-animate-right {
    transform: translateX(20px);
    transition: all 0.4s ease 0s;
}
.product-fruit-wrapper:hover .product-furit-action a.furit-animate-left {
    transform: translateX(0px);
}
.product-fruit-wrapper:hover .product-furit-action a.furit-animate-right {
    transform: translateX(0px);
}
.product-fruit-wrapper:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 10px 10px;
}
.product-fruit-wrapper:hover .product-furit-action {
    opacity: 1
}
.section-title-furits.bg-shape:before {
    content: url(../../img/banner/title-shape.png);
    position: absolute;
    left: 50%;
    top: 6px;
    bottom: auto;
    transform: translate(-50%, -100%);
}
.section-title-furits > h2 {
    color: #434343;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 25px 0 0;
    position: relative;
}
.section-title-furits > h2::before {
    background-color: #85bd02;
    bottom: -13px;
    content: "";
    height: 2px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    width: 60px;
}
.section-title-furits {
    position: relative;
}
.product-fruit-slider.owl-carousel .owl-stage-outer {
    padding: 25px 0;
}
.product-style-area .container-fluid {
    padding: 0 100px;
}


/* 23.5 Banner style 2 of fruits */

.single-fruits-choose {
    display: flex;
    margin-bottom: 50px;
}
.fruits-choose-wrapper {
    margin-top: 94px;
}
.fruits-choose-serial {
    background: rgba(0, 0, 0, 0) url("../../img/icon-img/50.png") no-repeat scroll center center / cover;
    position: absolute;
    height: 63px;
    width: 150px;
    left: -59px;
    background-size: 60px;
    text-align: center;
}
.fruits-choose-serial > h3 {
    color: #f8f7f7;
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    padding-top: 10px;
}
.fruits-choose-content > h4 {
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
}
.fruits-choose-content > p {
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    width: 80%;
}
.fruits-choose-title > h2 {
    color: #d4d9cc;
    font-size: 80px;
    font-weight: 900;
    letter-spacing: 0.2px;
    margin: 20px 0 0 33px;
    text-transform: uppercase;
}
.fruits-banner2-wrapper a > img {
    width: 100%;
}
.fruits-banner2-wrapper {
    position: relative;
}
.fruits-banner2-content {
    position: absolute;
    // left: 10%;
    // text-align: center;
    top: 50%;
    transform: translateY(-50%);
    padding: 70px;
    z-index: 2
}
.fruits-banner2-content > h3, .fruits-banner2-content > h4 {
    font-size: 2.8vw;
    font-weight: 400;
    margin: 22px 0 0;
    text-transform: uppercase;
}

.fruits-banner2-content > h4 {
    font-size: 2vw !important;
}
.fruits-services-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 190px 0 305px;
}
.fruits-banner2-wrapper,
.furits-banner-wrapper {
    overflow: hidden;
    transition: all .3s ease 0s;
}
.fruits-banner2-content > img,
.fruits-banner2-wrapper > a img,
.furits-banner-wrapper > img {
    width: 100%;
    transition: all 0.3s ease 0s;
}
.fruits-banner2-wrapper:hover img,
.furits-banner-wrapper:hover img {
    transform: scale(1.1);
}

/* 23.6 Services style of fruits */

.single-fruits-services {
    position: relative;
    margin: 20px 0;
}

.fruits-services-content{
    margin-left: 100px;
}
.fruits-services-content > h4 {
    color: #666666;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.fruits-services-content > p {
    color: #363636;
    margin: 0;

}
.fruits-services-img {
    margin-right: 30px;
}
.fruits-services-img > img {
    width: 80px;
}

/* 23.7 Product coundoun style of fruits */

.coundown-wrapper > h2 {
    color: #434343;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 58px;
    margin: 0;
    text-transform: uppercase;
}
.coundown-wrapper > span {
    color: #85bd02;
    display: block;
    font-size: 18px;
    letter-spacing: 0.2px;
    margin: 10px 0 21px;
    text-transform: capitalize;
}
.coundown-wrapper > p {
    color: #666666;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 26px;
    margin-bottom: 22px;
    width: 98%;
}
.timer span {
    display: inline-block;
    font-size: 48px;
    font-weight: 500;
    line-height: 1;
    margin-right: 64px;
    position: relative;
    text-align: center;
}
.timer span:last-child {
    margin-right: 0px;
}

.timer span p {
    color: #999999;
    letter-spacing: .2px;
    margin: 0;
}
.timer span.cdown::before {
    background-color: #666666;
    content: "";
    height: 6px;
    position: absolute;
    right: -26px;
    top: 10px;
    width: 6px;
    z-index: 9999;
}

.timer span.cdown::after {
    background-color: #666666;
    bottom: 37px;
    content: "";
    height: 6px;
    position: absolute;
    right: -26px;
    width: 6px;
    z-index: 9999;
}
.coundown-btn {
    background-color: #85bd02;
    border-radius: 50px;
    color: #ffffff;
    display: inline-block;
    letter-spacing: 0.2px;
    padding: 11px 30px 10px;
    text-transform: uppercase;
    z-index: 1;
}
.coundown-btn:hover {
    color: #ffffff;
}
.timer {
    margin-bottom: 36px;
}
.coundown-btn.btn-hover::before {
    border-radius: 50px;
}

/* 23.8 Product Best seller style of fruits */

.single-top-seller {
    align-items: center;
    background-color: #fafafa;
    display: flex;
    margin-bottom: 30px;
}
.single-top-seller:last-child {
    margin-bottom: 0px;
}
.top-seller-img {
    margin-right: 25px;
}
.top-seller-content > h5 {
    color: #434343;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 0;
}
.top-seller-content > h5 a {
    color: #434343;
}
.fruit-price > span {
    color: #85bd02;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.2px;
}
.fruit-price > span.old {
    color: #9e9e9e;
    font-size: 14px;
    text-decoration: line-through;
}
.top-seller-content > a {
    color: #434343;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
}
.top-seller-content > a:hover,
.top-seller-content > h5 a:hover {
    color: #85bd02;
}
.fruit-price {
    margin: 2px 0;
}
.top-seller-title > span {
    color: #696969;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.top-seller-title > h3 {
    color: #434343;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 2px 0 11px;
}
.top-seller-title {
    margin-bottom: 36px;
}
.fruits-top-seller-area {
    position: relative;
}
.fruits-top-seller-area:before {
    content: url(../../img/banner/12.png);
    position: absolute;
    left: 0;
    top: 0px;
    bottom: auto;
    z-index: -1;
}
.fruits-top-seller-area:after {
    content: url(../../img/banner/13.png);
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

/* 23.9 Testimonial style of fruits */

.fruits-single-testimonial > h3 {
    color: #5d5d5d;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 45px 0;
}
.fruits-single-testimonial > p {
    color: #8a8a8a;
    font-size: 18px;
    font-style: italic;
    letter-spacing: 0.2px;
    line-height: 30px;
    margin: 42px auto 34px;
    width: 63%;
}
.client-name > span {
    color: #999999;
}
.client-name > span.client-name-bright {
    color: #595959;
    font-size: 16px;
}
.fruits-ratting {
    margin-top: 12px;
}
.fruits-ratting i {
    color: #85bd02;
    font-size: 20px;
    margin: 0 1px;
}

/* 23.10 Blog style of fruits */

.blog-meta-2 li {
    color: #8f8f8f;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-right: 15px;
    position: relative;
}
.blog-meta-2 ul li a {
    color: #8f8f8f;
}
.blog-meta-2 ul li::before {
    background-color: #8f8f8f;
    content: "";
    height: 4px;
    position: absolute;
    right: -12px;
    top: 9px;
    width: 4px;
}
.blog-meta-2 ul li:last-child::before {
    content: none;
}
.blog-info-wrapper-2 > h3 {
    color: #434343;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 28px;
    margin: 4px 0 18px;
}
.blog-info-wrapper-2 > h3 a:hover,
.blog-meta-2 ul li a:hover {
    color: #85bd02;
}
.blog-img-2 a img {
    width: 100%;
}
.blog-info-wrapper-2 {
    background-color: #fafafa;
    padding: 20px 20px 25px;
    transition: all .3s ease 0s;
}
.blog-wrapper:hover .blog-info-wrapper-2 {
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(2, 3, 3, 0.1);
}

/* 23.11 Newsletter style of fruits */

.newsletter-wrapper {
    padding: 0 77px;
}
.subscribe-form-furits .clear {
    display: inline-block;
    float: left;
}
.subscribe-form-furits input {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    border-radius: 50px;
    box-shadow: 0 2px 15px 1.4px rgba(0, 0, 0, 0.15);
    color: #79ad00;
    display: inline-block;
    float: left;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    height: 55px;
    letter-spacing: 1px;
    line-height: 55px !important;
    padding: 0 30px;
    width: 450px;
}
.subscribe-form-furits .clear input.button {
    background-color: #85bd02;
    border: medium none;
    box-shadow: none;
    color: #ffffff;
    float: left;
    font-weight: 600;
    height: 40px;
    letter-spacing: 1px;
    line-height: 30px;
    margin-left: 40px;
    /*padding: 0 40px;*/
    text-transform: uppercase;
    width: inherit;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    box-shadow: 0 0px 7px .9px rgba(0, 0, 0, 0.1);
    height: 55px;
    line-height: 55px !important;
}
.subscribe-form-furits .clear input.button:hover {
    background-color: #648f00;
}
.fruits-newsletter-title > span {
    color: #79ad00;
    display: block;
    font-size: 18px;
    margin-bottom: 1px;
}
.fruits-newsletter-title > h3 {
    color: #79ad00;
    font-size: 30px;
    font-weight: 600;
    margin: 0;
}
.subscribe-form-furits input::-moz-placeholder {
    color: #79ad00;
    opacity: 1;
}
.subscribe-form-furits input::-webkit-placeholder {
    color: #79ad00;
    opacity: 1;
}

/* 23.12 Footer style of fruits */

.fruits-footer .food-about-info > p {
    color: #dfdfdf;
}
.fruits-footer .food-info-content > p {
    color: #dfdfdf;
    font-size: 14px;
    font-weight: 600;
}
.fruits-footer .footer-widget-title-6 {
    font-size: 24px;
}
.ptb-105 {
    padding: 105px 0 80px;
}
.fruits-newsletter-title {
    margin-top: 11px;
}
.subscribe-form-furits.f-right {
    margin-top: 9px;
}

/* 24. Modal style */
.modal-body {
    display: flex;
    justify-content: space-between;
    padding: 50px;
}
.modal-quickview-width {
    margin: 150px auto;
    min-width: 878px;
}
.qwick-view-left {
    min-width: 320px;
}
.quick-view-tab-content .tab-pane > img {
    width: 100%;
}
.quick-view-list {
    margin-top: 10px;
}
.quick-view-list a {
    margin-right: 10px;
}
.quick-view-list a:last-child {
    margin-right: 0px;
}
.qwick-view-left {
    margin-right: 30px;
}
.modal-content {
    border-radius: 0rem;
}
.qwick-view-content > h3 {
    color: #707070;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
.price span {
    color: #707070;
    font-size: 15px;
    font-weight: 600;
}
.price span.new {
    color: #050035;
    margin-right: 12px;
}
.price span.old {
    color: #707070;
    text-decoration: line-through;
}
.quick-view-rating i {
    color: #000000;
    font-size: 18px;
    margin-right: 2px;
}
.quick-view-rating i.red-star {
    color: #050035;
}
.rating-number {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
}
.quick-view-number > span {
    color: #808080;
    display: block;
    font-size: 14px;
    margin: 3px 0 0 10px;
}
.qwick-view-content > p {
    color: #545454;
    margin-bottom: 25px;
}
.select-option-part label {
    color: #707070;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 14px;
}
.select-option-part {
    margin-bottom: 35px;
}
.modal-body .select-option-part select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    color: #828282;
    font-size: 14px;
    height: 43px;
    padding-left: 20px;
    position: relative;
    width: 100%;
    background: rgba(0, 0, 0, 0) url("../../img/icon-img/4.png") no-repeat scroll right 20px center;
    text-transform: capitalize;
}
.qtybutton {
    color: #727272;
    cursor: pointer;
    float: left;
    font-size: 16px;
    font-weight: 600;
    height: 20px;
    line-height: 25px;
    position: relative;
    text-align: center;
    width: 20px;
}
input.cart-plus-minus-box {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    float: left;
    font-size: 16px;
    height: 25px;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 25px;
}
.cart-plus-minus *::-moz-selection {
    background: transparent none repeat scroll 0 0;
    color: #333;
    text-shadow: none;
}
.cart-plus-minus {
    border: 1px solid #dcdcdc;
    overflow: hidden;
    padding: 12px 0 8px 5px;
    width: 80px;
}
.quickview-plus-minus {
    display: flex;
    justify-content: flex-start;
    padding-top: 5px;
}
.quickview-btn-cart > a {
    background-color: #050035;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 1;
    padding: 17px 35px;
    position: relative;
    text-transform: uppercase;
    z-index: 5;
}
.quickview-btn-wishlist > a {
    border: 1px solid #dcdcdc;
    color: #727272;
    display: inline-block;
    font-size: 22px;
    padding: 11px 18px 2px;
    z-index: 9;
}
.quickview-btn-wishlist > a:hover {
    border: 1px solid transparent;
}
.quickview-btn-cart {
    margin: 0 30px;
}
.qtybutton.inc {
    margin-top: 2px;
}
.price {
    margin: 9px 0 8px;
}
#exampleModal .close {
    color: #fff;
    float: right;
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
    opacity: 1;
    position: absolute;
    right: 370px;
    text-shadow: 0 1px 0 #fff;
    top: 32px;
    transition: all .3s ease 0s;
    cursor: pointer;
}
#exampleModal .close:hover {
    color: #999;
}
.modal-backdrop.show {
    opacity: 0.8;
}
.modal-content .close:hover {
    color: #050035;
}
#space{
    padding-top: 8px;
}
#spice-head{
    padding-top: 15px;
}
#footer-info{
    margin-top: 1px;
}
/* ingredient page layout 2 table */
#table-arrange{
    width: 60%;
    margin-left: 270px;
}
.center{
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
}
#info-space{
    padding-bottom: 1px;
}

/*Home Page Testimonial*/
.testimonial{
    border: 1px solid #c9ced3;
    border-top: 3px solid #c9ced3;
    padding: 70px 25px 100px;
    margin-bottom: 30px;
    position: relative;
}
.testimonial:after{
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 30px;
    color: #00adb7;
    position: absolute;
    top: 20px;
    left: 25px;
}
.testimonial .description{
    font-size: 14px;
    color: #666;
    line-height:26px;
}
.testimonial .pic{
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: -30px;
    left: 25px;
}
.testimonial .pic img{
    width: 100%;
    height: auto;
}
.testimonial .testimonial-title{
    font-size: 20px;
    color: #000;
    position: absolute;
    bottom: 20px;
    left: 150px;
}
.testimonial .testimonial-title small{
    display: block;
    font-size: 14px;
    color: #666;
    margin-top: 7px;
}
.owl-theme .owl-controls .owl-page.active span{
    background: #00adb7;
}
@media only screen and (max-width:767px){
    .testimonial-bg{ padding: 50px 40px; }
    .owl-theme .owl-controls{ bottom: -22%; }

}
@media only screen and (max-width:480px){
    .testimonial-bg:before{
        width: 55px;
        height: 55px;
        line-height: 55px;
        font-size: 40px;
    }
    .testimonial-bg{ padding: 30px 10px; }
    .owl-theme .owl-controls{ bottom: -15%; }
}
@media only screen and (max-width:360px){
    .testimonial .title,
    .testimonial .post{
        font-size: 16px;
    }
    .owl-theme .owl-controls{ bottom: -12%; }
}





   #top-space{
    margin-top: 100px;
   }
   .our-clients{
margin-bottom: 50px;
   }


  #row-space1{
margin-top: 5px;
    margin-bottom: 5px;
  }

  .headtext {
    color: #1abc9c;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: center;
}
.careers-data-holder {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}
.career-opportunities {
    padding-top: 10px;
    padding-bottom: 10px;
}
.panel-group {
    margin-bottom: 20px;
}
.career-opportunities .panel {
    border: 1px solid #efefef;
    border-radius: 0px;
    padding-left: 50px;
}
.career-opportunities .panel-heading {
    background-color: #fff;
    color: #808080;
}
.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
}
.career-opportunities .panel-heading .accordion-toggle {
    text-transform: uppercase;
    display: block;
    padding-bottom: 20px;
    padding-top: 16px;
}
.career-page{
    padding-bottom: 500px;
    padding-top: 80px;
}
.collapse.in {
    display: block;
}
.career-detail-head{
margin-top: 15px;
    margin-bottom: 15px;
}
.custom-list{
    list-style-type: square;
}
.img-responsive{
    margin:0 auto;
    display: inline;
    width:20%;
}
/*Sticky Image css*/

img.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 200px;
    z-index: 98;
    display: grid;
    width: 150px;
    margin-bottom: -11px;
    margin-top: -35px;
}
img.sticky1 {
    position: -webkit-sticky;
    position: sticky;
    top: 288px;
    z-index: 98;
    width: 107px;
    margin-left: 21px;
}
img.sticky1:hover{
    width: 110px;
    -webkit-animation: blink 1s;
}
@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
img.sticky1 {
    -webkit-animation: blink 2s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation: blink 2s;
    -moz-animation-iteration-count: infinite;
    -o-animation: blink 2s;
    -o-animation-iteration-count: infinite;
}

/*sticky img css done*/

/*Blog Css*/

.blog-space {
    width: 70%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.blog-details-info > h3 {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 48px;
    margin: 30px 0 30px;
}
.blog-details-info > p,
.blog-feature > p {
    color: #666;
    letter-spacing: 0.4px;
    margin: 0;
    line-height: 26px;
    text-align: justify;
}
.blog-feature > p.blog-pera-mrg {
    margin: 26px 0;
    text-align: justify;
}
.blog-feature > img{
    width: 70%;
    margin-top: 50px;
    margin-bottom: 50px;
}
.blog-details-info  p {
    margin-top: 20px;
    line-height: 26px;
    text-align: justify;
    margin-bottom: 20px;
}
.blog-feature > h5 {
    letter-spacing: 0.4px;
}
.blog-feature {
    margin-top: 39px;
    margin-bottom: 39px;
}
.blog-details-info a:link{
    color: blue;
}
.blog-details-info a:hover{
    color: green;
}
.blog-pera-mrg a:link{
    color: blue;
}
.blog-pera-mrg a:hover{
    color: green;
}
p > a {
    color: blue;
}
.blog-details-title,
.blog-details-title2 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 34px;
    text-transform: uppercase;
}
.blog-details-info li {
    list-style-type: disc;
    font-family: 'Poppins', sans-serif;
    color: #383838;
    margin-top: 0px;
    font-style: normal;
    font-weight: 400;
    margin-left: 50px;
    text-align: justify;
}

 .footer-section {
    font-color: blue;
}


// pagination


.pagination a, .pagination span,.pagination em {
   padding: 7px 18px;
   border: 1px solid #eee;
   margin-left: -2px;
   margin-right: -2px;
   background-color: #ffffff;
   display: inline-block;
   color:#84bd1e;
 }

.pagination a {
   &:hover {
       background-color: #f1f1f1;
       color: #333;
   }
}

.pagination {
   text-align: center;
   display: block !important;
   margin-bottom: 20px;
}
.pagination em {
   color:black;
}

/*dehydrated products css*/
.ul-type{
    list-style-type: square !important;
}
.section-dveg{
    padding-left: 30px;
    margin-bottom: 120px;
}
.section-dveg  h4{
    margin-bottom: 30px;
     margin-left: 70px;

}
.dehydrated-img > img{
    width: 170px;
    height: 170px;
    border-radius: 5px;
}

.dehydrated-img p{
    margin-bottom: 80px;
}
.section-title-dehydrated  h3{
    margin-bottom: 25px;

}
.section-title-dehydrated h2{
    font-weight: 500;
}
.section-title-dehydrated {
    position: relative;
    margin-left: 25px;
    margin-bottom: 40px;
}

.range-button {
    background-color: #85bd02;
    border: 1px solid transparent;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    width: 120px;
    height: 45px;;
}

.dpt-170{
    padding-top: 160px;
}
@media (max-width: 767px){
.dpt-170 {
    padding-top: 160px;
}
.section-dveg {
    padding-left: 30px;
    margin-bottom: 80px;
}
}
.kwes-enquire {
background-color: #85bd02;
border: medium none;
box-shadow: none;
color: #ffffff;
float: left;
font-weight: 600;
height: 40px;
letter-spacing: 1px;
line-height: 30px;
margin-left: 40px;
text-transform: uppercase;
width: inherit;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
box-shadow: 0 0px 7px 0.9px rgba(0,0,0,0.1);
height: 55px;
line-height: 55px !important;
border-radius: 50px;
font-size: 14px; 
padding: 0 30px;
}
.kwes-enquire-contact-us {
background-color: #85bd02;
border: medium none;
box-shadow: none;
color: #ffffff;
float: left;
font-weight: 600;
height: 40px;
letter-spacing: 1px;
line-height: 30px;
text-transform: uppercase;
width: inherit;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
box-shadow: 0 0px 7px 0.9px rgba(0,0,0,0.1);
height: 55px;
line-height: 55px !important;
border-radius: 50px;
width: 300px;
}